import React from 'react'
import {composeComponent} from 'utils/react-tools'
import {withRouter} from 'react-router-dom'
import {withHandlers, withStateHandlers, lifecycle} from 'recompose'
import moment from 'moment'
import cx from 'classnames'
import * as _ from 'ramda'
import Header from 'theme/Header'
import Loader from 'theme/Loader'
import Footer from 'theme/Footer'
import {getUser} from 'api'
import Modal from 'theme/Modal'
import Form from './NewLanguageForm'
import {getLanguages, getTranslation, createLanguage, getLanguagesList, getToTranslate} from './requests'

import './Languages.sass'


export default composeComponent 'Languages',
  withRouter

  withStateHandlers
    loading: false
    languages: []
    choosingTemplate: false
    modalOpened: false
    languagesList: []
  ,
    setLanguages: -> (languages) -> {languages}
    endLoading: -> -> loading: false
    startLoading: -> -> loading: true
    chooseTemplate: -> -> choosingTemplate: true
    endChooseTemplate: -> -> choosingTemplate: false
    openModal: -> -> modalOpened: true
    closeModal: -> -> modalOpened: false
    setLanguagesList: -> (languagesList) -> {languagesList}

  lifecycle
    componentDidMount: ->
      {startLoading, endLoading, setLanguages, setLanguagesList} = @props
      startLoading()
      getLanguagesList()
      .then (list) ->
        mappedList = list.map (l) ->
          value: l, label: l
        setLanguagesList mappedList
      getLanguages()
      .then (languages) ->
        endLoading()
        setLanguages languages
      ,
        -> endLoading()

  withHandlers
    clickOnRow: ({history, choosingTemplate}) -> ({language}) -> ->
      history.push "/languages/#{language}"
    addLanguage: ({languages, setLanguages, closeModal}) -> (values) ->
      createLanguage values
      .then (language) ->
        getLanguages()
        .then (languages) ->
          setLanguages languages
          closeModal()
    exportToTranslate: -> ->
      a = document.createElement 'a'
      getToTranslate()
      .then ({values}) ->
        data = JSON.stringify values, null, 4
        file = new Blob [data], type: 'text/plain'
        a.href = URL.createObjectURL file
        a.download = "ImperativeTranslation_ToTranslate.txt"
        a.click()

  ({languages
    loading
    history
    endChooseTemplate
    chooseTemplate
    choosingTemplate
    clickOnRow
    openModal
    closeModal
    modalOpened
    addLanguage
    languagesList
    exportToTranslate
  }) ->
    user = getUser()

    React.createElement("div", {"className": "Languages page"},
      React.createElement(Header, {"user": (user)}),
      React.createElement("div", {"className": "container"},

        React.createElement("div", {"className": "Languages__heading"},
          React.createElement("div", null,
            React.createElement("h1", {"className": "Languages__title"}, "Languages"),
            React.createElement("p", null, "Click on a row to show all versions")
          ),
          React.createElement("div", {"className": "Languages__headingBtns"},
            React.createElement("button", { \
              "onClick": (exportToTranslate),  \
              "className": "btn btn_secondary btn_solid_bluePurple"
            }, "Export To-Translate"),
            React.createElement("button", { \
              "onClick": (openModal),  \
              "className": "btn btn_secondary btn_solid_bluePurple"
            }, "Create New")
          )
        ),

        React.createElement(Loader, {"loading": (loading)}),

        React.createElement("table", null,
          React.createElement("thead", null,
            React.createElement("tr", null,
              React.createElement("th", null, "Language"),
              React.createElement("th", null, "Created"),
              React.createElement("th", null, "Last Modified"),
              React.createElement("th", null, "Version"),
              React.createElement("th", null, "State"),
              React.createElement("th", null)
            )
          ),
          React.createElement("tbody", null,
            (for languageName, versions of languages
              activeVersion = versions.find((v, i) -> v.state is 'Activated') or versions[0]
              newerVersion = versions.find (v) -> moment(v.modifyDate).isAfter moment activeVersion.modifyDate
              React.createElement("tr", {"key": (activeVersion.id), "onClick": (clickOnRow activeVersion)},
                React.createElement("td", {"data-th": "Language"}, (languageName)),
                React.createElement("td", {"data-th": "Created"}, (moment(activeVersion.createDate).format 'MMMM DD, YYYY')),
                React.createElement("td", {"data-th": "Last Modified"}, (moment(activeVersion.modifyDate).format 'MMMM DD, YYYY hh:mm a')),
                React.createElement("td", {"data-th": "Version"}, (activeVersion.version)),
                React.createElement("td", {"data-th": "State", "className": (cx "Languages__state", activeVersion.state)}, (activeVersion.state)),
                React.createElement("td", {"className": "Languages__newer"}, (if newerVersion? then 'Newer version exists'))
              )
            )
          )
        )
      ),

      React.createElement(Footer, null),

      React.createElement(Modal, { \
        "isOpen": (modalOpened),  \
        "close": (closeModal),  \
        "className": "Languages__modal"
      },
        React.createElement("h1", {"className": "Languages__title"}, "New language"),
        React.createElement(Form, {"onSubmit": (addLanguage), "languagesList": (languagesList)})
      )
    )
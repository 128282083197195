import React from 'react'
import T from 'tcomb'
import cx from 'classnames'
import {defaultProps} from 'recompose'
import {composeComponent, setPropTypes} from 'utils/react-tools'

import './Avatar.sass'


initials = ({firstName, lastName, slug}) ->
  if firstName? and lastName?
    "#{firstName.charAt 0}#{lastName.charAt 0}"
  else
    slug.slice 0, 2


export default composeComponent 'Avatar',
  defaultProps
    size: 'xs'
    withDominant: false
  setPropTypes
    for: T.union [
      T.interface
        avatars: T.interface
          small: T.String
          normal: T.String
          large: T.String
      T.interface
        firstName: T.String
        lastName: T.String
      T.interface
        slug: T.String
    ]
    size: T.enums.of ['xs', 'sm', 'md', 'md-lg', 'lg', 'xl']
    className: T.maybe T.String
    withDominant: T.Boolean
  ({for: subject, size, className, withDominant}) ->
    dominant = subject.pattern?.dominant?.toLowerCase() ? if subject.assessmentResult? then ['who', 'why', 'how'].find (d) -> subject.assessmentResult?.drivers[d].dominant

    React.createElement("span", {"className": (cx "Avatar", "_sz_#{size}", className, "#{dominant}": withDominant, withDominant: withDominant)},
      (if subject.avatars?
        React.createElement("span", { \
          "className": "Avatar__photo",  \
          "style": ({backgroundImage: "url(#{subject.avatars.normal})"})
        })
      else
        React.createElement("span", {"className": "Avatar__initials"}, (initials subject))
      )
    )


import React from 'react'
import {composeComponent} from 'utils/react-tools'
import {withProps} from 'recompose'
import Header from 'theme/Header'
import Subnav from 'theme/Subnav'
import Footer from 'theme/Footer'
import Conversations from './Conversations.coffee'
import Programs from './Programs.coffee'
import Sets from './Sets.coffee'
import Questions from './Questions.coffee'
import Preview from './Preview/Preview.coffee'
import ProgramsAssignment from './ProgramAssignment/ProgramsAssignment.coffee'
import {getUser} from 'api'

export default composeComponent 'PeerConversations',
  
  withProps ({match: {params}}) ->
    section: params?.element ? 'programs'

  ({section}) ->
    user = getUser()
    navLinks = [
      {to: '/peer-conversations/programs', name: 'programs'}
      {to: '/peer-conversations/sets', name: 'sets'}
      {to: '/peer-conversations/conversations', name: 'conversations'}
      {to: '/peer-conversations/questions', name: 'questions'}
      {to: '/peer-conversations/preview', name: 'preview'}
      {to: '/peer-conversations/assignment', name: 'programs assignment'}
    ]
    subtitle = "List of available #{section} in the PCP. Click on a row to edit its details."
    previewSubtitle = "List of available programs combinations in the PCP. Click edit button to change combination."
    assignmentSubtitle = "List of available programs that can be assigned to many organizations. Click on a row to manage organizations."

    React.createElement("div", {"className": "PeerConversations page"},
      React.createElement(Header, {"user": (user)}),
      React.createElement(Subnav, {"links": (navLinks)}),
      (
        switch section
          when 'programs'
            React.createElement(Programs, { \
              "name": (section),  \
              "subtitle": (subtitle)
            })
          when 'sets'
            React.createElement(Sets, { \
              "name": (section),  \
              "subtitle": (subtitle)
            })
          when 'conversations'
            React.createElement(Conversations, { \
              "name": (section),  \
              "subtitle": (subtitle)
            })
          when 'questions'
            React.createElement(Questions, { \
              "name": (section),  \
              "subtitle": (subtitle)
            })
          when 'preview'
            React.createElement(Preview, { \
              "name": (section),  \
              "subtitle": (previewSubtitle)
            })
          when 'assignment'
            React.createElement(ProgramsAssignment, { \
              "name": 'programs assignment',  \
              "subtitle": (assignmentSubtitle)
            })
      ),
      React.createElement(Footer, null)
    )

import React from 'react'
import {composeComponent} from 'utils/react-tools'
import {withStateHandlers, lifecycle, withProps} from 'recompose'
import {reduxForm, Field} from 'redux-form'
import {Checkbox, TextField, Select} from 'theme/Form'
import Loader from 'theme/Loader'
import {required} from 'validate'

fieldRequired = required 'Cannot be empty'

export default composeComponent 'Program.Field',

  ({
    options
    setLastAction
    index
  }) ->
    warning = if initialValues?.id then -> 'Changing this may lead to inapropriate import-export process.'

    React.createElement(Field, { \
      "name": "#{index}",  \
      "placeholder": "Organizations",  \
      "component": (Select),  \
      "options": (options.organization),  \
      "setLastAction": (setLastAction),  \
      "isClearable": false,  \
      "isMulti": true
    })

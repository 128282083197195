import React from 'react'
import * as _ from 'ramda'
import cx from 'classnames'
import {composeComponent} from 'utils/react-tools'
import {withRouter} from 'react-router'
import {withState, withProps, withHandlers} from 'recompose'
import Collapse from 'react-collapse'
import Form from './Form.coffee'
import SetDetails from './SetDetails.coffee'
import CheckBox from 'theme/CheckBox'
import {addProgramSet, getPeerConversation} from 'peerConversations/requests'

import 'theme/btn/btn-styles.sass'


export default composeComponent 'Preview.ProgramDetails',
  withRouter
  withState 'collapsed', 'open', true
  withProps ({programDetails, opened, collapsed, sets, setTree}) ->
    opened: opened or not collapsed
    programId: (_.head programDetails)?.id
    localSets: sets.map (set) -> {label: set.label}
    setsAssigned: setTree.map (set) -> {label: set.label}

  withProps ({localSets, setsAssigned, sets, programDetails}) ->
    setsToAddLabels = _.difference localSets, setsAssigned
    setsToAdd = setsToAddLabels.map (label) -> _.find(_.propEq('label', label.label))(sets)
    options: setsToAdd.map (set) -> {label: set.label, value: set.id}
    program: _.head programDetails

  withHandlers
    submit: ({history, setProgram, programId, setTree, setPeerConversations}) -> (values) ->
      addProgramSet Object.assign({}, {programId: programId, setId: values.id, seq: setTree.length+1})
        .then () -> getPeerConversation()
          .then (tree) ->
            setPeerConversations tree

  ({
    setTree
    opened
    open
    collapsed
    sets
    enableEdition
    conversations
    questions
    programId
    options
    submit
    setPeerConversations
    onChangeSelect
    selected
    program
    }) ->

    value = if selected then selected[0].selected else false

    React.createElement("div", {"className": "Preview__item"},
      React.createElement("div", {"className": (cx "Preview__name", "Preview__cell_name", if opened then "Preview__opened")},
        React.createElement(CheckBox, { \
          "value": (value),  \
          "onChange": (onChangeSelect),  \
          "programLabel": (program?.label)
        }),
        React.createElement("div", {"className": "main", "onClick": (-> open !collapsed)},
          React.createElement("h3", null, "Program ", (program?.label)),
          React.createElement("span", {"className": "count", "onClick": (-> open !collapsed)}, """
            (""", (setTree.length), """)
"""),
          React.createElement("span", {"className": (cx "Preview__cell_icon", 'btnLink btnLink_secondary')},
            (React.createElement("i", {"className": "fal #{if opened then 'fa-chevron-up btn__icon_after' else 'fa-chevron-down btn__icon_after'}"}))
          )
        )
      ),

      React.createElement("div", {"className": "Preview__itemContent"},
        React.createElement(Collapse, {"isOpened": (opened), "hasNestedCollapse": (true)},
          (
            setTree.map (set, index) ->
              React.createElement(SetDetails, { \
                "key": (index),  \
                "conversationTree": (set.definitions),  \
                "setDetails": (sets.filter (s) -> s.label is set.label),  \
                "enableEdition": (enableEdition),  \
                "conversations": (conversations),  \
                "questions": (questions),  \
                "programId": (program?.id),  \
                "setPeerConversations": (setPeerConversations),  \
                "previousSet": (sets.filter (s) -> s.label is setTree[index-1]?.label),  \
                "nextSet": (sets.filter (s) -> s.label is setTree[index+1]?.label)
              })
          )
        ),
        React.createElement(Form, { \
          "enableEdition": (enableEdition),  \
          "placeholderText": "Add new set",  \
          "onSubmit": (submit),  \
          "options": (options)
        })
      )
    )

import React from 'react'
import {composeComponent} from 'utils/react-tools'
import {Link} from 'react-router-dom'
import {withRouter} from 'react-router'
import * as _ from 'ramda'
import {withHandlers, withStateHandlers, lifecycle, withProps, withState, mapProps} from 'recompose'
import {SubmissionError} from 'redux-form'
import InView from 'react-inview-monitor'
import moment from 'moment'
import cx from 'classnames'
import Header from 'theme/Header'
import Loader from 'theme/Loader'
import Footer from 'theme/Footer'
import Translation from 'theme/TranslationView/Translation.coffee'
import ArchiveModal from 'theme/ArchiveModal'
import {getUser} from 'api'
import {getJobHack, getEnumerators, updateJobHack, createJobHack, getLanguages, deleteJobHack} from 'jobHacks/requests'
import Form from './Form'

import './JobHack.sass'

GROUPS_MAP =
  drtc: 'Type'
  contentType: 'Content Type'
  developmentGoal: 'Development Goal'

export default composeComponent 'JobHack',
  withRouter
  withProps ({match: {params}}) ->
    jobHackId: params.id
  withStateHandlers
    loading: true
    enumerators: {}
    jobHack: {}
    languages: null
    modalVisible: false
    translationsVisible: false
  ,
    setJobHack: -> (jobHack) -> {jobHack}
    endLoading: -> -> loading: false
    startLoading: -> -> loading: true
    setSortBy: ({sortBy, sortDir}) -> (column) ->
      if column.name isnt sortBy.name
        sortBy: column
        sortDir: 'asc'
      else switch sortDir
        when 'asc' then sortDir: 'desc'
        when 'desc' then sortDir: 'asc'
        else sortDir: 'asc'
    setEnumerators: -> (enumerators) -> {enumerators}
    changeFilter: -> (filters) -> filters: filters
    showMore: ({page}) -> -> page: page + 1
    setLanguages: -> (languages) -> {languages}
    showModal: -> (data) -> modalVisible: true
    closeModal: -> -> modalVisible: false
    showTranslatoins: -> -> translationsVisible: true
    closeTranslatoins: -> -> translationsVisible: false

  lifecycle
    componentDidMount: ->
      {startLoading, endLoading, setJobHack, setEnumerators, jobHackId, setLanguages} = @props
      startLoading()

      a = if jobHackId then getJobHack(jobHackId) else Promise.resolve({})
      a.then setJobHack

      b = getEnumerators()
      .then setEnumerators

      c = getLanguages()
      .then setLanguages

      Promise.all [a, b, c]
      .finally endLoading

  withProps ({enumerators}) ->
    options: Object.keys(enumerators).reduce (options, key) ->
      _.merge options, "#{key}": enumerators[key].map (o) ->
        label: o
        value: o
    , {}

  withHandlers
    submit: ({jobHackId, history, setJobHack}) -> (values) ->
      if values.order?
        values.order = Number values.order
      if jobHackId
        updateJobHack jobHackId, values
        .then setJobHack
      else
        createJobHack values
        .then (jh) ->
          setJobHack jh
          history.push "/job-hacks/#{jh.id}"

    archive: ({jobHack, startLoading, endLoading, setJobHack, closeModal}) -> ->
      startLoading()

      deleteJobHack jobHack.id
      .then ->
        getJobHack jobHack.id
        .then setJobHack
        .then closeModal
        .finally endLoading

    unarchive: ({jobHack, startLoading, endLoading, setJobHack}) -> ->
      startLoading()

      updateJobHack jobHack.id, jobHack
      .then setJobHack
      .finally endLoading

  ({
    loading
    jobHack
    match
    showModal
    closeModal
    modalVisible
    enumerators
    options
    submit
    languages
    archive
    unarchive
    jobHackId
    showTranslatoins
    closeTranslatoins
    translationsVisible
  }) ->
    user = getUser()
    React.createElement("div", {"className": "JobHack page"},
      React.createElement(Header, {"user": (user)}),
      React.createElement("div", {"className": "container"},
        React.createElement(Loader, {"loading": (loading and not modalVisible)}),

        React.createElement("div", {"className": "JobHack__heading"},
          React.createElement("div", null,
            React.createElement("h1", {"className": "JobHack__title"},
              React.createElement(Link, {"to": "/job-hacks"},
                React.createElement("span", {"className": "far fa-chevron-left"}),
                (jobHack.label ? 'New job hack')
              )
            )
          ),
          React.createElement("div", {"className": "JobHack__headingBtns"},
            (if jobHack.archiveDate
              React.createElement("button", {"onClick": (unarchive), "className": "btn btn_secondary btn_outlined_bluePurple"}, "Unarchive")
            else
              React.createElement("button", {"onClick": (showModal), "className": "btn btn_secondary btn_outlined_bluePurple"}, "Archive")
            ),
            React.createElement("button", { \
              "onClick": (if translationsVisible then closeTranslatoins else showTranslatoins),  \
              "className": "btn btn_secondary btn_outlined_bluePurple",  \
              "disabled": (!jobHackId)
            },
              (if translationsVisible then 'Close content' else 'Edit content')
            )
          )
        ),
        React.createElement("div", {"className": "JobHack__content"},
          React.createElement("div", {"className": (cx "form", if translationsVisible then 'form__translationVisible')},
            (unless loading
              React.createElement(Form, { \
                "onSubmit": (submit),  \
                "options": (options),  \
                "initialValues": (jobHack)
              })
            )
          ),
          (if translationsVisible
            React.createElement(Translation, { \
              "languages": (languages),  \
              "element": (jobHack),  \
              "type": "jobHack"
            })
          )
        )
      ),

      React.createElement(Footer, null),

      React.createElement(ArchiveModal, { \
        "closeModal": (closeModal),  \
        "loading": (loading),  \
        "elementType": "job hack",  \
        "archive": (archive),  \
        "modalVisible": (modalVisible)
      })

    )
import React from 'react'
import {composeComponent} from 'utils/react-tools'
import {Link} from 'react-router-dom'
import {withRouter} from 'react-router'
import * as _ from 'ramda'
import RSelect from 'react-select'
import {withHandlers, withStateHandlers, lifecycle, withProps, withState, mapProps} from 'recompose'
import {SubmissionError} from 'redux-form'
import InView from 'react-inview-monitor'
import moment from 'moment'
import cx from 'classnames'
import Header from 'theme/Header'
import Loader from 'theme/Loader'
import DeleteModal from 'theme/DeleteModal'
import Footer from 'theme/Footer'
import Heading from 'theme/Heading'
import {LinksSection } from 'theme/Heading/HeadingElements'
import {getUser} from 'api'
import {getCompetencies, exportCompetencies, importCompetencies, deleteCompetency, getActiveLanguage} from 'competencies/requests'

import 'jobHacks/JobHacks/JobHacks.sass'

VISIBLE_ROWS = 20
HEADINGS = [
    name: 'Label'
    sortFunc: _.prop 'label'
  ,
    name: 'Last Modified'
    sortFunc: _.prop 'modifyDate'
  ,
    name: 'Archived'
    sortFunc: _.prop 'archiveDate'
]

export default composeComponent 'Competencies',
  withRouter
  withState 'fileInput', 'setFileInput', null
  withState 'importStatus', 'setImportStatus', null
  withState 'language', 'setLanguage', null
  withStateHandlers
    loading: false
    competencies: []
    sortBy: HEADINGS[0]
    sortDir: 'asc'
    filters: []
    page: 1
    exporting: false
    modal:
      visible: false
      data: null
  ,
    setCompetencies: -> (competencies) -> {competencies}
    endLoading: -> -> loading: false
    startLoading: -> -> loading: true
    setSortBy: ({sortBy, sortDir}) -> (column) ->
      if column.name isnt sortBy.name
        sortBy: column
        sortDir: 'asc'
      else switch sortDir
        when 'asc' then sortDir: 'desc'
        when 'desc' then sortDir: 'asc'
        else sortDir: 'asc'
    changeFilter: -> (filters) -> filters: filters
    showMore: ({page}) -> -> page: page + 1
    showModal: -> (data) -> modal: {visible: true, data}
    closeModal: ({modal}) -> -> modal: _.merge modal, visible: false
    setExporting: -> (exporting) -> {exporting}
    changeFilter: -> (filters) -> filters: filters

  lifecycle
    componentDidMount: ->
      {startLoading, endLoading, setCompetencies, setLanguage} = @props
      startLoading()

      a = getCompetencies()
      .then setCompetencies

      b = getActiveLanguage()
      .then setLanguage

      Promise.all [a, b]
      .finally endLoading

  mapProps (props) ->
    reverse = if props.sortDir is 'desc' then _.reverse else _.identity
    filters = props?.filters?.value
    filterFunc = (c) ->
      unless filters?.length
        return true
      propsToFilter = _.flatten [c.id]
      _.any(_.contains _.__, filters) propsToFilter
    _.evolve(
      competencies: _.compose _.filter(filterFunc), reverse, _.sortBy(props.sortBy?.sortFunc)
    ) props

  withProps ({competencies}) ->
    options: [
      {
        'label': 'Competencies'
        'options': competencies.map (c) -> {label: c.label, value: c.id}
      }
    ]

  withHandlers
    exportCompetencies: ({setExporting}) -> ->
      setExporting true
      exportCompetencies()
      .then (competencies) ->
        a = document.createElement 'a'
        data = JSON.stringify competencies, null, 4
        file = new Blob [data], type: 'text/plain'
        a.href = URL.createObjectURL file
        a.download = "ImperativeCompetencies.txt"
        a.click()
      .finally -> setExporting false

    importCompetencies: ({setCompetencies, fileInput, setErrors, setImportStatus}) -> (e) ->
      file = fileInput.files[0]
      if file.type?.match /text.*/
        reader = new FileReader()

        reader.onload = =>
          try
            result = JSON.parse reader.result ? '[]'
            importCompetencies result
            .then (d) ->
              setCompetencies d
              setImportStatus type: 'success'
            .catch (err) ->
              setImportStatus type: 'error', message: e, error: err.message
          catch err
            if err instanceof SyntaxError
              e = 'File is corrupted.'
            else e = 'Unknown error'
            setImportStatus type: 'error', message: e, error: err.message

        reader.readAsText file

    deleteCompetency: ({setCompetencies, startLoading, endLoading, competencies, closeModal}) -> (competency) -> (e) ->
      e.stopPropagation()
      startLoading()
      deleteCompetency competency.id
      .then ->
        getCompetencies()
         .then setCompetencies
        closeModal()
      .finally endLoading


  ({
    loading
    history
    competencies
    count
    match
    modalOpened
    closeModal
    setSortBy
    sortBy
    sortDir
    filters
    changeFilter
    page
    showMore
    exportCompetencies
    exporting
    importing
    importCompetencies
    fileInput
    setFileInput
    modal
    showModal
    language
    importStatus
    deleteCompetency
    options
  }) ->
    user = getUser()
    count = if competencies.length then page * VISIBLE_ROWS else competencies.length
    maxCount = if count > competencies.length then competencies.length else count
    links = [
      {text: "Edit competencies translations", link: "/languages/English/#{language?.id}?key=competency"},
      {text: "Add new competency", link: "/competencies/new"}
    ]

    React.createElement("div", {"className": "JobHacks page"},
      React.createElement(Header, {"user": (user)}),
      React.createElement("div", {"className": "container"},
        React.createElement("input", {"type": "file", "ref": (setFileInput), "onChange": (importCompetencies), "className": "JobHacks__import"}),

        React.createElement(Heading, { \
          "name": "Competencies",  \
          "subtitle": "List of available competencies in the app. Click on a row to edit its details.",  \
          "linksSection": (
            React.createElement(LinksSection, { \
              "links": (links)
            })
          ),  \
          "buttonsSection": (
           React.createElement("div", null,
              React.createElement("button", {"onClick": (->fileInput.click()), "className": "btn btn_secondary btn_outlined_bluePurple", "disabled": (importing)}, """
                Import
""", (if importing
                  React.createElement("span", {"className": "fas fa-spinner fa-spin btn__icon_after"})
                )
              ),
              React.createElement("button", {"onClick": (exportCompetencies), "className": "btn btn_secondary btn_outlined_bluePurple", "disabled": (exporting)}, """
                Export
""", (if exporting
                  React.createElement("span", {"className": "fas fa-spinner fa-spin btn__icon_after"})
                )
              )
            )
          )
        }),

        (if importStatus?
          React.createElement("div", {"className": (cx "JobHacks__info", if importStatus.type is 'error' then 'JobHacks__errors' else 'JobHacks__success')},
            (if importStatus.type is 'success'
              React.createElement("div", null,
                React.createElement("span", null, "Import Successful")
              )
            else
              React.createElement("div", null,
                (importStatus.message),
                React.createElement("p", null, (importStatus.error))
              )
            )
          )
        ),

        React.createElement("div", {"className": "JobHacks__filters"},
          React.createElement(RSelect, { \
            "className": "JobHacks__select",  \
            "classNamePrefix": "JobHacks__select",  \
            "isClearable": true,  \
            "value": (filters),  \
            "onChange": (changeFilter),  \
            "placeholder": "Filter",  \
            "options": (options)
          })
        ),

        React.createElement("div", {"className": "JobHacks__table relative"},
          React.createElement(Loader, {"loading": (loading or importing)}),
          React.createElement("table", null,
            React.createElement("thead", null,
              React.createElement("tr", null,
                (HEADINGS.map (c, i) ->
                  React.createElement("th", {"onClick": (-> setSortBy c), "key": (i)},
                    (c.name),
                    (if c.name is sortBy.name
                      React.createElement("i", {"className": "far #{if sortDir is 'asc' then 'fa-chevron-down' else 'fa-chevron-up'}"})
                    )
                  )
                ),
                React.createElement("th", null)
              )
            ),
            React.createElement("tbody", null,
              ([0...maxCount].map (i) ->
                competency = competencies[i]
                React.createElement("tr", {"key": (i), "onClick": (-> history.push "/competencies/#{competency.id}")},
                  React.createElement("td", null,
                    (competency.label)
                  ),
                  React.createElement("td", null,
                    (moment(competency.modifyDate).format 'MMM DD, YYYY')
                  ),
                  React.createElement("td", null,
                    (if competency.archiveDate
                      React.createElement("span", {"className": "far fa-check"})
                    )
                  ),
                  React.createElement("td", null,
                    React.createElement("button", { \
                      "className": "btnLink",  \
                      "onClick": ((e) ->
                        e.stopPropagation()
                        showModal competency
                      )
                    },
                    (if !competency.archiveDate
                      React.createElement("span", {"className": "fas fa-trash"})
                    )
                    ),
                    React.createElement(Link, { \
                      "className": "btn btn_compact btn_solid_bluePurple",  \
                      "to": "/languages/English/#{language?.id}?key=competency.#{competency.label.toLowerCase()}",  \
                      "onClick": ((e) -> e.stopPropagation())
                    }, """
                      Edit translations
""")
                  )
                )
              ),
              (if maxCount < competencies.length
                React.createElement("tr", null,
                  React.createElement("td", {"colSpan": "8"},
                    React.createElement(InView, { \
                      "onInView": (showMore),  \
                      "intoViewMargin": "0%",  \
                      "repeatOnInView": true
                    }, "Loading more...")
                  )
                )
              )
            )
          )
        )

      ),
      React.createElement(Footer, null),
      React.createElement(DeleteModal, { \
        "modal": (modal),  \
        "closeModal": (closeModal),  \
        "loading": (loading),  \
        "removedItemName": "competency",  \
        "deleteFunc": (deleteCompetency)
      })
    )
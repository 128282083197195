import React from 'react'
import {composeComponent} from 'utils/react-tools'
import {Link} from 'react-router-dom'
import {withRouter} from 'react-router'
import cx from 'classnames'

export LinksSection = composeComponent 'LinksSection',
  ({links}) ->
    React.createElement("div", null,
      (links.map (link, index) ->
        React.createElement(Link, {"to": (link.link), "className": "btn btn_secondary btn_solid_bluePurple", "key": (index)},
          (link.text)
        )
      )
    )

export ButtonsSection = composeComponent 'ButtonsSection',
  ({buttons}) ->
    React.createElement("div", null,
      (buttons.map (button, index) -> 
        React.createElement("button", {  \
          "onClick": (-> if button.text is 'Import' then button.func.click() else button.func()),  \
          "className": "btn btn_secondary btn_outlined_bluePurple", "disabled": (button?.disabled),  \
          "key": (index)
        },
          (button.text),
          (if button?.disabled
            React.createElement("span", {"className": "fas fa-spinner fa-spin btn__icon_after"})
          )
        )
      )
    )
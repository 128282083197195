import React from 'react'
import {composeComponent} from 'utils/react-tools'
import {Link} from 'react-router-dom'
import {withRouter} from 'react-router'
import * as _ from 'ramda'
import RSelect from 'react-select'
import {reduxForm} from 'redux-form'
import {withStateHandlers, withProps, lifecycle, withState, mapProps} from 'recompose'
import moment from 'moment'
import InView from 'react-inview-monitor'
import Heading from 'theme/Heading'
import Loader from 'theme/Loader'
import ProgramAssignment from './ProgramAssignment.coffee'
import {getActiveLanguage, getPrograms, getProgramsOrganizations} from 'peerConversations/requests'

import '../Preview/Preview.sass'

HEADINGS = [
    name: 'Label'
    sortFunc: _.prop 'label'
]

export default composeComponent 'ProgramsAssignment',
  withRouter
  withState 'language', 'setLanguage', null
  withStateHandlers
    loading: false
    programs: []
    programOrganizations: []
    filters:[]
    page: 1
  ,
    setPrograms: -> (programs) -> {programs}
    setProgramsOrganizations: -> (programOrganizations) -> {programOrganizations}
    endLoading: -> -> loading: false
    startLoading: -> -> loading: true
    changeFilter: -> (filters) -> filters: filters

  lifecycle
    componentDidMount: ->
      {startLoading, endLoading, setPrograms, setLanguage, setProgramsOrganizations} = @props
      startLoading()

      a = getPrograms().then (programs) -> programs.filter (p) -> p.isGlobal is false and p.organizationId? is false
      .then setPrograms

      b = getActiveLanguage()
      .then setLanguage

      c = getProgramsOrganizations()
      c.then setProgramsOrganizations

      Promise.all [a, b, c]
      .finally endLoading

  withProps ({programs}) ->
    options: [
      {
        'label': 'Programs'
        'options': programs.map (program) -> {label: program.label, value: program.id}
      }
    ]
  
  withProps ({programs, programOrganizations}) ->
    initialValues: Object.assign {}, programs.map (p, i) -> (programOrganizations.filter (o) -> o.programId is p.id).map (set) -> set.organizationId

  reduxForm
    form: "organizationsForm"
    enableReinitialize: true
    keepDirtyOnReinitialize: true

  ({
    loading
    name
    subtitle
    programs
    filters
    options
    changeFilter
    initialValues
    }) ->
    React.createElement("div", {"className": "container"},
      React.createElement(Loader, {"loading": ((loading))}),
      React.createElement(Heading, {  \
        "name": (name),  \
        "subtitle": (subtitle)
      }),

      React.createElement("div", {"className": "PeerConversation__filters"},
        React.createElement(RSelect, { \
          "className": "PeerConversation__select",  \
          "classNamePrefix": "PeerConversation__select",  \
          "options": (options),  \
          "value": (filters),  \
          "onChange": (changeFilter),  \
          "placeholder": "Filter",  \
          "isClearable": true
        })
      ),

      React.createElement("div", {"className": "Preview__header Preview__row"},
        (HEADINGS.map (h, i) ->
          React.createElement("div", {"className": "Preview__#{h.className}", "key": (i)},
            (h.name)
          )
        )
      ),
      React.createElement("div", {"className": "Preview__rows"},
        React.createElement("form", null,
          (
            programs?.map (program, index) ->
              React.createElement(ProgramAssignment, { \
                "key": (index),  \
                "index": (index),  \
                "program": (program),  \
                "initialValues": (initialValues)
              })
          )
        )
      )
    )

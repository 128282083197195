import React from 'react'
import {composeComponent} from 'utils/react-tools'
import cx from 'classnames'
import {defaultProps} from 'recompose'
import RModal from 'react-modal'

import './Modal.sass'

RModal.setAppElement 'body'

export default composeComponent 'Modal',
  defaultProps
    withClose: true

  ({className, isOpen, close, children, withClose}) ->
    React.createElement(RModal, { \
      "className": (cx "Modal Modal__content", className),  \
      "overlayClassName": "Modal__overlay",  \
      "bodyOpenClassName": "Modal__body_opened",  \
      "contentLabel": "Modal",  \
      "isOpen": (isOpen),  \
      "onRequestClose": (close),  \
      "closeTimeoutMS": (500)
    },
      (if withClose
        React.createElement("button", { \
          "className": "Modal__exit btn",  \
          "onClick": (close)
        }, React.createElement("span", {"className": "fal fa-times"}))
      ),
      (children)
    )
import React from 'react'
import {composeComponent} from 'utils/react-tools'
import * as _ from 'ramda'
import {SubmissionError} from 'redux-form'
import moment from 'moment'
import {reduxForm, Field} from 'redux-form'
import {TextField, Select} from 'theme/Form'
import Loader from 'theme/Loader'
import cx from 'classnames'
import {required, integer} from 'validate'

import './JobHack.sass'

DRIVERS =
  who: [
    'Individual'
    'Organization'
    'Society'
  ]
  why: [
    'Karma'
    'Harmony'
  ]
  how: [
    'Community'
    'Human'
    'Knowledge'
    'Structure'
  ]

fieldRequired = required 'Cannot be empty'
fieldInteger = integer 'Must be a number'

optionClassName = (option) ->
  Object.keys(DRIVERS).find (driver) ->
    option.value in DRIVERS[driver]

GROUPS_MAP =
  drtc: 'Type'
  contentType: 'Content Type'
  developmentGoal: 'Development Goal'

export default composeComponent 'JobHack.Form',
  reduxForm
    form: 'JobHackForm'
    enableReinitialize: true

  ({
    handleSubmit
    options = {}
    pristine
    submitting
    submitFailed
    initialValues
    err
  }) ->
    warning = if initialValues?.id then -> 'Changing this may lead to inapropriate import-export process.'

    React.createElement("form", {"onSubmit": (handleSubmit)},
      React.createElement("div", null,
         React.createElement("h2", null, "Details"),
          React.createElement("p", null,
            React.createElement("span", null, "Edit details and remember to save changes. ")
          )
      ),
      React.createElement(Loader, {"loading": (submitting)}),
      React.createElement(Field, { \
        "name": "label",  \
        "placeholder": "Job hack identifier",  \
        "label": "Label",  \
        "component": (TextField),  \
        "validate": (fieldRequired),  \
        "warn": (warning)
      }),
      React.createElement(Field, { \
        "name": "drtc",  \
        "placeholder": "Pick job hack type",  \
        "label": "Type",  \
        "component": (Select),  \
        "validate": (fieldRequired),  \
        "options": (options.drtcs)
      }),
      React.createElement(Field, { \
        "name": "stage",  \
        "placeholder": "Pick job hack stage",  \
        "label": "Stage",  \
        "component": (Select),  \
        "validate": (fieldRequired),  \
        "options": (options.stages)
      }),
      React.createElement(Field, { \
        "name": "contentType",  \
        "placeholder": "Pick content type",  \
        "label": "Content Type",  \
        "component": (Select),  \
        "validate": (fieldRequired),  \
        "options": (options.contentType)
      }),
      React.createElement(Field, { \
        "name": "developmentGoal",  \
        "placeholder": "Pick job hack development goals",  \
        "label": "Development Goal",  \
        "component": (Select),  \
        "validate": (fieldRequired),  \
        "isMulti": true,  \
        "options": (options.developmentGoals),  \
        "optionClassName": (_.prop 'value')
      }),
      React.createElement(Field, { \
        "name": "drivers",  \
        "placeholder": "Pick job hack drivers",  \
        "label": "Drivers",  \
        "component": (Select),  \
        "validate": (fieldRequired),  \
        "isMulti": true,  \
        "options": (options.drivers),  \
        "optionClassName": (optionClassName)
      }),
      React.createElement(Field, { \
        "name": "order",  \
        "placeholder": "Pick job hack priority",  \
        "label": "Priority",  \
        "type": "integer",  \
        "component": (TextField),  \
        "validate": (fieldInteger)
      }),

      (if submitFailed and err
        React.createElement("div", {"className": "JobHack__error"}, (err))
      ),

      React.createElement("button", { \
        "type": "submit",  \
        "className": "btn btn_secondary btn_solid_bluePurple JobHack__btn",  \
        "disabled": (pristine || submitting)
      }, "Save")
    )

import React from 'react'
import {composeComponent} from 'utils/react-tools'
import {Link} from 'react-router-dom'
import {withRouter} from 'react-router'
import {withHandlers, withStateHandlers, lifecycle, withProps, withState, mapProps} from 'recompose'
import cx from 'classnames'
import Loader from 'theme/Loader'
import Form from './Form.coffee'
import Translation from 'theme/TranslationView/Translation'
import ArchiveModal from 'theme/ArchiveModal'
import {getProgram, getLanguages, createProgram, updateProgram, getOrganizations, getPeerConversation, deleteProgram} from 'peerConversations/requests'

import '../ConversationElements.sass'

export default composeComponent 'Program',
  withRouter
  withProps ({match: {params}}) ->
    programId: params.id
  withStateHandlers
    organizations: []
    loading: false
    program: {}
    languages: null
    translationsVisible: false
    modalVisible: false
  ,
    setProgram: -> (program) -> {program}
    setOrganizations: -> (organizations) -> {organizations}
    endLoading: -> -> loading: false
    startLoading: -> -> loading: true
    setSortBy: ({sortBy, sortDir}) -> (column) ->
      if column.name isnt sortBy.name
        sortBy: column
        sortDir: 'asc'
      else switch sortDir
        when 'asc' then sortDir: 'desc'
        when 'desc' then sortDir: 'asc'
        else sortDir: 'asc'
    changeFilter: -> (filters) -> filters: filters
    showMore: ({page}) -> -> page: page + 1
    setLanguages: -> (languages) -> {languages}
    showTranslations: -> -> translationsVisible: true
    closeTranslations: -> -> translationsVisible: false
    showModal: -> (data) -> modalVisible: true
    closeModal: -> -> modalVisible: false


  lifecycle
    componentDidMount: ->
      {startLoading, endLoading, setProgram, programId, setLanguages, setOrganizations} = @props
      startLoading()

      a = if programId then getProgram(programId) else Promise.resolve({isGlobal: false})
      a.then setProgram

      b = getLanguages()
      .then setLanguages

      c = getOrganizations()
      .then setOrganizations

      Promise.all [a, b, c]
      .finally endLoading

  withProps ({organizations}) ->
    options: {
      'organization': organizations.map (organization) -> {label: organization.name, value: organization.id}
    }

  withHandlers
    submit: ({programId, history, setProgram}) -> (values) ->
      if programId
        updateProgram programId, values
        .then setProgram
      else
        createProgram values
        .then (program) ->
          setProgram program
          history.push "/peer-conversations/programs/#{program.id}"

    archive: ({program, startLoading, endLoading, setProgram, closeModal}) -> ->
      startLoading()

      deleteProgram program.id
      .then ->
        getProgram program.id
        .then setProgram
        .then closeModal
        .finally endLoading

    unarchive: ({program, startLoading, endLoading, setProgram}) -> ->
      startLoading()

      updateProgram program.id, program
      .then setProgram
      .finally endLoading

  ({
    loading
    program
    organizations
    options
    match
    submit
    programId
    params
    languages
    translationsVisible
    showTranslations
    closeTranslations
    unarchive
    archive
    showModal
    closeModal
    modalVisible
  }) ->

    React.createElement("div", {"className": "container"},
      React.createElement(Loader, {"loading": (loading)}),
      React.createElement("div", {"className": "ConversationElements__heading"},
        React.createElement("div", null,
          React.createElement("h1", {"className": "ConversationElements__title"},
            React.createElement(Link, {"to": "/peer-conversations/programs"},
              React.createElement("span", {"className": "far fa-chevron-left"}),
              (program.label ? 'New Program')
            )
          )
        ),
        React.createElement("div", {"className": "ConversationElements__headingBtns"},
          (if program.archiveDate
            React.createElement("button", {"onClick": (unarchive), "className": "btn btn_secondary btn_outlined_bluePurple"}, "Unarchive")
          else
            React.createElement("button", {"onClick": (showModal), "className": "btn btn_secondary btn_outlined_bluePurple"}, "Archive")
          ),
          React.createElement("button", { \
            "onClick": (if translationsVisible then closeTranslations else showTranslations),  \
            "className": "btn btn_secondary btn_outlined_bluePurple",  \
            "disabled": (!programId)
          },
            (if translationsVisible then 'Close content' else 'Edit content')
          )
        )
      ),

      React.createElement("div", {"className": "ConversationElements__content"},
        React.createElement("div", {"className": (cx "form", if translationsVisible then 'form__translationVisible')},
          (unless loading
            React.createElement(Form, { \
              "onSubmit": (submit),  \
              "options": (options),  \
              "initialValues": (program)
            })
          )
        ),
        (if translationsVisible
          React.createElement(Translation, { \
            "languages": (languages),  \
            "element": (program),  \
            "type": "peerconversation.program"
          })
        )
      ),

      React.createElement(ArchiveModal, { \
        "closeModal": (closeModal),  \
        "loading": (loading),  \
        "elementType": "program",  \
        "archive": (archive),  \
        "modalVisible": (modalVisible)
      })

    )

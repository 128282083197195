
import React from 'react'
import {Link} from 'react-router-dom'
import {withRouter} from 'react-router'
import {composeComponent} from 'utils/react-tools'
import cx from 'classnames'

import 'theme/grid/grid-styles.sass'
import './Subnav.sass'


export default composeComponent 'Subnav',
  withRouter

  ({links, match}) ->
    React.createElement("div", {"className": "Subnav__nav"},
      React.createElement("div", {"className": "Subnav__navItems container-fluid"},
        (links.map (link, i) ->
          active = if link.strict then match.url is link.to else match.url.indexOf(link.to) is 0
          React.createElement(Link, { \
            "key": (i),  \
            "className": (cx("Subnav__navItem", {active})),  \
            "to": (link.to)
          }, (link.name))
        )
      )
    )
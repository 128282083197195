import React from 'react'
import {composeComponent} from 'utils/react-tools'
import {withRouter} from 'react-router'
import {withState, withHandlers, withStateHandlers, lifecycle, withProps} from 'recompose'
import cx from 'classnames'
import Heading from 'theme/Heading'
import ProgramDetails from './ProgramDetails.coffee'
import { ButtonsSection } from 'theme/Heading/HeadingElements'
import {getPeerConversation, getPrograms, getSets, getConversations, getQuestions, exportPrograms, importPrograms} from 'peerConversations/requests'

import './Preview.sass'

HEADINGS = [
  name: 'Label'
  className: 'cell_name'
,
  name: 'Description'
  className: 'cell_description'
]

export default composeComponent 'Preview',
  withRouter
  withState 'selectedPrograms', 'setProgram', []
  withState 'selectButtonText', 'setSelectButtonText', 'select all'
  withState 'exportButtonText', 'setExportButtonText', 'export all'
  withState 'fileInput', 'setFileInput', null
  withState 'importStatus', 'setImportStatus', null
  withStateHandlers
    peerConversations: []
    programs: []
    sets: []
    conversations: []
    questions: []
    loading: false
    enableEdition: false
    editionButtonText: 'edit'
    exporting: false
    importing: false
  ,
    setPeerConversations: -> (peerConversations) -> {peerConversations}
    setPrograms: -> (programs) -> {programs}
    setSets: -> (sets) -> {sets}
    setConversations: -> (conversations) -> {conversations}
    setQuestions: -> (questions) -> {questions}
    setEdition: ({enableEdition}) -> -> enableEdition: not enableEdition
    setEditionButtonText: ({enableEdition}) -> -> editionButtonText: if enableEdition then 'end edition' else 'edit'
    endLoading: -> -> loading: false
    startLoading: -> -> loading: true
    setExporting:  -> (exporting) -> {exporting}
    setImporting:  -> (importing) -> {importing}

  lifecycle
    componentDidMount: ->
      {startLoading, endLoading, setPeerConversations, setPrograms, setSets, setConversations, setQuestions} = @props
      startLoading()

      a = getPeerConversation()
      .then setPeerConversations

      b = getPrograms()
      .then setPrograms

      c = getSets()
      .then setSets

      d = getConversations()
      .then setConversations

      e = getQuestions()
      .then setQuestions

      Promise.all [a, b, c, d, e]
      .finally endLoading

  withProps ({selectedPrograms, peerConversations}) ->
    initialSelection = peerConversations.map (program) -> {label: program.label, selected: false}
    selectedPrograms: if selectedPrograms.length then selectedPrograms else initialSelection

  withHandlers
    editionOnChange: ({setEdition, setEditionButtonText}) -> (e) ->
      setEdition()
      setEditionButtonText()

    onChangeSelectButton: ({setSelectButtonText, selectButtonText, setProgram, selectedPrograms}) -> (e) ->
      if selectButtonText is 'select all'
        setProgram selectedPrograms.map (p) -> Object.assign {}, p, {selected: true}
        setSelectButtonText 'deselect all'
      else
        setProgram selectedPrograms.map (p) -> Object.assign {}, p, {selected: false}
        setSelectButtonText 'select all'

    onChangeSelection: ({setProgram, selectedPrograms, setExportButtonText}) -> (programLabel) ->
      change = (p) -> if p.label is programLabel then Object.assign {}, p, {selected: !p.selected} else p
      addChanges = selectedPrograms.map (p) -> change p
      checkIfSelected = addChanges.find (p) -> p.selected is true
      exportButtonText = if checkIfSelected then 'export selected' else 'export all'
      setProgram addChanges
      setExportButtonText exportButtonText

    exportPrograms: ({setExporting, selectedPrograms, setProgram, setExportButtonText}) -> ->
      selectedLabels = selectedPrograms
        .filter (p) -> p.selected is true
        .map (p) -> p.label
      setExporting true
      exportPrograms selectedLabels
      .then (programs) ->
        a = document.createElement 'a'
        data = JSON.stringify programs, null, 4
        file = new Blob [data], type: 'text/plain'
        a.href = URL.createObjectURL file
        a.download = "ImperativePrograms.txt"
        a.click()
        setProgram selectedPrograms.map (p) -> Object.assign {}, p, {selected: false}
        setExportButtonText 'export all'
      .finally -> setExporting false

    importPrograms: ({setPrograms, fileInput, setImportStatus, setPeerConversations, setImporting}) -> (e) ->
      file = fileInput.files[0]
      if file.type?.match /text.*/
        reader = new FileReader()

        reader.onload = =>
          try
            setImporting true
            result = JSON.parse reader.result ? '[]'
            importPrograms result
            .then (p) ->
              setPeerConversations p.peerConv
              setPrograms p.programs
              setImportStatus type: 'success'
            .catch (err) ->
              setImportStatus type: 'error', message: e, error: err.message
            .finally -> setImporting false

          catch err
            if err instanceof SyntaxError
              e = 'File is corrupted.'
            else e = 'Unknown error'
            setImportStatus type: 'error', message: e, error: err.message

        reader.readAsText file

  ({
    name
    subtitle
    peerConversations
    programs
    sets
    conversations
    questions
    enableEdition
    editionOnChange
    onChangeSelectButton
    editionButtonText
    selectButtonText
    setPeerConversations
    selectedPrograms
    exportPrograms
    onChangeSelection
    exportButtonText
    setFileInput
    fileInput
    importPrograms
    importing
    exporting
    importStatus
  }) ->
    programFilter = (program)-> program.label is programTree.label

    buttons = [
      {text: editionButtonText, func: editionOnChange},
      {text: 'Import', func: fileInput, disabled: importing}
      {text: exportButtonText, func: exportPrograms, disabled: exporting}
      {text: selectButtonText, func: onChangeSelectButton}
    ]

    React.createElement("div", {"className": "Preview page"},
      React.createElement("div", {"className": "container"},
        React.createElement("input", {"type": "file", "ref": (setFileInput), "onChange": (importPrograms), "className": "Preview__import"}),

        React.createElement(Heading, {  \
          "name": (name),  \
          "subtitle": (subtitle),  \
          "buttonsSection": (
            React.createElement(ButtonsSection, { \
              "buttons": (buttons)
            })
          )
        }),

        (if importStatus?
          React.createElement("div", {"className": (cx "Preview__info", if importStatus.type is 'error' then 'Preview__errors' else 'Preview__success')},
            (if importStatus.type is 'success'
              React.createElement("div", null,
                React.createElement("span", null, "Import Successful")
              )
            else
              React.createElement("div", null,
                (importStatus.message),
                React.createElement("p", null, (importStatus.error))
              )
            )
          )
        ),

        React.createElement("div", {"className": "Preview__header Preview__row"},
          (HEADINGS.map (h, i) ->
            React.createElement("div", {"className": "Preview__#{h.className}", "key": (i)},
              (h.name)
            )
          )
        ),

        React.createElement("div", {"className": "Preview__rows"},
          (
            peerConversations?.map (programTree, index) ->
              React.createElement(ProgramDetails, { \
                "key": (index),  \
                "setTree": (programTree.sets),  \
                "programDetails": (programs.filter (p) -> p.label is programTree.label),  \
                "enableEdition": (enableEdition),  \
                "sets": (sets),  \
                "conversations": (conversations),  \
                "questions": (questions),  \
                "programTree": (programTree),  \
                "setPeerConversations": (setPeerConversations),  \
                "selected": (selectedPrograms.filter (p) -> p.label is programTree.label),  \
                "onChangeSelect": (onChangeSelection)
              })
          )
        )
      )
    )

import React from 'react'
import {composeComponent} from 'utils/react-tools'
import cx from 'classnames'

import './Loader.sass'



export default composeComponent 'Loader',
  ({className, loading, size = '3'}) ->
    React.createElement("div", {"className": (cx "Loader", className, visible: loading)},
      React.createElement("span", {"className": "far fa-spinner-third fa-spin fa-#{size}x"})
    )

import React from 'react'
import {composeComponent} from 'utils/react-tools'
import * as _ from 'ramda'
import {SubmissionError} from 'redux-form'
import moment from 'moment'
import {reduxForm, Field} from 'redux-form'
import {TextField, Select} from 'theme/Form'
import Loader from 'theme/Loader'
import cx from 'classnames'
import {required} from 'validate'

import '../../jobHacks/JobHack/JobHack.sass'

fieldRequired = required 'Cannot be empty'

export default composeComponent 'Competency.Form',
  reduxForm
    form: 'CompetencyForm'
    enableReinitialize: true

  ({
    handleSubmit
    options = {}
    pristine
    submitting
    submitFailed
    initialValues
    err
  }) ->
    warning = if initialValues?.id then -> 'Changing this may lead to inapropriate import-export process.'

    React.createElement("form", {"onSubmit": (handleSubmit)},
      React.createElement("div", null,
         React.createElement("h2", null, "Details"),
          React.createElement("p", null,
            React.createElement("span", null, "Edit details and remember to save changes. ")
          )
      ),
      React.createElement(Loader, {"loading": (submitting)}),
      React.createElement(Field, { \
        "name": "label",  \
        "placeholder": "Competency identifier",  \
        "label": "Label",  \
        "component": (TextField),  \
        "validate": (fieldRequired),  \
        "warn": (warning)
      }),

      (if submitFailed and err
        React.createElement("div", {"className": "JobHack__error"}, (err))
      ),

      React.createElement("button", { \
        "type": "submit",  \
        "className": "btn btn_secondary btn_solid_bluePurple JobHack__btn",  \
        "disabled": (pristine || submitting)
      }, "Save")
    )

import api, {saveAuth, authorizationHeader} from 'api'


export signin = (credentials) ->
  new Promise (resolve, reject) ->
    api(
      method: 'post'
      url: '/session'
      data: credentials
    )
    .then (auth) ->
      authorizationHeader(auth)
      .then (header) ->
        api(
          mathod: 'get'
          url: '/members/me'
          headers: header
        )
        .then (user) ->
          if user.isSuperAdmin
            saveAuth Object.assign {}, auth, {user}
            resolve user
          else reject data: message: 'Not authorized to access this page'
    .catch reject

import React from 'react'
import {composeComponent, setPropTypes} from 'utils/react-tools'
import {withProps} from 'recompose'
import Header from 'theme/Header'
import Footer from 'theme/Footer'
import Program from './Program/Program.coffee'
import Set from './Set/Set.coffee'
import Conversation from './Conversation/Conversation.coffee'
import Question from './Question/Question.coffee'

import {getUser} from 'api'

export default composeComponent 'ConversationElement',
  
  withProps ({match: {params}}) ->
    section: params?.element

  ({section}) ->
    user = getUser()

    React.createElement("div", {"className": "PeerConversations page"},
      React.createElement(Header, {"user": (user)}),
      (
        switch section
          when 'programs'
            React.createElement(Program, null)
          when 'sets'
            React.createElement(Set, null)
          when 'conversations'
            React.createElement(Conversation, null)
          when 'questions'
            React.createElement(Question, null)
      ),
      React.createElement(Footer, null)
    )
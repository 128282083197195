import React from 'react'
import {composeComponent} from 'utils/react-tools'
import {Link} from 'react-router-dom'
import {withRouter} from 'react-router'
import * as _ from 'ramda'
import {withHandlers, withStateHandlers, lifecycle, withProps, withState, mapProps} from 'recompose'
import {SubmissionError} from 'redux-form'
import InView from 'react-inview-monitor'
import moment from 'moment'
import cx from 'classnames'
import Header from 'theme/Header'
import Loader from 'theme/Loader'
import Footer from 'theme/Footer'
import Translation from 'theme/TranslationView/Translation.coffee'
import ArchiveModal from 'theme/ArchiveModal'
import {getUser} from 'api'
import {getCompetency, updateCompetency, createCompetency, getLanguages, deleteCompetency} from 'competencies/requests'
import Form from './Form'

import 'jobHacks/JobHack/JobHack.sass'

export default composeComponent 'Competency',
  withRouter
  withProps ({match: {params}}) ->
    competencyId: params.id
  withStateHandlers
    loading: true
    competency: {}
    languages: null
    modalVisible: false
    translationsVisible: false
  ,
    setCompetency: -> (competency) -> {competency}
    endLoading: -> -> loading: false
    startLoading: -> -> loading: true
    setSortBy: ({sortBy, sortDir}) -> (column) ->
      if column.name isnt sortBy.name
        sortBy: column
        sortDir: 'asc'
      else switch sortDir
        when 'asc' then sortDir: 'desc'
        when 'desc' then sortDir: 'asc'
        else sortDir: 'asc'
    changeFilter: -> (filters) -> filters: filters
    showMore: ({page}) -> -> page: page + 1
    setLanguages: -> (languages) -> {languages}
    showModal: -> (data) -> modalVisible: true
    closeModal: -> -> modalVisible: false
    showTranslations: -> -> translationsVisible: true
    closeTranslations: -> -> translationsVisible: false

  lifecycle
    componentDidMount: ->
      {startLoading, endLoading, setCompetency, competencyId, setLanguages} = @props
      startLoading()

      a = if competencyId then getCompetency(competencyId) else Promise.resolve({})
      a.then setCompetency

      b = getLanguages()
      .then setLanguages

      Promise.all [a, b]
      .finally endLoading

  withHandlers
    submit: ({competencyId, history, setCompetency}) -> (values) ->
      if competencyId
        updateCompetency competencyId, values
        .then setCompetency
      else
        createCompetency values
        .then (c) ->
          setCompetency c
          history.push "/competencies/#{c.id}"

    archive: ({competency, startLoading, endLoading, setCompetency, closeModal}) -> ->
      startLoading()

      deleteCompetency competency.id
      .then ->
        getCompetency competency.id
        .then setCompetency
        .then closeModal
        .finally endLoading

    unarchive: ({competency, startLoading, endLoading, setCompetency}) -> ->
      startLoading()

      updateCompetency competency.id, competency
      .then setCompetency
      .finally endLoading

  ({
    loading
    competency
    match
    showModal
    closeModal
    modalVisible
    submit
    languages
    archive
    unarchive
    competencyId
    showTranslations
    closeTranslations
    translationsVisible
  }) ->
    user = getUser()
    React.createElement("div", {"className": "JobHack page"},
      React.createElement(Header, {"user": (user)}),
      React.createElement("div", {"className": "container"},
        React.createElement(Loader, {"loading": (loading and not modalVisible)}),

        React.createElement("div", {"className": "JobHack__heading"},
          React.createElement("div", null,
            React.createElement("h1", {"className": "JobHack__title"},
              React.createElement(Link, {"to": "/competencies"},
                React.createElement("span", {"className": "far fa-chevron-left"}),
                (competency.label ? 'New competency')
              )
            )
          ),
          React.createElement("div", {"className": "JobHack__headingBtns"},
            (if competency.archiveDate
              React.createElement("button", {"onClick": (unarchive), "className": "btn btn_secondary btn_outlined_bluePurple"}, "Unarchive")
            else
              React.createElement("button", {"onClick": (showModal), "className": "btn btn_secondary btn_outlined_bluePurple"}, "Archive")
            ),
            React.createElement("button", { \
              "onClick": (if translationsVisible then closeTranslations else showTranslations),  \
              "className": "btn btn_secondary btn_outlined_bluePurple",  \
              "disabled": (!competencyId)
            },
              (if translationsVisible then 'Close content' else 'Edit content')
            )
          )
        ),
        React.createElement("div", {"className": "JobHack__content"},
          React.createElement("div", {"className": (cx "form", if translationsVisible then 'form__translationVisible')},
            (unless loading
              React.createElement(Form, { \
                "onSubmit": (submit),  \
                "initialValues": (competency)
              })
            )
          ),
          (if translationsVisible
            React.createElement(Translation, { \
              "languages": (languages),  \
              "element": (competency),  \
              "type": "competency"
            })
          )
        )
      ),

      React.createElement(Footer, null),

      React.createElement(ArchiveModal, { \
        "closeModal": (closeModal),  \
        "loading": (loading),  \
        "elementType": "competency",  \
        "archive": (archive),  \
        "modalVisible": (modalVisible)
      })

    )
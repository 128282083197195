import api from 'api'
import * as _ from 'ramda'

export getSignatures = ->
  api.get '/cms/admin/signatures'
  .then _.map (s) -> _.compose(
    _.evolve key: _.replace /\./g, '/'
    _.assoc 'label', s.key
  ) s

export getDraft = (id) ->
  api.get "/cms/admin/i18n/#{id}"
  .then _.evolve translation: _.evolve(
    values: _.map (v) -> _.compose(
      _.identity
      _.assoc 'label', v.key
      _.assoc 'key', _.replace /\./g, '/', v.key
    ) v
  )

export validate = (dto, keysAdded = false) ->
  new Promise (resolve, reject) ->
    errors = validateFields dto
    if errors
      reject errors
    else
      api(
        method: 'post'
        url: '/cms/admin/i18n/validate'
        data: dto
      )
      .then (res) ->
        if res instanceof Array
          if keysAdded and (res.length is 1) and (res[0].errorCode is 'WrongKeysProvided')
            resolve res
          else
            reject {message: 'ValidationFailed', errors: res}
        else resolve res
      .catch reject


validateFields = (dto) ->
  splitFunc = (f) -> f.value?
  [hasValue, noValue] = _.partition splitFunc, dto.values
  if noValue.length is 0
    null
  else
    errors = noValue.map (field) -> key: field.key, errorCode: 'NoValue'
    message: 'ValidationFailed', errors: errors


export updateLanguage = (id, {values} = {}) ->
  dto = values: values.map (v) ->
    key: v.label
    value: v.value

  new Promise (resolve, reject) ->
    validate dto
    .then ->
      api(
        method: 'post'
        url: "/cms/admin/i18n/#{id}"
        data: dto
      )
      .then resolve, reject
    .catch reject

export createDraft = (language, {values} = {}, opts) ->
  dto = values: values.map (v) ->
    key: v.label
    value: v.value

  new Promise (resolve, reject) ->
    validate dto, opts?.keysAdded
    .then ->
      api(
        method: 'post'
        url: "/cms/admin/i18n/import/#{language}"
        data: dto
      )
      .then resolve, reject
    .catch reject

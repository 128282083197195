import React from 'react'
import {composeComponent} from 'utils/react-tools'
import {Link} from 'react-router-dom'
import {withRouter} from 'react-router'
import * as _ from 'ramda'
import {withHandlers, withProps, withState} from 'recompose'
import cx from 'classnames'
import Collapse from 'react-collapse'
import QuestionDetails from './QuestionDetails.coffee'
import Form from './Form.coffee'
import {EditionButtons} from './EditionButtons.coffee'
import {deleteSetConversation, getPeerConversation, addConversationQuestion, updateSetConversation} from 'peerConversations/requests'

import './Preview.sass'

export default composeComponent 'SetDetails.ConversationDetails',
  withRouter
  withState 'collapsed', 'open', true
  withState 'detailsVisible', 'showDetails', false
  withProps ({opened, collapsed, conversationDetails, questions, questionTree}) ->
    opened: opened or not collapsed
    convId: (_.head conversationDetails)?.id
    localQuestions: questions.map (quest) -> {label: quest.label}
    questionsAssigned: questionTree.map (quest) -> {label: quest.label}

  withProps ({localQuestions, questionsAssigned, questions}) ->
    questToAddLabels = _.difference localQuestions, questionsAssigned
    questToAdd = questToAddLabels.map (label) -> _.find(_.propEq('label', label.label))(questions)
    options: questToAdd.map (quest) -> {label: quest.label, value: quest.id}

  withHandlers
    deleteAction: ({setId, setPeerConversations, conversationDetails}) -> ->
      deleteSetConversation setId, (_.head conversationDetails).id
        .then () -> getPeerConversation()
          .then (tree) ->
            setPeerConversations tree
    submit: ({convId, questionTree, setPeerConversations}) -> (values) ->
      addConversationQuestion Object.assign({}, {definitionId: convId, questionId: values.id, seq: questionTree.length+1})
        .then () -> getPeerConversation()
          .then (tree) ->
            setPeerConversations tree
    upAction: ({setId, convId, previousConv, setPeerConversations}) -> ->
      if _.head previousConv
        updateSetConversation setId, Object.assign({}, { firstId: convId, secondId: (_.head previousConv)?.id})
          .then () -> getPeerConversation()
            .then (tree) ->
              setPeerConversations tree
    downAction: ({setId, convId, nextConv, setPeerConversations}) -> ->
      if _.head nextConv
        updateSetConversation setId, Object.assign({}, {firstId: (_.head nextConv)?.id}, secondId: convId)
          .then () -> getPeerConversation()
            .then (tree) ->
              setPeerConversations tree

  ({
    questionTree
    conversationDetails
    questions
    enableEdition
    opened
    open
    collapsed
    deleteAction
    submit
    options
    setPeerConversations
    downAction
    upAction
  }) ->
    conversation = _.head conversationDetails
  
    React.createElement("div", {"className": "Preview__item"},
      React.createElement("div", {"className": (cx "Preview__row", "Preview__itemContent_row", if opened then "Preview__opened")},
        React.createElement("div", {"className": (cx "cell", "Preview__cell_name" ), "onClick": (-> open !collapsed)}, """
          Conv """, (conversation?.label), " (", (questionTree.length), """)
"""),
        React.createElement("div", {"className": (cx "cell", "Preview__cell_description"), "onClick": (-> open !collapsed)},
          (conversation?.description)
        ),
        (if enableEdition
          React.createElement(EditionButtons, { \
            "enableEdition": (enableEdition),  \
            "deleteAction": (deleteAction),  \
            "upAction": (upAction),  \
            "downAction": (downAction)
          })
        ),
        React.createElement("span", {"className": (cx 'btnLink btnLink_secondary', "Preview__cell_icon"), "onClick": (-> open !collapsed)},
          (React.createElement("i", {"className": "fal #{if opened then 'fa-chevron-up btn__icon_after' else 'fa-chevron-down btn__icon_after'}"}))
        )
      ),
      React.createElement("div", {"className": "Preview__itemContent"},
        React.createElement(Collapse, {"isOpened": (opened), "hasNestedCollapse": (true)},
          (
            questionTree.map (question, index) ->
              React.createElement(QuestionDetails, { \
                "key": (index),  \
                "questionDetails": (questions.filter (q) -> q.label is question.label),  \
                "enableEdition": (enableEdition),  \
                "setPeerConversations": (setPeerConversations),  \
                "conversationId": (conversation.id),  \
                "previousQuest": (questions.filter (quest) -> quest.label is questionTree[index-1]?.label),  \
                "nextQuest": (questions.filter (quest) -> quest.label is questionTree[index+1]?.label),  \
                "conversationId": (conversation.id)
              })
          )
        ),
        React.createElement(Form, { \
          "enableEdition": (enableEdition),  \
          "placeholderText": "Add new question",  \
          "onSubmit": (submit),  \
          "options": (options)
        })
      )
    )

import api from 'api'
import * as _ from 'ramda'

export getTransalations = (id, data) ->
  api.get "cms/admin/i18n/#{id}", data

export validate = (dto, params, keysAdded = false) ->
  new Promise (resolve, reject) ->
    errors = validateFields dto
    if errors
      reject errors
    else
      api(
        method: 'post'
        url: '/cms/admin/i18n/validate'
        data: dto
        params: {prefix: params.params.prefix}
      )
      .then (res) ->
        if res instanceof Array
          if keysAdded and (res.length is 1) and (res[0].errorCode is 'WrongKeysProvided')
            resolve res
          else
            reject {message: 'ValidationFailed', errors: res}
        else resolve res
      .catch reject

validateFields = (dto) ->
  splitFunc = (f) -> f.value?
  [hasValue, noValue] = _.partition splitFunc, dto.values
  if noValue.length is 0
    null
  else
    errors = noValue.map (field) -> key: field.key, errorCode: 'NoValue'
    message: 'ValidationFailed', errors: errors

export editTransalations = (id, values, params) ->

  new Promise (resolve, reject) ->
    validate values, params
    .then ->
      api.patch "/cms/admin/i18n/#{id}", values
      .then ->
        getTransalations id, params
      .then resolve, reject
    .catch reject
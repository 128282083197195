import React from 'react'
import {composeComponent} from 'utils/react-tools'
import './Heading.sass'

export default composeComponent 'Heading',
  
  ({name, subtitle, linksSection, buttonsSection}) ->
    title =  name.charAt(0).toUpperCase() + name.slice(1)

    React.createElement("div", {"className": "Heading__heading"},
      React.createElement("div", null,
        React.createElement("h1", {"className": "Heading__title"}, (title)),
        React.createElement("p", null, (subtitle))
      ),
      React.createElement("div", {"className": "JobHacks__headingBtns"},
        (linksSection),
        (buttonsSection)
      )
    )
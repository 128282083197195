import React from 'react'
import RSelect from 'react-select'
import {composeComponent} from 'utils/react-tools'
import {withState, withHandlers, withProps} from 'recompose'
import {Prompt} from 'react-router-dom'
import {reduxForm, Field, FieldArray} from 'redux-form'
import {withRouter} from 'react-router-dom'
import {required} from 'validate'
import InView from 'react-inview-monitor'
import TableField from './TableField.coffee'

VISIBLE_FIELDS = 10
fieldRequired = required 'Cannot be empty'


ArrayFields = composeComponent 'Form.ArrayFields',
  withState 'count', 'setCount', VISIBLE_FIELDS
  withHandlers
    loadMore: ({setCount, count, fields}) -> ->
      setCount count + VISIBLE_FIELDS
    findFieldIndex: ({fields}) -> (key) ->
      fields.getAll().findIndex (f) -> f.key is key
  
  withProps ({fields}) ->
    values: fields.getAll() ? []

  ({fields, disabled, loadMore, count, values, findFieldIndex}) ->

    maxCount = if count > values?.length then values?.length else count

    React.createElement("tbody", null,
      (if values?.length then [0...maxCount].map (i, index) ->
        value = values[i]
        error = errs?.find (e) -> e.key is value.key
        field = "#{fields.name}[#{findFieldIndex value.key}].value"
        React.createElement(Field, { \
          "key": (field),  \
          "name": (field),  \
          "placeholder": "Enter value",  \
          "label": (value.key),  \
          "component": (TableField),  \
          "validate": (fieldRequired),  \
          "textarea": true,  \
          "disabled": (disabled),  \
          "err": (error?.value)
        })
      ),
      (if maxCount < values?.length
        React.createElement("tr", null,
          React.createElement("td", {"colSpan": "2"},
            React.createElement(InView, { \
              "onInView": (loadMore),  \
              "intoViewMargin": "0%",  \
              "repeatOnInView": true
            }, "Loading more...")
          )
        )
      )
    )

export default composeComponent 'Form',
  withRouter
  reduxForm
    form: 'TranslationForm'
    enableReinitialize: true

  ({handleSubmit, onSubmit, submitting, disabled, pristine, options, language, onChangeLanguage, err, submitFailed}) ->

    React.createElement("div", null,
      React.createElement("form", {"onSubmit": (handleSubmit unless disabled)},
        React.createElement("h2", null, "Content"),
        React.createElement("p", null,
          React.createElement("span", null, "Select content language and then click on item to edit. "),
          React.createElement("span", null, "Hover to show description of the field.")
        ),
        React.createElement("div", {"className": "TranslationView__filters"},
          React.createElement(RSelect, { \
            "className": "TranslationView__selec",  \
            "classNamePrefix": "TranslationView__selec",  \
            "options": (options),  \
            "placeholder": "Select translation language",  \
            "onChange": (onChangeLanguage),  \
            "value": (language)
          })
        ),
        (if submitFailed and err
          React.createElement("div", {"className": "TranslationView__errors"}, """
            Saving failed because of following problems:
""", (if err.accum?.length
              React.createElement("ul", null,
                (err.accum?.map (e) ->
                  React.createElement("li", {"key": (e)}, (e))
                )
              )
            else if err.errors?.length
              React.createElement("p", null, "Validation failed")
            else
              React.createElement("p", null, (err))
            )
          )
        ),
        React.createElement("div", {"className": "TranslationView__table"},
          React.createElement("table", null,
            React.createElement("thead", null,
              React.createElement("tr", null,
                React.createElement("th", null, """
                  Key
"""),
                React.createElement("th", null, """
                  Value
""")
              )
            ),
            React.createElement(FieldArray, { \
              "name": "values",  \
              "component": (ArrayFields),  \
              "disabled": (submitting or disabled),  \
              "errs": (err?.errors)
            })
          ),
          React.createElement("button", { \
            "type": "button",  \
            "className": "btn btn_secondary btn_solid_bluePurple",  \
            "onClick": (handleSubmit onSubmit),  \
            "disabled": (pristine or submitting)
          }, "Save")
        )
      ),

      React.createElement(Prompt, { \
        "when": (not pristine),  \
        "message": "Are you sure you want to leave this page? All unsaved changes will be lost!"
      })
    )

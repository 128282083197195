import React from 'react'
import {composeComponent} from 'utils/react-tools'
import TranslationView from 'theme/TranslationView'
import {withProps} from 'recompose'

export default composeComponent 'ProgramTranslation',
  withProps ({languages}) ->
    languagesTypes = [...new Set languages?.map (language) -> language.language]
    languagesGroup = languagesTypes?.map (l) -> (languages.filter (lan) -> lan.language == l)
    languagesHighestVersions = languagesGroup?.map (language) -> language.reduce (prev, curr) -> if (prev.version > curr.version) is true then prev else curr
    options: languagesHighestVersions.map (language) -> {label: language.language, value: language.id}

  ({languages, element, type, options}) ->

    React.createElement("div", {"className": "TranslationView"},
      React.createElement(TranslationView, { \
        "type": (type),  \
        "element": (element),  \
        "options": (options)
      })
    )
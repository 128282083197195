import React from 'react'
import {composeComponent} from 'utils/react-tools'
import {Link} from 'react-router-dom'
import {withRouter} from 'react-router'
import * as _ from 'ramda'
import RSelect from 'react-select'
import {withHandlers, lifecycle, withProps, withStateHandlers, withState, mapProps} from 'recompose'
import InView from 'react-inview-monitor'
import moment from 'moment'
import Heading from 'theme/Heading'
import Loader from 'theme/Loader'
import DeleteModal from 'theme/DeleteModal'
import Search from 'Search'
import { LinksSection } from 'theme/Heading/HeadingElements'
import {getQuestions, deleteQuestion, getActiveLanguage, getOrganizations} from 'peerConversations/requests'

import './PeerConversation.sass'

HEADINGS = [
  name: 'Label'
  sortFunc: _.prop 'label'
,
  name: 'Description'
  sortFunc: _.prop 'description'
,
  name: 'Organization'
  sortFunc: _.prop 'organization'
,
  name: 'Last Modified'
  sortFunc: _.prop 'modifyDate'
,
  name: 'Archived'
  sortFunc: _.prop 'archiveDate'
]

export default composeComponent 'Questions',
  withRouter
  withState 'language', 'setLanguage', null
  withStateHandlers
    loading: false
    questions: []
    organizations: []
    sortBy: HEADINGS[0]
    sortDir: 'asc'
    filters: []
    exporting: false
    modal:
      visible: false
      data: null
    query: ''
    workingQuestionList: []

  ,
    setQuestions: -> (questions) -> {questions}
    setOrganizations: -> (organizations) -> {organizations}
    endLoading: -> -> loading: false
    startLoading: -> -> loading: true
    setSortBy: ({sortBy, sortDir}) -> (column) ->
      if column.name isnt sortBy.name
        sortBy: column
        sortDir: 'asc'
      else switch sortDir
        when 'asc' then sortDir: 'desc'
        when 'desc' then sortDir: 'asc'
        else sortDir: 'asc'
    showModal: -> (data) -> modal: {visible: true, data}
    closeModal: ({modal}) -> -> modal: _.merge modal, visible: false
    changeFilter: -> (filters) -> filters: filters
    setQuery: -> (query) -> {query}
    setWorkingQuestionList: -> (workingQuestionList) -> {workingQuestionList}

  lifecycle
    componentDidMount: ->
      {startLoading, endLoading, setQuestions, setQuery, setLanguage, setOrganizations, setWorkingQuestionList} = @props
      startLoading()
      setQuery ''

      a = getQuestions()
      .then setQuestions

      b = getActiveLanguage()
      .then setLanguage

      c = getOrganizations()
      .then setOrganizations

      Promise.all [a, b, c]
      .finally endLoading

   mapProps (props) ->
    reverse = if props.sortDir is 'desc' then _.reverse else _.identity
    filters = _.pluck 'value', props.filters
    filterFunc = (p) ->
      unless filters.length
        return true
      propsToFilter = _.flatten [p.organizationId]
      _.any(_.contains _.__, filters) propsToFilter
    _.evolve(
      questions: _.compose _.filter(filterFunc), reverse, _.sortBy(props.sortBy?.sortFunc)
    ) props

  withProps ({organizations}) ->
    options: [
      {
        'label': 'Organizations'
        'options': organizations.map (organization) -> {label: organization.name, value: organization.id}
      }
    ]

  withProps ({questions, query}) ->
    if (query? and query.length > 0)
      tempQuestions = questions?.filter((a) -> 
        (a.label.toLowerCase().indexOf(query.toLowerCase()) > -1) or
        (a.description.toLowerCase().indexOf(query.toLowerCase()) > -1) 
        ) 
      workingQuestionList: tempQuestions
    else
      workingQuestionList: questions 

  withHandlers
    deleteQuestion: ({setQuestions, startLoading, endLoading, questions, closeModal}) -> (question) -> (e) ->
      e.stopPropagation()
      startLoading()
      deleteQuestion question.id
      .then ->
        getQuestions()
         .then setQuestions
        closeModal()
      .finally endLoading

  withHandlers
    _searchQuestion: ({setQuery}) -> (questions) -> (query) ->
      setQuery query

  ({
    loading
    name
    subtitle
    questions
    organizations
    filters
    options
    setSortBy
    sortBy
    sortDir
    history
    deleteQuestion
    modal
    showModal
    closeModal
    changeFilter
    query
    setQuery
    _searchQuestion
    workingQuestionList
    setWorkingQuestionList
  }) ->
    links = [
      {text:"Edit #{name} content", link:"/languages/English/#{language?.id}?key=peerconversation.question"},
      {text:"Add new question", link:"/peer-conversations/questions/new"}
    ]
    organizationName = (id) -> organization.name for organization in organizations when id is organization.id

    React.createElement("div", {"className": "PeerConversation container"},
      React.createElement(Loader, {"loading": ((loading))}),
      React.createElement(Heading, {  \
        "name": (name),  \
        "subtitle": (subtitle),  \
        "linksSection": (
          React.createElement(LinksSection, { \
            "links": (links)
          })
        )
      }),

      React.createElement("div", {"className": "PeerConversation__filters"},
        React.createElement(RSelect, { \
          "className": "PeerConversation__select",  \
          "classNamePrefix": "PeerConversation__select",  \
          "options": (options),  \
          "isMulti": true,  \
          "value": (filters),  \
          "onChange": (changeFilter),  \
          "placeholder": "Filter"
        }),
        React.createElement(Search, { \
          "search": (_searchQuestion questions),  \
          "placeholder": "Search for questions",  \
          "name": "searchBox",  \
          "value": (query)
        })         
      ),

      React.createElement("div", {"className": "PeerConversation__table"},
        React.createElement("table", null,
          React.createElement("thead", null,
            React.createElement("tr", null,
              (HEADINGS.map (h, i) ->
                React.createElement("th", {"onClick": (-> setSortBy h), "key": (i)},
                  (h.name),
                  (if h.name is sortBy.name
                    React.createElement("i", {"className": "far #{if sortDir is 'asc' then 'fa-chevron-down' else 'fa-chevron-up'}"})
                  )
                )
              ),
              React.createElement("th", null)
            )
          ),
          React.createElement("tbody", null,
            (workingQuestionList.map (question) ->
              React.createElement("tr", {"key": (question.id), "onClick": (-> history.push "/peer-conversations/questions/#{question.id}")},
                React.createElement("td", null,
                  (question?.label)
                ),
                React.createElement("td", null,
                  (question?.description)
                ),
                React.createElement("td", null,
                  (organizationName question?.organizationId)
                ),
                React.createElement("td", null,
                  (moment(question?.modifyDate).format 'MMM DD, YYYY')
                ),
                React.createElement("td", null,
                  (if question?.archiveDate
                    React.createElement("span", {"className": "far fa-check"})
                  )
                ),
                React.createElement("td", null,
                  React.createElement("button", { \
                    "className": "btnLink",  \
                    "onClick": ((e) ->
                      e.stopPropagation()
                      showModal question
                    )
                  },
                    (if !question?.archiveDate
                      React.createElement("span", {"className": "fas fa-trash"})
                    )
                  ),
                  React.createElement(Link, { \
                    "className": "btn btn_compact btn_solid_bluePurple",  \
                    "to": "/languages/English/#{language?.id}?key=peerconversation.question.#{question.label.toLowerCase()}",  \
                    "onClick": ((e) -> e.stopPropagation())
                  }, """
                    Edit content
""")
                )
              )
            )
          )
        )
      ),

      React.createElement(DeleteModal, { \
        "modal": (modal),  \
        "closeModal": (closeModal),  \
        "loading": (loading),  \
        "removedItemName": "question",  \
        "deleteFunc": (deleteQuestion)
      })

    )

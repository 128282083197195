import api from 'api'
import * as _ from 'ramda'

export getJobHacks = (params) ->
  api.get '/admin/jobhack/list', params: _.merge params, includeArchive: false

export getActiveLanguage = ->
  api.get '/cms/admin/i18n'
  .then (languages) ->
    languages.find (l) -> l.language is 'English' and l.state is 'Activated'

export getLanguages = ->
  api.get '/cms/admin/i18n'

export getOrganizations = ->
  api.get './organizations'

export getPrograms = ->
  api.get '/admin/peerconversationdefinition/program', params: includeArchived: true

export createProgram = (data) ->
  api.post '/admin/peerconversationdefinition/program', data

export getProgram = (id) ->
  api.get '/admin/peerconversationdefinition/program', params: {id, includeArchived: true}
  .then _.head

export updateProgram = (id, data) ->
  new Promise (resolve, reject) ->
    api.post "/admin/peerconversationdefinition/program/#{id}", _.omit ['modifyDate', 'createDate', 'id', 'modifiedBy'], data
    .then ->
      getProgram id
      .then resolve
    .catch reject

export deleteProgram = (id) ->
  api.delete "/admin/peerconversationdefinition/program/#{id}"

export getSets = ->
  api.get '/admin/peerconversationdefinition/set', params: includeArchived: true

export createSet = (data) ->
  api.post '/admin/peerconversationdefinition/set', data

export getSet = (id) ->
  api.get '/admin/peerconversationdefinition/set', params: {id, includeArchived: true}
  .then _.head

export updateSet = (id, data) ->
  new Promise (resolve, reject) ->
    api.post "/admin/peerconversationdefinition/set/#{id}", _.omit ['modifyDate', 'createDate', 'id', 'modifiedBy'], data
    .then ->
      getSet id
      .then resolve
    .catch reject

export deleteSet = (id) ->
  api.delete "/admin/peerconversationdefinition/set/#{id}"

export getConversations = ->
  api.get '/admin/peerconversationdefinition/definition', params: includeArchived: true

export createConversation = (data) ->
  api.post '/admin/peerconversationdefinition/definition', data

export getConversation = (id) ->
  api.get '/admin/peerconversationdefinition/definition', params: {id, includeArchived: true}
  .then _.head

export updateConversation = (id, data) ->
  new Promise (resolve, reject) ->
    api.post "/admin/peerconversationdefinition/definition/#{id}", _.omit ['modifyDate', 'createDate', 'id', 'modifiedBy'], data
    .then ->
      getConversation id
      .then resolve
    .catch reject

export deleteConversation = (id) ->
  api.delete "/admin/peerconversationdefinition/definition/#{id}"

export getQuestions = ->
  api.get '/admin/peerconversationdefinition/question', params: includeArchived: true

export createQuestion = (data) ->
  api.post '/admin/peerconversationdefinition/question', data

export getQuestion = (id) ->
  api.get '/admin/peerconversationdefinition/question', params: {id, includeArchived: true}
  .then _.head

export updateQuestion = (id, data) ->
  new Promise (resolve, reject) ->
    api.post "/admin/peerconversationdefinition/question/#{id}", _.omit ['modifyDate', 'createDate', 'id', 'modifiedBy'], data
    .then ->
      getQuestion id
      .then resolve
    .catch reject

export deleteQuestion = (id) ->
  api.delete "/admin/peerconversationdefinition/question/#{id}"

export getPeerConversation = ->
  api.get '/admin/peerconversationdefinition/list',

export deleteProgramSet = (pId, sId) ->
  api.delete "/admin/peerconversationdefinition/programset/#{pId}/#{sId}"

export addProgramSet = (data) ->
  api.post '/admin/peerconversationdefinition/programset', data

export updateProgramSet = (pId, data) ->
  api.patch "/admin/peerconversationdefinition/programset/#{pId}", data

export getCompetencies = (params) ->
  api.get '/admin/competency/list', params: includeArchive: false

export addSetConversation = (data) ->
  api.post '/admin/peerconversationdefinition/setdefinition', data

export deleteSetConversation = (sId, dId) ->
  api.delete "/admin/peerconversationdefinition/setdefinition/#{sId}/#{dId}"

export updateSetConversation = (sId, data) ->
  api.patch "/admin/peerconversationdefinition/setdefinition/#{sId}", data

export addConversationQuestion = (data) ->
  api.post '/admin/peerconversationdefinition/definitionquestion', data

export deleteConversationQuestion = (dId, qId) ->
  api.delete "/admin/peerconversationdefinition/definitionquestion/#{dId}/#{qId}"

export updateConversationQuestion = (cId, data) ->
  api.patch "/admin/peerconversationdefinition/definitionquestion/#{cId}", data

export getQuestionType = ->
  api.get '/dictionaries/PeerConversationDefinitionQuestionType'
  .catch -> Promise.resolve []

export getAnswerType = ->
  api.get '/dictionaries/PeerConversationDefinitionAnswerType'
  .catch -> Promise.resolve []

export getEnumerators = ->
  Promise.all [getQuestionType(), getAnswerType()]
  .then ([questionType, answerType]) ->
    {questionType, answerType}

export getProgramsOrganizations = ->
  api.get '/admin/peerconversationdefinition/organization'

export addProgramsOrganizations = (pId, oId) ->
  api.post "/admin/peerconversationdefinition/organization/#{pId}/#{oId}"

export deleteProgramsOrganizations = (pId, oId) ->
  api.delete "/admin/peerconversationdefinition/organization/#{pId}/#{oId}"

export exportPrograms = (data) ->
  api.get "admin/peerconversationdefinition/export", params: {label: data}

export importPrograms = (data) ->
  api.post "admin/peerconversationdefinition/upload", data
  .then ->
    Promise.all [getPrograms(), getPeerConversation()]
    .then ([programs, peerConv]) ->
      {programs, peerConv}

import React from 'react'
import {composeComponent} from 'utils/react-tools'
import {Link} from 'react-router-dom'
import {withRouter} from 'react-router'
import * as _ from 'ramda'
import {withHandlers, withStateHandlers, lifecycle, withProps} from 'recompose'
import cx from 'classnames'
import Loader from 'theme/Loader'
import Form from './Form.coffee'
import Translation from 'theme/TranslationView/Translation'
import ArchiveModal from 'theme/ArchiveModal'
import {getQuestion, createQuestion, updateQuestion, getOrganizations, getLanguages, getEnumerators, getJobHacks, deleteQuestion} from 'peerConversations/requests'

import '../ConversationElements.sass'

export default composeComponent 'Question',
  withRouter
  withProps ({match: {params}}) ->
    questionId: params.id
  withStateHandlers
    organizations: []
    loading: false
    question: {}
    enumerators: {}
    languages: null
    translationsVisible: false
    jobHacks: []
    modalVisible: false
  ,
    setQuestion: -> (question) -> {question}
    setOrganizations: -> (organizations) -> {organizations}
    setJobHacks: -> (jobHacks) -> {jobHacks}
    endLoading: -> -> loading: false
    startLoading: -> -> loading: true
    setEnumerators: -> (enumerators) -> {enumerators}
    setSortBy: ({sortBy, sortDir}) -> (column) ->
      if column.name isnt sortBy.name
        sortBy: column
        sortDir: 'asc'
      else switch sortDir
        when 'asc' then sortDir: 'desc'
        when 'desc' then sortDir: 'asc'
        else sortDir: 'asc'
    changeFilter: -> (filters) -> filters: filters
    showMore: ({page}) -> -> page: page + 1
    setLanguages: -> (languages) -> {languages}
    showTranslations: -> -> translationsVisible: true
    closeTranslations: -> -> translationsVisible: false
    showModal: -> (data) -> modalVisible: true
    closeModal: -> -> modalVisible: false

  lifecycle
    componentDidMount: ->
      {startLoading, endLoading, setQuestion, questionId, setLanguages, setOrganizations, setEnumerators, setJobHacks} = @props
      startLoading()

      a = if questionId then getQuestion(questionId) else Promise.resolve({isGlobal: false})
      a.then setQuestion

      b = getEnumerators()
      .then setEnumerators

      c = getLanguages()
      .then setLanguages

      d = getOrganizations()
      .then setOrganizations

      e = getJobHacks()
      .then setJobHacks

      Promise.all [a, b, c, d, e]
      .finally endLoading

  withProps ({organizations, enumerators, jobHacks}) ->
    enumeratorsOptions=Object.keys(enumerators).reduce (options, key) ->
      _.merge options, "#{key}": enumerators[key].map (o) ->
        label: o.match(/[A-Z][a-z]+/g).join(" ")
        value: o
    , {}
    options: {
      'organization': organizations.map (organization) -> {label: organization.name, value: organization.id}
      'jobHack': jobHacks.map (jh) -> {label: jh.label, value: jh.label}
      enumeratorsOptions...
    }

  withHandlers
    submit: ({questionId, history, setQuestion}) -> (values) ->
      if questionId
        updateQuestion questionId, Object.assign {}, values, {optionsCount: Number values.optionsCount}
        .then setQuestion
      else
        createQuestion Object.assign {}, values, {optionsCount: Number values.optionsCount}
        .then (question) ->
          setQuestion question
          history.push "/peer-conversations/questions/#{question.id}"

    archive: ({question, startLoading, endLoading, setQuestion, closeModal}) -> ->
      startLoading()

      deleteQuestion question.id
      .then ->
        getQuestion question.id
        .then setQuestion
        .then closeModal
        .finally endLoading
    
    unarchive: ({question, startLoading, endLoading, setQuestion}) -> ->
      startLoading()

      updateQuestion question.id, question
      .then setQuestion
      .finally endLoading

  ({
    loading
    question
    organizations
    options
    match
    submit
    questionId,
    params
    enumerators
    languages
    translationsVisible
    showTranslations
    closeTranslations
    showModal
    closeModal
    modalVisible
    unarchive
    archive
  }) ->
    React.createElement("div", {"className": "container"},
      React.createElement(Loader, {"loading": (loading)}),
      React.createElement("div", {"className": "ConversationElements__heading"},
        React.createElement("div", null,
          React.createElement("h1", {"className": "ConversationElements__title"},
            React.createElement(Link, {"to": "/peer-conversations/questions"},
              React.createElement("span", {"className": "far fa-chevron-left"}),
              (question.label ? 'New Question')
            )
          )
        ),
         React.createElement("div", {"className": "ConversationElements__headingBtns"},
          (if question.archiveDate
            React.createElement("button", {"onClick": (unarchive), "className": "btn btn_secondary btn_outlined_bluePurple"}, "Unarchive")
          else
            React.createElement("button", {"onClick": (showModal), "className": "btn btn_secondary btn_outlined_bluePurple"}, "Archive")
          ),
          React.createElement("button", { \
            "onClick": (if translationsVisible then closeTranslations else showTranslations),  \
            "className": "btn btn_secondary btn_outlined_bluePurple",  \
            "disabled": (!questionId)
          },
            (if translationsVisible then 'Close content' else 'Edit content')
          )
        )
      ),

      React.createElement("div", {"className": "ConversationElements__content"},
        React.createElement("div", {"className": (cx "form", if translationsVisible then 'form__translationVisible')},
          (unless loading
            React.createElement(Form, { \
              "onSubmit": (submit),  \
              "options": (options),  \
              "initialValues": (if questionId then question else null)
            })
          )
        ),
        (if translationsVisible
          React.createElement(Translation, { \
            "languages": (languages),  \
            "element": (question),  \
            "type": "peerconversation.question"
          })
        )
      ),

      React.createElement(ArchiveModal, { \
        "closeModal": (closeModal),  \
        "loading": (loading),  \
        "elementType": "question",  \
        "archive": (archive),  \
        "modalVisible": (modalVisible)
      })

    )

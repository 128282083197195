import React from 'react'
import {
  Router
  Route
  Link
  Redirect
  withRouter
  Switch
} from 'react-router-dom'
import {compose} from 'recompose'
import {isLoggedIn, logout} from 'api'
import history from 'api/history'
import Signin from 'Auth/Signin'
import NotFound from 'NotFound'
import Languages from 'Languages'
import Drafts from 'Drafts'
import Draft from 'Draft'
import JobHacks from 'jobHacks/JobHacks'
import JobHack from 'jobHacks/JobHack'
import PeerConversations from 'peerConversations/PeerConversations'
import ConversationElement from 'peerConversations/conversationElements/ConversationElement'
import Competencies from 'competencies/Competencies'
import Competency from 'competencies/Competency'

Logout = (props) =>
  logout()
  React.createElement(Redirect, {"to": "/signin"})

AuthRoutes = (props) =>
  if isLoggedIn()
    React.createElement(Route, Object.assign({},  props ))
  else
    React.createElement(Redirect, Object.assign({"to": "/signin"}, props ))

LoginRoute = (props) =>
  React.createElement(Route, Object.assign({}, \
    props, { \
    "render": ((props) ->
      if isLoggedIn()
        React.createElement(Redirect, {"to": "/"})
      else React.createElement(Signin, Object.assign({},  props ))
    )
  }))


export default () =>
  React.createElement(Router, {"history": (history)},
    React.createElement(Switch, null,
      React.createElement(Route, {"path": "/"},
        React.createElement(Switch, null,
          React.createElement(LoginRoute, {"exact": true, "path": "/signin"}),
          React.createElement(Route, {"exact": true, "path": "/logout", "render": (Logout)}),
          React.createElement(AuthRoutes, {"path": "/"},
            React.createElement(Switch, null,
              React.createElement(Redirect, {"exact": true, "path": "/", "to": "/languages"}),
              React.createElement(Route, {"exact": true, "path": "/languages", "component": (Languages)}),
              React.createElement(Route, {"exact": true, "path": "/languages/:languageName/archived", "render": ((props) ->
                React.createElement(Drafts, Object.assign({},  props, {"archived": true}))
              )}),
              React.createElement(Route, {"exact": true, "path": "/languages/:languageName", "component": (Drafts)}),
              React.createElement(Route, {"exact": true, "path": "/languages/:languageName/import", "component": (Draft)}),
              React.createElement(Route, {"exact": true, "path": "/languages/:languageName/:draftId", "component": (Draft)}),
              React.createElement(Route, {"exact": true, "path": "/drafts/:draftId", "component": (Draft)}),
              React.createElement(Route, {"exact": true, "path": "/job-hacks", "component": (JobHacks)}),
              React.createElement(Route, {"exact": true, "path": "/job-hacks/new", "component": (JobHack)}),
              React.createElement(Route, {"exact": true, "path": "/job-hacks/:id", "component": (JobHack)}),
              React.createElement(Route, {"exact": true, "path": "/peer-conversations", "component": (PeerConversations)}),
              React.createElement(Route, {"exact": true, "path": "/peer-conversations/:element", "component": (PeerConversations)}),
              React.createElement(Route, {"exact": true, "path": "/peer-conversations/:element/new", "component": (ConversationElement)}),
              React.createElement(Route, {"exact": true, "path": "/peer-conversations/:element/:id", "component": (ConversationElement)}),
              React.createElement(Route, {"exact": true, "path": "/competencies", "component": (Competencies)}),
              React.createElement(Route, {"exact": true, "path": "/competencies/new", "component": (Competency)}),
              React.createElement(Route, {"exact": true, "path": "/competencies/:id", "component": (Competency)}),
              React.createElement(Route, {"component": (NotFound)})
            )
          ),
          React.createElement(Route, {"component": (NotFound)})
        )
      )
    )
  )

import React from 'react'
import {composeComponent} from 'utils/react-tools'
import {withStateHandlers, withProps} from 'recompose'
import {reduxForm, Field} from 'redux-form'
import {Checkbox, TextField, Select} from 'theme/Form'
import Loader from 'theme/Loader'
import {required} from 'validate'

import '../ConversationElements.sass'

fieldRequired = required 'Cannot be empty'

export default composeComponent 'Program.Form',

  withStateHandlers ({initialValues}) ->
    organization: if initialValues then initialValues?.organizationId else ""
    global: initialValues?.isGlobal
  ,
    setValue: -> (organization) ->
      global: false
      organization: organization
    setGlobal: ({global}) -> -> global: not global

  withProps ({initialValues, global}) ->
    initialValues: Object.assign {}, initialValues, {isGlobal: global}

  reduxForm
    form: 'ProgramForm'
    enableReinitialize: true
    keepDirtyOnReinitialize: true

  ({
    options
    handleSubmit
    pristine
    submitting
    submitFailed
    initialValues
    err
    organization
    setValue
    global
    setGlobal
  }) ->
    warning = if initialValues?.id then -> 'Changing this may lead to inapropriate import-export process.'
    React.createElement("form", {"onSubmit": (handleSubmit)},
      React.createElement("div", null,
        React.createElement("h2", null, "Details"),
        React.createElement("p", null,
          React.createElement("span", null, "Edit details and remember to save changes. ")
        )
      ),
      React.createElement(Loader, {"loading": (submitting)}),
      React.createElement(Field, { \
        "name": "label",  \
        "placeholder": "Program identifier",  \
        "label": "Label",  \
        "component": (TextField),  \
        "validate": (fieldRequired),  \
        "warn": (warning)
      }),
      React.createElement(Field, { \
        "name": "description",  \
        "placeholder": "Program description",  \
        "label": "Description",  \
        "component": (TextField),  \
        "validate": (fieldRequired),  \
        "warn": (warning)
      }),
      React.createElement(Field, { \
        "name": "organizationId",  \
        "placeholder": "Organization",  \
        "label": "Organization",  \
        "component": (Select),  \
        "options": (options.organization),  \
        "isClearable": (true),  \
        "setValue": (setValue)
      }),
      React.createElement(Field, { \
        "name": "isGlobal",  \
        "id": "isGlobal",  \
        "label": "Type",  \
        "component": (Checkbox),  \
        "description": "Global",  \
        "normalize": ((v) => !!v),  \
        "value": (global),  \
        "checked": (global),  \
        "disabled": (organization),  \
        "setValue": (setGlobal)
      }),
      
      (if submitFailed and err
        React.createElement("div", {"className": "JobHack__error"}, (err))
      ),

      React.createElement("button", { \
        "type": "submit",  \
        "className": "btn btn_secondary btn_solid_bluePurple JobHack__btn",  \
        "disabled": (pristine || submitting)
      }, "Save")
    )

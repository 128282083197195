import React from 'react'
import {composeComponent} from 'utils/react-tools'
import {Link} from 'react-router-dom'
import {withRouter} from 'react-router'
import {withStateHandlers, lifecycle, withProps, withState} from 'recompose'
import Collapse from 'react-collapse'
import cx from 'classnames'
import Loader from 'theme/Loader'
import Field from './Field.coffee'
import {getProgram, getOrganizations, getProgramsOrganizations, addProgramsOrganizations, deleteProgramsOrganizations} from 'peerConversations/requests'

import 'theme/btn/btn-styles.sass'

export default composeComponent 'ProgramAssignment',
  withRouter
  withState 'collapsed', 'open', true
  withStateHandlers
    organizations: []
    loading: false
    programOrganizations: []
    lastAction: {}
  ,
    setOrganizations: -> (organizations) -> {organizations}
    setProgramOrganizations: -> (programOrganizations) -> {programOrganizations}
    setLastAction: -> (lastAction) -> {lastAction}
    endLoading: -> -> loading: false
    startLoading: -> -> loading: true

  withProps ({organizations}) ->
    options: {
      'organization': organizations.map (organization) -> {label: organization.name, value: organization.id}
    }
  
  withProps ({program, opened, collapsed}) ->
    opened: opened or not collapsed
    programId: program?.id

  lifecycle
    componentDidMount: ->
      {startLoading, endLoading, setProgram, programId, setOrganizations, setProgramOrganizations} = @props
      startLoading()

      a = getOrganizations()
      .then setOrganizations

      b = getProgramsOrganizations(programId).then (programs) -> programs.filter (p) -> p.programId is programId
      b.then setProgramOrganizations

      Promise.all [a, b]
      .finally endLoading

    componentDidUpdate: (prevProps) ->
      if @props.lastAction isnt prevProps.lastAction
        if  @props.lastAction?.action is "select-option"
          addProgramsOrganizations  @props.programId,  @props.lastAction.option.value
        if @props.lastAction?.action is "remove-value"
          deleteProgramsOrganizations @props.programId,  @props.lastAction.removedValue.value

  ({
    program
    options
    setLastAction
    opened
    open
    collapsed
    index
    initialValues
  }) ->
    React.createElement("div", {"className": "Preview__item"},
      React.createElement("div", {"className": (cx "Preview__name", "Preview__cell_name", if opened then "Preview__opened"), "onClick": (-> open !collapsed)},
        React.createElement("h3", null, (program?.label)),
        React.createElement("span", {"className": "count"}, """
          (""", (initialValues[index].length), """)
"""),
        React.createElement("span", {"className": 'btnLink btnLink_secondary'},
          React.createElement("i", {"className": "fal #{if opened then 'fa-chevron-up btn__icon_after' else 'fa-chevron-down btn__icon_after'}"})
        )
      ),

      React.createElement("div", {"className": "Preview__itemContent"},
        React.createElement(Collapse, {"isOpened": (opened)},
          (
            React.createElement(Field, { \
              "index": (index),  \
              "options": (options),  \
              "setLastAction": (setLastAction)
            })
          )
        )
      )
    )

import React from 'react'
import {composeComponent} from 'utils/react-tools'
import {Link} from 'react-router-dom'
import {withRouter} from 'react-router'
import * as _ from 'ramda'
import {withHandlers, withProps} from 'recompose'
import cx from 'classnames'
import {deleteConversationQuestion, getPeerConversation, updateConversationQuestion} from 'peerConversations/requests'
import {EditionButtons} from './EditionButtons.coffee'

import './Preview.sass'

export default composeComponent 'SetDetails.ConversationDetails',
  withRouter
  withProps ({questionDetails}) ->
    questId: (_.head questionDetails)?.id
  withHandlers
    deleteAction: ({conversationId, setPeerConversations, questionDetails}) -> ->
      deleteConversationQuestion conversationId, (_.head questionDetails).id
        .then () -> getPeerConversation()
          .then (tree) ->
            setPeerConversations tree
    upAction: ({conversationId, questId, previousQuest, setPeerConversations}) -> ->
      if _.head previousQuest
        updateConversationQuestion conversationId, Object.assign({}, { firstId: questId, secondId: (_.head previousQuest)?.id})
          .then () -> getPeerConversation()
            .then (tree) ->
              setPeerConversations tree
    downAction: ({conversationId, questId, nextQuest, setPeerConversations}) -> ->
      if _.head nextQuest
        updateConversationQuestion conversationId, Object.assign({}, {firstId: (_.head nextQuest)?.id}, secondId: questId)
          .then () -> getPeerConversation()
            .then (tree) ->
              setPeerConversations tree

  ({
    questionDetails
    enableEdition
    deleteAction
    upAction
    downAction
  }) ->
    question = _.head questionDetails

    React.createElement("div", {"className": "Preview__item"},
      React.createElement("div", {"className": (cx "Preview__row", "Preview__itemContent_row")},
        React.createElement("div", {"className": (cx "cell", "Preview__cell_name" )}, """
          Quest """, (question?.label)
        ),
        React.createElement("div", {"className": (cx "cell", "Preview__cell_description")},
          (question?.description)
        ),
        (if enableEdition
          React.createElement(EditionButtons, { \
            "enableEdition": (enableEdition),  \
            "deleteAction": (deleteAction),  \
            "upAction": (upAction),  \
            "downAction": (downAction)
          })
        )
      )
    )

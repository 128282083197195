import React from 'react'
import {composeComponent} from 'utils/react-tools'
import {reduxForm, Field} from 'redux-form'
import {Select} from 'theme/Form'
import Loader from 'theme/Loader'
import cx from 'classnames'

export default composeComponent 'Preview.Form',

  reduxForm
    form: 'PreviewForm'
  
  ({
    options
    handleSubmit
    onSubmit
    reset
    pristine
    submitting
    submitFailed
    err
    placeholderText
    enableEdition
  }) ->
    submit = (values) ->
      onSubmit values
      .then ->
        reset()

    React.createElement("div", null,
      (if enableEdition
        React.createElement("div", {"className": (cx "Preview__row", "Preview__itemContent_row")},
          React.createElement("form", {"onSubmit": (handleSubmit submit)},
            React.createElement(Loader, {"loading": (submitting)}),
            React.createElement("div", {"className": (cx "cell", "Preview__cell_add" )},
              React.createElement(Field, { \
                "name": "id",  \
                "placeholder": (placeholderText),  \
                "component": (Select),  \
                "options": (options)
              }),
              (if submitFailed and err
                React.createElement("div", {"className": "JobHack__error"}, (err))
              )
            ),
            React.createElement("div", {"className": (cx "cell", "Preview__cell_addButton")},
              React.createElement("button", { \
                "type": "submit",  \
                "className": "btn btn_secondary btn_solid_bluePurple",  \
                "disabled": (pristine || submitting)
              }, "Add")
            )
          )
        )
      )
    )

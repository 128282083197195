import React from 'react'
import {composeComponent} from 'utils/react-tools'
import {Link} from 'react-router-dom'
import {withRouter} from 'react-router'
import * as _ from 'ramda'
import RSelect from 'react-select'
import {withHandlers, withStateHandlers, lifecycle, withProps, withState, mapProps} from 'recompose'
import moment from 'moment'
import InView from 'react-inview-monitor'
import Heading from 'theme/Heading'
import Loader from 'theme/Loader'
import DeleteModal from 'theme/DeleteModal'
import { LinksSection } from 'theme/Heading/HeadingElements'
import {getActiveLanguage, getPrograms, deleteProgram, getOrganizations} from 'peerConversations/requests'

import './PeerConversation.sass'

VISIBLE_ROWS = 20

HEADINGS = [
    name: 'Label'
    sortFunc: _.prop 'label'
  ,
    name: 'Global'
    sortFunc: _.prop 'isGlobal'
  ,
    name: 'Description'
    sortFunc: _.prop 'description'
  ,
    name: 'Organization'
    sortFunc: _.prop 'organization'
  ,
    name: 'Last Modified'
    sortFunc: _.prop 'modifyDate'
  ,
    name: 'Archived'
    sortFunc: _.prop 'archiveDate'
]

export default composeComponent 'Programs',
  withRouter
  withState 'language', 'setLanguage', null
  withStateHandlers
    loading: false
    programs: []
    organizations: []
    filters:[]
    sortBy: HEADINGS[0]
    sortDir: 'asc'
    page: 1
    exporting: false
    modal:
      visible: false
      data: null
  ,
    setPrograms: -> (programs) -> {programs}
    setOrganizations: -> (organizations) -> {organizations}
    endLoading: -> -> loading: false
    startLoading: -> -> loading: true
    setSortBy: ({sortBy, sortDir}) -> (column) ->
      if column.name isnt sortBy.name
        sortBy: column
        sortDir: 'asc'
      else switch sortDir
        when 'asc' then sortDir: 'desc'
        when 'desc' then sortDir: 'asc'
        else sortDir: 'asc'
    showMore: ({page}) -> -> page: page + 1
    showModal: -> (data) -> modal: {visible: true, data}
    closeModal: ({modal}) -> -> modal: _.merge modal, visible: false
    changeFilter: -> (filters) -> filters: filters

  lifecycle
    componentDidMount: ->
      {startLoading, endLoading, setPrograms, setLanguage, setOrganizations} = @props
      startLoading()

      a = getPrograms()
      .then setPrograms

      b = getActiveLanguage()
      .then setLanguage

      c = getOrganizations()
      .then setOrganizations

      Promise.all [a, b, c]
      .finally endLoading

  mapProps (props) ->
    reverse = if props.sortDir is 'desc' then _.reverse else _.identity
    filters = _.pluck 'value', props.filters
    filterFunc = (p) ->
      unless filters.length
        return true
      propsToFilter = _.flatten [p.isGlobal, p.organizationId]
      _.any(_.contains _.__, filters) propsToFilter
    _.evolve(
      programs: _.compose _.filter(filterFunc), reverse, _.sortBy(props.sortBy?.sortFunc)
    ) props

  withProps ({organizations}) ->
    options: [
       {
        'label': 'Global'
        'options': [{label: 'Yes', value: true}, {label: 'No', value: false}]
      }
      {
        'label': 'Organizations'
        'options': organizations.map (organization) -> {label: organization.name, value: organization.id}
      }
    ]

  withHandlers
    deleteProgram: ({setPrograms, startLoading, endLoading, programs, closeModal}) -> (program) -> (e) ->
      e.stopPropagation()
      startLoading()
      deleteProgram program.id
      .then ->
        getPrograms()
          .then setPrograms
        closeModal()
      .finally endLoading

  ({
    loading
    name
    subtitle
    programs
    organizations
    filters
    options
    page
    setSortBy
    sortBy
    sortDir
    history
    deleteProgram
    modal
    showModal
    closeModal
    changeFilter
    showMore
    }) ->

    count = if programs.length then page * VISIBLE_ROWS else programs.length
    maxCount = if count > programs.length then programs.length else count
    links = [
      {text:"Edit #{name} content", link: "/languages/English/#{language?.id}?key=peerconversation.program"},
      {text:"Add new program", link: "/peer-conversations/programs/new"}
    ]
    organizationName = (id) -> organization.name for organization in organizations when id is organization.id

    React.createElement("div", {"className": "PeerConversation container"},
      React.createElement(Loader, {"loading": ((loading))}),
      React.createElement(Heading, {  \
        "name": (name),  \
        "subtitle": (subtitle),  \
        "linksSection": (
          React.createElement(LinksSection, { \
            "links": (links)
          })
        )
      }),

      React.createElement("div", {"className": "PeerConversation__filters"},
        React.createElement(RSelect, { \
          "className": "PeerConversation__select",  \
          "classNamePrefix": "PeerConversation__select",  \
          "options": (options),  \
          "isMulti": true,  \
          "value": (filters),  \
          "onChange": (changeFilter),  \
          "placeholder": "Filter"
        })
      ),

      React.createElement("div", {"className": "PeerConversation__table"},
          React.createElement("table", null,
            React.createElement("thead", null,
              React.createElement("tr", null,
                (HEADINGS.map (h, i) ->
                  React.createElement("th", {"onClick": (-> setSortBy h), "key": (i)},
                    (h.name),
                    (if h.name is sortBy.name
                      React.createElement("i", {"className": "far #{if sortDir is 'asc' then 'fa-chevron-down' else 'fa-chevron-up'}"})
                    )
                  )
                ),
                React.createElement("th", null)
              )
            ),
            React.createElement("tbody", null,
              ([0...maxCount].map (i) ->
                program = programs[i]
                React.createElement("tr", {"key": (i), "onClick": (-> history.push "/peer-conversations/programs/#{program.id}")},
                  React.createElement("td", null,
                    (program.label)
                  ),
                  React.createElement("td", null,
                    (React.createElement("i", {"className": "fas #{if program.isGlobal then 'fa-check' else 'fa-times'}"}))
                  ),
                  React.createElement("td", null,
                    (program.description)
                  ),
                  React.createElement("td", null,
                    (organizationName program.organizationId)
                  ),
                  React.createElement("td", null,
                    (moment(program.modifyDate).format 'MMM DD, YYYY')
                  ),
                  React.createElement("td", null,
                    (if program.archiveDate
                      React.createElement("span", {"className": "far fa-check"})
                    )
                  ),
                  React.createElement("td", null,
                    React.createElement("button", { \
                      "className": "btnLink",  \
                      "onClick": ((e) ->
                        e.stopPropagation()
                        showModal program
                      )
                    },
                      (if !program.archiveDate
                        React.createElement("span", {"className": "fas fa-trash"})
                      )
                    ),
                    React.createElement(Link, { \
                      "className": "btn btn_compact btn_solid_bluePurple",  \
                      "to": "/languages/English/#{language?.id}?key=peerconversation.program.#{program.label.toLowerCase()}",  \
                      "onClick": ((e) -> e.stopPropagation())
                    }, """
                      Edit content
""")
                  )
                )
              ),
              (if maxCount < programs.length
                React.createElement("tr", null,
                  React.createElement("td", {"colSpan": "6"},
                    React.createElement(InView, { \
                      "onInView": (showMore),  \
                      "intoViewMargin": "0%",  \
                      "repeatOnInView": true
                    }, "Loading more...")
                  )
                )
              )
            )
          )
        ),

      React.createElement(DeleteModal, { \
        "modal": (modal),  \
        "closeModal": (closeModal),  \
        "loading": (loading),  \
        "removedItemName": "program",  \
        "deleteFunc": (deleteProgram)
      })

    )

import axios from 'axios';
import qs from 'qs';
import moment from 'moment';
import jwt_decode from "jwt-decode";
import history from './history';

const tokenStoreKey = 'auth__token';
const refreshTokenStoreKey = 'auth__refresh_token';


let refreshingTokenPromise = null;
let createRefreshTokenPromise = null;

const config = window.config;

const decodejwt = (jwt) => {
  try {
    return jwt && jwt_decode(jwt);
  } catch (err) {
    return null;
  }
}

export const getAuth = () => {
  try {
    const auth = localStorage.getItem(tokenStoreKey);
    if (!auth) return null;
    return JSON.parse(auth);
  } catch (err) {
    console.error(err);
    return null;
  }
}

export const getUser = () => {
  const auth = getAuth();
  return auth && auth.user;
}

// export const saveAuth = (auth) => {
//   try {
//     return localStorage.setItem(tokenStoreKey, JSON.stringify(auth || null));
//   } catch (err) {
//     console.error(err);
//     return null;
//   }
// }

export const saveAuth = (auth) => {
  const {valid, user, jwt} = auth;
  const decoded = decodejwt(jwt);
  const u = {...user, ...({
    ...decoded,
    roles: [...(user.roles || []), ...((decoded && decoded.roles) || [])]
  })};
  const exp = (decoded && decoded.exp) || valid;
  const token = !!decoded && {exp, user: u, jwt};
  localStorage.setItem(tokenStoreKey, JSON.stringify(token || null));
  return token;
}

export const saveRefreshToken = (token) => {
  try {
    localStorage.setItem(refreshTokenStoreKey, JSON.stringify(token));
    return token;
  } catch (err) {
    console.error(err);
    return null;
  }
}

export const getRefreshToken = () => {
  try {
    const token = localStorage.getItem(refreshTokenStoreKey);
    return !!token && JSON.parse(token);
  } catch (err) {
    console.error(err);
    return null;
  }
}

export const authorizationHeader = async (auth) => {
  if(!auth) return undefined;
  else if(auth.jwt) {
    try {
      const token = await getJWTToken(auth.jwt);
      return {'Authorization': `Bearer ${token}`};
    } catch (err) {
      throw "JWT Error";
    }
  };
}

const JWTValid = (jwt) => {
  const decoded = jwt_decode(jwt);
  return decoded && moment().isBefore(moment.unix(decoded.exp));
}


const getJWTToken = (jwt) => {
  let refreshToken = getRefreshToken();
  if(!jwt) return Promise.reject('No JWT token provided');

  return (refreshToken ? Promise.resolve(refreshToken) : createRefreshToken(jwt))
  .then((refreshToken) => {
    if(JWTValid(jwt)) {
      return Promise.resolve(jwt);
    }
    return refreshJWT(refreshToken);
  });
}

const refreshJWT = (refreshToken) => {
  if(refreshingTokenPromise) {
    return refreshingTokenPromise;
  }

  const request = api({
    method: 'post',
    url: "/session/refresh-tokens",
    data: {refreshToken}
  })
  .then(jwt => {
    const auth = getAuth();
    saveAuth({...auth, jwt})
    refreshingTokenPromise = null;
    return jwt;
  });

  refreshingTokenPromise = Promise.resolve(request);
  return request;
} 

export const createRefreshToken = (token) => {
  if(createRefreshTokenPromise) {
    return createRefreshTokenPromise;
  }
  
  const deviceId = navigator.userAgent.replace(/(\/|\s)/g, "");

  createRefreshTokenPromise = api({
    method: 'post',
    url: `/session/devices/${deviceId}/refresh-tokens`,
    headers: {'Authorization': `Bearer ${token}`}
  }).then(({value}) => {
    createRefreshTokenPromise = null;
    return saveRefreshToken(value)
  }, removeStorage);

  return createRefreshTokenPromise;
}

export const isLoggedIn = () => {
  const auth = getAuth();
  return !!auth && !!auth.jwt;
}

const removeStorage = () => {
  localStorage.removeItem(tokenStoreKey);
  localStorage.removeItem(refreshTokenStoreKey);
}

export const logout = () => {
  const auth = getAuth();
  const decoded = decodejwt(auth.jwt);
  if(decoded && moment().isBefore(moment.unix(decoded.exp))) {
    return api.delete("/session")
    .then(removeStorage, removeStorage)
  }
  else {
    removeStorage();
    return Promise.resolve('Removed storage, token expired');
  }
}

const api = axios.create( {
  baseURL: config.api.base,
  paramsSerializer: (params) => qs.stringify(params, {arrayFormat: 'repeat'})
})

api.interceptors.request.use((config) => {
  return new Promise((resolve, reject) => {
    const isIE = false || !!document.documentMode;
    const withTimestamp =
      (config && config.method == 'get' && isIE)
      ? Object.assign({}, config, {params: Object.assign({}, config.params, {_timestamp: moment().format()})})
      : config;

    const auth = getAuth();
    if (config.withCredentials !== false && isLoggedIn() && config.url.indexOf('/refresh-tokens') == -1) {
      authorizationHeader(auth)
      .then(authHeader => {
        const headers = Object.assign({}, config.headers, authHeader);
        resolve(Object.assign({}, withTimestamp, {headers}));
      }, () => {
        reject(Object.assign({}, withTimestamp));
        history.push('/logout');
      });
    } else resolve(withTimestamp);
  })
})

api.interceptors.response.use(
  (response) => response.data,
  (error) => {
    if(error.response && error.response.status === 401 && history.location.pathname !== '/signin') {
      history.push('/logout');
    }
    Promise.reject(error.response || error);
  }
)

export default api;
import React, {Component, Children} from 'react'
import * as _ from 'ramda'
import {
  branch
  compose
  getContext as getC
  mapProps
  nest
  renderComponent
  setDisplayName
  setPropTypes as setPT
  withContext as withC
  withProps
  wrapDisplayName
} from 'recompose'
import cx from 'classnames'
import {propTypes as PT, ReactNode} from 'tcomb-react'
import T from 'tcomb'


compute = (selector, f) ->
  if _.is Function, f
    (p) -> f selector(p), p
  else -> f

export withBranch = ({component: Component, selector, message, props}) ->
  children = compute selector, message
  getProps = compute selector, props

  branch selector, renderComponent (p) ->
    React.createElement(Component, Object.assign({},  getProps(p)),
      (children(p))
    )

export withContainer = _.curryN 2, nest

export flattenApolloData = (fields...) ->
  mapProps (props) ->
    dataProps = _.pick ['error', 'loading', fields...], props.data
    restProps = _.omit ['data'], props
    _.merge restProps, dataProps

export omitRec = _.curry (fields, obj) ->
  return obj unless obj? and typeof obj is 'object'
  _.pipe(
    _.unless _.is(Array), _.omit fields
    _.map omitRec fields
  ) obj

export getInitialValues = (props) ->
  _.pipe _.when(_.always(props), _.pick props), omitRec ['__typename']

export setPropTypes = (types, opts = strict: false) ->
  setPT PT types, opts

export defaultMessage = (message, defaultMessage) ->
  # coffeelint: disable=prefer_fewer_parens
  _.pipe(
    _.unless(_.is(Function), _.always) message
    _.when _.isNil, defaultMessage
  )
  # coffeelint: enable=prefer_fewer_parens

export withContext = (types, get, opts = strict: false) ->
  withC PT(types, opts), get

export dropNils = _.reject _.isNil

export getContext = (types, opts = strict: false) -> getC PT types, opts

export composeHoc = (name, decorators...) ->
  decorate = compose dropNils(decorators)...
  (C) -> setDisplayName(wrapDisplayName C, name) decorate C

export composeComponent = (name, decorators..., component) ->
  decorators = [
    setDisplayName name
    decorators...
    if decorators.length and typeof component isnt 'string'
      setDisplayName name + ':inner'
  ]
  compose(dropNils(decorators)...) component

export withClassNames = (cls) ->
  getCls = _.unless(_.is(Function), _.always) cls
  withProps (props) ->
    className: cx getCls(props), props.className

export FirstChild = composeComponent 'FirstChild',
  ({children}) -> Children.toArray(children)[0] ? null

export OnlyChild = composeComponent 'OnlyChild',
  ({children}) -> Children.only children

ReactClassComponent = T.irreducible 'ReactClassComponent', (x) ->
  x.prototype instanceof Component or x is Component

export ReactComponent = T.union [
  T.String
  ReactClassComponent
  T.Function
], 'ReactComponent'

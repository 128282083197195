import React from 'react'
import {composeComponent} from 'utils/react-tools'
import {reduxForm, Field} from 'redux-form'
import {withStateHandlers} from 'recompose'
import {TextField, Select} from 'theme/Form'
import Loader from 'theme/Loader'
import {required} from 'validate'

import '../ConversationElements.sass'

fieldRequired = required 'Cannot be empty'

export default composeComponent 'Question.Form',

  withStateHandlers
    questionTypetValue: ""
    answerTypetValue: ""
  ,
    setQuestionValue: -> (questionTypetValue) -> {questionTypetValue}
    setAnswerValue: -> (answerTypetValue) -> {answerTypetValue}

  reduxForm
    form: 'QuestionForm'
    enableReinitialize: true
    keepDirtyOnReinitialize: true

  ({
    options
    handleSubmit
    pristine
    submitting
    submitFailed
    initialValues
    err
    values
    setQuestionValue
    setAnswerValue
    questionTypetValue
    answerTypetValue
  }) ->
    warning = if initialValues?.id then -> 'Changing this may lead to inapropriate import-export process.'
    React.createElement("form", {"onSubmit": (handleSubmit)},
      React.createElement("div", null,
        React.createElement("h2", null, "Details"),
        React.createElement("p", null,
          React.createElement("span", null, "Edit details and remember to save changes. ")
        )
      ),
      React.createElement(Loader, {"loading": (submitting)}),
      React.createElement(Field, { \
        "name": "label",  \
        "placeholder": "Question identifier",  \
        "label": "Label",  \
        "component": (TextField),  \
        "validate": (fieldRequired),  \
        "warn": (warning)
      }),
      React.createElement(Field, { \
        "name": "description",  \
        "placeholder": "Question description",  \
        "label": "Description",  \
        "component": (TextField),  \
        "validate": (fieldRequired),  \
        "warn": (warning)
      }),
      React.createElement(Field, { \
        "name": "organizationId",  \
        "placeholder": "Organization",  \
        "label": "Organization",  \
        "component": (Select),  \
        "options": (options.organization)
      }),
      React.createElement(Field, { \
        "name": "questionType",  \
        "placeholder": "Pick question type",  \
        "label": "Question Type",  \
        "component": (Select),  \
        "validate": (fieldRequired),  \
        "options": (options.questionType),  \
        "setValue": (setQuestionValue)
      }),
      (if questionTypetValue is 'Multi' or initialValues?.questionType is 'Multi'
        React.createElement(Field, { \
          "name": "optionsCount",  \
          "placeholder": "Question options number",  \
          "label": "Options Number",  \
          "component": (TextField),  \
          "warn": (warning)
        })
      ),
      React.createElement(Field, { \
        "name": "answerType",  \
        "placeholder": "Pick answer type",  \
        "label": "Answer Type",  \
        "component": (Select),  \
        "validate": (fieldRequired),  \
        "options": (options.answerType),  \
        "setValue": (setAnswerValue)
      }),
      (if answerTypetValue is 'Prework' or initialValues?.answerType is 'Prework'
        React.createElement(Field, { \
          "name": "jobhackLabel",  \
          "placeholder": "Select job hack",  \
          "label": "Job hack label",  \
          "component": (Select),  \
          "options": (options.jobHack),  \
          "warn": (warning),  \
          "isClearable": (true)
        })
      ),
      
      (if submitFailed and err
        React.createElement("div", {"className": "JobHack__error"}, (err))
      ),

      React.createElement("button", { \
        "type": "submit",  \
        "className": "btn btn_secondary btn_solid_bluePurple JobHack__btn",  \
        "disabled": (pristine || submitting)
      }, "Save")
    )

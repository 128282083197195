import React from 'react'
import * as _ from 'ramda'
import {parse} from 'qs'
import {composeComponent} from 'utils/react-tools'
import {withState, withHandlers, withStateHandlers, withProps, lifecycle} from 'recompose'
import {withRouter} from 'react-router'
import {Link, Prompt} from 'react-router-dom'
import {reduxForm, Field, FieldArray} from 'redux-form'
import cx from 'classnames'
import InView from 'react-inview-monitor'
import {required} from 'validate'
import TableField from 'theme/TranslationView/TableField'

VISIBLE_FIELDS = 10
fieldRequired = required 'Cannot be empty'

ArrayFields = composeComponent 'Draft.ArrayFields',
  withState 'count', 'setCount', VISIBLE_FIELDS
  withHandlers
    loadMore: ({setCount, count, fields}) -> ->
      setCount count + VISIBLE_FIELDS
    findFieldIndex: ({fields}) -> (key) ->
      fields.getAll().findIndex (f) -> f.key is key
  withProps ({fields, query, category, errored, errs, valueQuery}) ->
    replaceFunc = _.replace /\//g, '.'
    errors = _.pluck 'key', errs ? []
    values: _.pipe(
      _.filter (f) -> if not errored or not errors.length then return true else f.label in errors
      _.filter (f) -> not category or f.key.toLowerCase().indexOf(category.toLowerCase()) is 0
      _.filter (f) -> not query or replaceFunc(f.key.toLowerCase()).indexOf(query) isnt -1
      _.filter (f) -> not valueQuery or (f.value and f.value.toLowerCase().indexOf(valueQuery) isnt -1)
    ) fields.getAll() ? []

  lifecycle
    componentDidUpdate: (prevProps) ->
      if @props.category isnt prevProps.category
        @props.setCount VISIBLE_FIELDS
      if not @props.draftId? and not prevProps.fileInput? and @props.fileInput isnt prevProps.fileInput
        @props.fileInput.click()

  ({fields, disabled, loadMore, count, values, findFieldIndex, errs, category}) ->
    maxCount = if count > values.length then values.length else count

    React.createElement("tbody", null,
      (if values.length then [0...maxCount].map (i) ->
        value = values[i]
        error = errs?.find (e) -> e.key is value.label
        field = "#{fields.name}[#{findFieldIndex value.key}].value"
        React.createElement(Field, { \
          "key": (field),  \
          "name": (field),  \
          "placeholder": "Enter value",  \
          "label": (value.label),  \
          "isNew": (value.new),  \
          "component": (TableField),  \
          "textarea": true,  \
          "disabled": (disabled),  \
          "validate": (fieldRequired),  \
          "tooltip": (value.description),  \
          "err": (error?.value)
        })
      ),
      (if maxCount < values.length
        React.createElement("tr", null,
          React.createElement("td", {"colSpan": "2"},
            React.createElement(InView, { \
              "onInView": (loadMore),  \
              "intoViewMargin": "0%",  \
              "repeatOnInView": true
            }, "Loading more...")
          )
        )
      )
    )

export default composeComponent 'Draft.Form',
  withRouter
  reduxForm
    form: 'DraftForm'
    enableReinitialize: true

  withHandlers
    setSearchParams: ({history, match}) -> ({key, value}) ->
      history.push {pathname: match.url, search: "?key=#{key}&value=#{value}"}

  withStateHandlers ({location}) ->
    {key, value} = parse location.search, ignoreQueryPrefix: true
    category: null
    query: key ? ''
    valueQuery: value ? ''
    errored: false
  ,
    setCategory: ({category}) -> (newCategory) ->
      category: if category is newCategory then null else newCategory
    clearCategory: -> -> category: null
    setQuery: ({valueQuery}, {setSearchParams}) -> (e) ->
      query = e?.target?.value?.toLowerCase() ? ''
      setSearchParams key: query, value: valueQuery
      {query}
    setValueQuery: ({query}, {setSearchParams}) -> (e) ->
      valueQuery = e?.target?.value?.toLowerCase() ? ''
      setSearchParams key: query, value: valueQuery
      {valueQuery}
    showErrored: -> ->
      category: null
      query: ''
      valueQuery: ''
      errored: true
    closeErrored: -> -> errored: false

  withProps ({signatures}) ->
    categories: _.uniq signatures.map (s) -> s.key.match(/^([^\/])*/)?[0]

  lifecycle
    componentDidUpdate: (prevProps) ->
      if not @props.draftId? and @props.fileInput isnt prevProps.fileInput
        @props.fileInput.click()

  ({user, categories, draftId, handleSubmit, onSubmit, languageName, invalid, submitFailed, submitting,
  error, disabled, pristine, setCategory, setQuery, category, query, showErrored, closeErrored, errored,
  err, exportDraft, importStatus, fileInput, setValueQuery, valueQuery, signatures}) ->
    newSignatures = signatures.filter ({new: isNew}) -> isNew

    React.createElement("div", null,
      React.createElement("div", {"className": "Draft__heading"},
        React.createElement("div", null,
          React.createElement("h1", null,
            React.createElement(Link, {"to": "/languages/#{languageName}"},
              React.createElement("span", {"className": "fas fa-chevron-left"}),
              (languageName)
            )
          )
        ),
        (unless disabled
          React.createElement("button", { \
            "type": "button",  \
            "onClick": (handleSubmit onSubmit),  \
            "className": "btn btn_secondary btn_solid_bluePurple",  \
            "disabled": (not importStatus? and pristine or submitting or invalid and submitFailed)
          }, "Save")
        ),
        React.createElement("button", { \
          "type": "button",  \
          "onClick": (-> fileInput.click()),  \
          "className": "btn btn_secondary btn_outlined_bluePurple",  \
          "disabled": (submitting)
        }, "Import"),
        React.createElement("button", { \
          "type": "button",  \
          "onClick": (exportDraft),  \
          "className": "btn btn_secondary btn_outlined_bluePurple",  \
          "disabled": (submitting)
        }, "Export")
      ),
      (if submitFailed and err
        React.createElement("div", {"className": "Draft__errors"}, """
          Saving failed because of following problems:
""", (if err.accum?.length
            React.createElement("ul", null,
              (err.accum?.map (e) ->
                React.createElement("li", {"key": (e)}, (e))
              )
            )
          else if err.errors?.length
            React.createElement("p", null, "Validation failed")
          else
            React.createElement("p", null, (err))
          ),
          (if err.errors.length and not errored
            React.createElement("button", {"type": "button", "onClick": (showErrored), "className": "btn btn_compact"}, "Show Invalid Fields")
          else if errored
            React.createElement("button", {"type": "button", "onClick": (closeErrored), "className": "btn btn_compact"}, "Show All Fields")
          )
        )
      ),
      (if importStatus?
        React.createElement("div", {"className": (cx "Draft__import", if importStatus.type is 'error' then 'Draft__errors' else 'Draft__success')},
          (if importStatus.type is 'success'
            React.createElement("div", null,
              React.createElement("span", null, "Import Successful"),
              React.createElement("p", null, "You can edit imported data and save")
            )
          else
            React.createElement("div", null,
              (importStatus.message),
              React.createElement("p", null, (importStatus.error))
            )
          )
        )
      ),
      (if newSignatures.length and draftId
        React.createElement("div", {"className": "Draft__warning"},
          React.createElement("span", null, "You are adding new keys to the existing draft (", (newSignatures.length), ")"),
          React.createElement("p", null, """
            After saving new draft will be created.
""")
        )
      ),
      (if newSignatures.length and not draftId
        React.createElement("div", {"className": "Draft__warning"},
          React.createElement("span", null, "New keys added (", (newSignatures.length), ")"),
          React.createElement("p", null, """
            After saving these keys will be required in all translations.
""")
        )
      ),
      React.createElement("form", {"onSubmit": (handleSubmit unless disabled)},
        React.createElement("div", {"className": "Draft__section"},
          React.createElement("h2", null, "Content"),
          React.createElement("p", null,
            (unless disabled
              React.createElement("span", null, "Click on item to edit. ")
            ),
            React.createElement("span", null, "Hover to show description of the field.")
          ),
          React.createElement("div", {"className": "Draft__filters"},
            React.createElement("div", {"className": "Draft__filter"},
              React.createElement("input", {"placeholder": "Filter keys", "onChange": (setQuery), "value": (query)}),
              (if query
                React.createElement("span", {"className": "fal fa-times", "onClick": (-> setQuery())})
              )
            ),
            React.createElement("div", {"className": "Draft__filter"},
              React.createElement("input", {"placeholder": "Filter values", "onChange": (setValueQuery), "value": (valueQuery)}),
              (if valueQuery
                React.createElement("span", {"className": "fal fa-times", "onClick": (-> setValueQuery())})
              )
            )
          ),
          React.createElement("div", {"className": "Draft__categories"},
            (categories.map (c, i) ->
              isActive = category is c
              React.createElement("div", { \
                "className": (cx "Draft__category color#{i %% 5}",
                  active: isActive
                  transparent: category?
                ),  \
                "key": (i),  \
                "onClick": (-> setCategory c)
              },
                (c),
                (if isActive
                  React.createElement("span", {"className": "fal fa-times"})
                )
              )
            )
          ),
          React.createElement("div", {"className": "Draft__table"},
            React.createElement("table", null,
              React.createElement("thead", null,
                React.createElement("tr", null,
                  React.createElement("th", null, """
                    Key
"""),
                  React.createElement("th", null, """
                    Value
""")
                )
              ),
              React.createElement(FieldArray, { \
                "name": "values",  \
                "component": (ArrayFields),  \
                "disabled": (submitting or disabled),  \
                "query": (query),  \
                "valueQuery": (valueQuery),  \
                "category": (category),  \
                "errored": (errored),  \
                "errs": (err?.errors)
              })
            )
          )
        )
      ),

      React.createElement(Prompt, { \
        "when": (not pristine),  \
        "message": "Are you sure you want to leave this page? All unsaved changes will be lost!"
      })
    )

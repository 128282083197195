import React from 'react'
import {composeComponent} from 'utils/react-tools'
import {withRouter} from 'react-router-dom'
import * as _ from 'ramda'
import {withHandlers, withStateHandlers, lifecycle, withProps} from 'recompose'
import {SubmissionError} from 'redux-form'
import moment from 'moment'
import cx from 'classnames'
import Header from 'theme/Header'
import Loader from 'theme/Loader'
import Modal from 'theme/Modal'
import Footer from 'theme/Footer'
import {getUser} from 'api'
import {getDraft, getSignatures, updateLanguage as updateRequest, createDraft} from './requests'
import validationErrors from 'theme/TranslationView/validationErrors'
import Form from './Form'

import './Draft.sass'

export default composeComponent 'Draft',
  withRouter

  withProps ({match: {params}}) ->
    draftId: params?.draftId
    languageName: params?.languageName

  withStateHandlers
    loading: false
    draft: {}
    signatures: []
    modalOpened: false
    values: {}
    errors: null
    fileInput: null
    importStatus: null
  ,
    setDraft: -> (draft) -> {draft}
    setSignatures: -> (signatures) -> {signatures}
    endLoading: -> -> loading: false
    startLoading: -> -> loading: true
    openModal: -> -> modalOpened: true
    closeModal: -> -> modalOpened: false
    setFormValues: -> (values) -> {values}
    setErrors: -> (errors) -> {errors}
    setFileInput: -> (fileInput) -> {fileInput}
    setImportStatus: -> (importStatus) -> {importStatus}

  withProps ({signatures}) ->
    newSignatures: signatures.filter (s) -> s.new

  lifecycle
    componentDidMount: ->
      {startLoading, endLoading, setDraft, draftId, setSignatures} = @props
      startLoading()
      d = if not draftId
        Promise.resolve()
      else
        getDraft draftId
        .then (draft) ->
          setDraft draft
      s = getSignatures()
      .then (signatures) ->
        setSignatures signatures

      Promise.all [d, s]
      .finally endLoading

  withHandlers
    updateLanguage: ({draftId, history, languageName, closeModal, setErrors, setImportStatus, newSignatures}) => (values) =>
      request = if draftId and not newSignatures.length
          updateRequest draftId, values
        else
          createDraft languageName, values, keysAdded: newSignatures.length > 0
      request
      .then(
        ->
          setImportStatus null
          setTimeout(
            -> history.push "/languages/#{languageName}"
            1500
          )
        (error) ->
          setTimeout closeModal(), 1500
          if error.message is 'ValidationFailed'
            fieldErrors = values.values.map (v) ->
              foundError = error.errors.find (e) -> e.key is v.label
              value: if foundError then validationErrors[foundError.errorCode] ? 'Unknown'
              key: v.label

            generalErrors = error.errors
            .filter (e) -> not e.key?
            .map (e) -> validationErrors[e.errorCode]

            errors =
              accum: generalErrors
              errors: fieldErrors.filter (f) -> f.value

            setErrors errors
            throw new SubmissionError values: fieldErrors, _error: errors
          else
            e = _error: 'Something went wrong. Please try again later'
            setErrors e
            throw new SubmissionError e
          return error
      )
    exportDraft: ({draft}) -> ->
      a = document.createElement 'a'
      values = draft.translation.values.map (v) ->
        key: v.label
        value: v.value
      data = JSON.stringify values, null, 4
      file = new Blob [data], type: 'text/plain'
      a.href = URL.createObjectURL file
      a.download = "ImperativeTranslation_#{draft.header.language}_#{draft.header.version}.txt"
      a.click()

    importDraft: ({setDraft, fileInput, draft, setErrors, setImportStatus, signatures, setSignatures}) -> (e) ->
      file = fileInput.files[0]
      if file.type.match /text.*/
        reader = new FileReader()

        reader.onload = =>
          try
            result = JSON.parse reader.result ? '[]'
            values = result.map (v) -> _.compose(
              _.identity
              _.assoc 'label', v.key
              _.assoc 'key', _.replace /\./g, '/', v.key
            ) v
            setDraft Object.assign {}, draft, translation: {values}

            signaturesKeys = _.pluck 'label', signatures
            newSignatures = result
              .filter (trans) -> trans.key not in signaturesKeys
              .map (v) -> key: _.replace(/\./g, '/', v.key), label: v.key, new: true

            setSignatures [].concat newSignatures, signatures

            setImportStatus type: 'success'
          catch err
            if err instanceof SyntaxError
              e = 'File is corrupted.'
            else e = 'Unknown error'
            setImportStatus type: 'error', message: e, error: err.message

        reader.readAsText file

  withHandlers
    submit: ({updateLanguage, draft, openModal}) -> (values) ->
      if draft?.header?.state in ['Activated', 'Published']
        openModal()
      updateLanguage values

  ({draft, loading, draftId, signatures, match, submit, modalOpened, closeModal, errors, exportDraft, setFileInput, importDraft, importStatus, fileInput}) ->
    user = getUser()
    {languageName} = match.params
    archived = draft.header?.archiveDate?

    initialValues = signatures.map (s) ->
      foundTranslation = draft?.translation?.values.find (v) -> v.key is s.key
      Object.assign {}, s, value: foundTranslation?.value

    React.createElement("div", {"className": "Draft page"},
      React.createElement(Header, {"user": (user)}),
      React.createElement("div", {"className": "container"},
        React.createElement(Loader, {"loading": (loading)}),

        React.createElement("input", {"type": "file", "ref": (setFileInput), "onChange": (importDraft)}),

        React.createElement(Form, { \
          "onSubmit": (submit),  \
          "signatures": (initialValues),  \
          "initialValues": (values: initialValues),  \
          "draftId": (draftId),  \
          "languageName": (languageName),  \
          "disabled": (archived),  \
          "err": (errors),  \
          "exportDraft": (exportDraft),  \
          "importStatus": (importStatus),  \
          "importDraft": (importDraft),  \
          "fileInput": (fileInput)
        })

      ),
      React.createElement(Footer, null),

      React.createElement(Modal, { \
        "isOpen": (modalOpened),  \
        "close": (closeModal),  \
        "className": "Draft__modal",  \
        "withClose": (false)
      },
        React.createElement("h2", {"className": "Draft__subtitle"}, "Version is already ", React.createElement("strong", {"className": (draft?.header?.state)}, (draft?.header?.state))),
        React.createElement("span", null, "Creating new version of draft...")
      )
    )
import React from 'react'
import {composeComponent} from 'utils/react-tools'
import Modal from 'theme/Modal'
import Loader from 'theme/Loader'
import './DeleteModal.sass'

export default composeComponent 'DeleteModal',

  ({modal, closeModal, loading, removedItemName, deleteFunc}) ->

    React.createElement(Modal, { \
      "isOpen": (modal?.visible),  \
      "close": (closeModal),  \
      "className": "DeleteModal__modal"
    },
      React.createElement(Loader, {"loading": (loading and modal?.visible)}),
      React.createElement("div", null,
        React.createElement("p", null, "Are you sure you want to delete ", React.createElement("strong", null, (modal?.data?.label)), (" " + removedItemName), "?"),
        React.createElement("div", {"className": "DeleteModal__modalBtns"},
          React.createElement("button", { \
            "className": "btn btn_secondary btn_solid_bluePurple",  \
            "onClick": (deleteFunc modal?.data)
          }, """
            Delete
"""),
          React.createElement("button", { \
            "className": "btn btn_secondary",  \
            "onClick": (closeModal)
          }, "Cancel")
        )
      )
    )

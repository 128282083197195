`/** @jsx R.DOM */`

import R from 'react'
import cx from 'classnames'
import _ from 'ramda'
import {composeComponent} from 'utils/react-tools'

import './CheckBox.sass'


export default composeComponent 'CheckBox',

  ({className, disabled, onChange, value, programLabel}) ->
    R.createElement("div", null,
      R.createElement("span", { \
        "className": (cx "CheckBox far fa#{if value then '-check' else ''}-square", {disabled}, className),  \
        "onClick": (-> onChange programLabel)
      })
    )


import React from 'react'
import {composeComponent} from 'utils/react-tools'
import {compose, withHandlers} from 'recompose'
import {reduxForm, Field} from 'redux-form'
import cx from 'classnames'
import Loader from 'theme/Loader'
import {TextField, Select} from 'theme/Form'
import {required, email} from 'validate'
import Modal from 'theme/Modal'

import './Languages.sass'

nameRequired = required 'Enter an language name please'


export default composeComponent 'Languages.NewLanguageForm',
  reduxForm
    form: 'NewLanguageForm'

  ({handleSubmit, submitFailed, submitting, invalid, error, languagesList}) ->
    React.createElement("form", {"onSubmit": (handleSubmit)},

      React.createElement(Field, { \
        "placeholder": "Select language name...",  \
        "name": "language",  \
        "component": (Select),  \
        "options": (languagesList),  \
        "validate": (nameRequired)
      }),

      (if submitFailed and error
        React.createElement("div", {"className": "Languages__error"},
          (if error is 'unknown' then 'Something went wrong. Please try again later.' else error)
        )
      ),

      React.createElement("button", { \
        "className": "btn btn_secondary btn_solid_bluePurple",  \
        "type": "submit",  \
        "disabled": (submitting or invalid and submitFailed)
      },
        (if submitting then 'Creating...' else 'Create')
      )
    )
import React from 'react'
import {composeComponent} from 'utils/react-tools'
import Modal from 'theme/Modal'
import Loader from 'theme/Loader'
import './ArchiveModal.sass'

export default composeComponent 'ArchiveModal',

  ({closeModal, modalVisible, loading, elementType, archive}) ->

    React.createElement(Modal, { \
      "isOpen": (modalVisible),  \
      "close": (closeModal),  \
      "className": "ArchiveModal__modal"
    },
      React.createElement(Loader, {"loading": (loading and modalVisible)}),
      React.createElement("div", null,
        React.createElement("p", null, "Are you sure you want to archive this ", (elementType), "?"),
        React.createElement("div", {"className": "JobHacks__modalBtns"},
          React.createElement("button", { \
            "className": "btn btn_secondary btn_solid_bluePurple",  \
            "onClick": (archive)
          }, """
            Archive
"""),
          React.createElement("button", { \
            "className": "btn btn_secondary",  \
            "onClick": (closeModal)
          }, "Cancel")
        )
      )
    )
import api from 'api'
import * as _ from 'ramda'


export getDrafts = (language) ->
  api.get '/cms/admin/i18n'
  .then _.filter _.propEq 'language', language

export createLanguage = ({language}) ->
  api.post "/cms/admin/i18n/create/#{language}"

export publish = (id) ->
  api.post "/cms/admin/i18n/#{id}/publish"

export activate = (id) ->
  api.post "/cms/admin/i18n/#{id}/activate"

export archive = (id) ->
  api.post "/cms/admin/i18n/#{id}/archive"

export getDraft = (id) ->
  api.get "/cms/admin/i18n/#{id}"

import React from 'react'
import { hot } from 'react-hot-loader'
import { Provider } from 'react-redux'
import Routes from './routes'
import store from 'store'

import './app.sass'


App = (arg) ->
  React.createElement(Provider, {"store": (store)},
    React.createElement("div", {"className": "App"},
      React.createElement("div", {"className": "App__content"},
        React.createElement(Routes, null)
      )
    )
  )

export default hot(module) App

import api from 'api'
import * as _ from 'ramda'


export getLanguages = ->
  api.get '/cms/admin/i18n'
  .then _.groupBy _.prop 'language'

export createLanguage = ({language}) ->
  api.post "/cms/admin/i18n/create/#{language}"

export getToTranslate = ->
  api.get "/cms/admin/toTranslate"

export getLanguagesList = ->
  api.get "/dictionaries/CmsTranslationLanguage"
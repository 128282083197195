import React from 'react'
import cx from 'classnames'
import {composeComponent} from 'utils/react-tools'
import {withStateHandlers, withState, lifecycle} from 'recompose'
import {TextField} from 'theme/Form'


export default composeComponent 'TableField',
  withState 'inputRef', 'setRef', null

  withStateHandlers
    editing: false
  ,
    edit: (_, {disabled}) -> -> if not disabled then editing: true
    endEditing: -> -> editing: false

  lifecycle
    componentDidUpdate: (prevProps) ->
      if @props.inputRef isnt prevProps.inputRef
        @props.inputRef?.focus()

  ({input, label, required, placeholder, className, textarea, disabled, meta, editing, edit, endEditing, setRef, tooltip, err, isNew}) ->

    error = meta.error ? err
    {touched, submitFailed} = meta
    fieldProps = {input, required, placeholder, className, textarea, disabled, meta: Object.assign {}, meta, {error}}

    React.createElement("tr", {"onClick": (if !editing then edit), "className": (cx {editing})},
      React.createElement("td", {"className": "TranslationView__tableLabel"},
        React.createElement("div", null,
          (if (touched or submitFailed) and error
            React.createElement("div", {"className": "TranslationView__fieldError"},
              React.createElement("span", {"className": "fas fa-exclamation-circle"}),
              (error)
            )
          ),
          (if isNew
            React.createElement("div", {"className": "TranslationView__fieldWarning"},
              React.createElement("span", {"className": "fas fa-exclamation-triangle"}), """
              New key
""")
          ),
          React.createElement("label", {"htmlFor": (name)}, (label))
        ),
        React.createElement("div", {"className": "TranslationView__tooltip"},
          (tooltip)
        )
      ),
      React.createElement("td", null,
        (if editing
          React.createElement("div", {"className": "TranslationView__tableCell"},
            React.createElement(TextField, Object.assign({ \
              "withoutError": true,  \
              "setRef": (setRef)
              }, fieldProps
            )),
            React.createElement("button", { \
              "className": "btn btn_compact btn_white_pink",  \
              "onClick": (endEditing)
            }, "Finished")
          )
        else
          React.createElement("div", {"className": "TranslationView__tableCell"},
            React.createElement("span", null, (input.value)),
            (unless disabled
              React.createElement("span", {"className": "fas fa-pen-square"})
            )
          )
        )
      )
    )
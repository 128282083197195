import React from 'react'
import {composeComponent} from 'utils/react-tools'
import {reduxForm, Field} from 'redux-form'
import {TextField, Select} from 'theme/Form'
import Loader from 'theme/Loader'
import {required} from 'validate'

import '../ConversationElements.sass'

fieldRequired = required 'Cannot be empty'

export default composeComponent 'Conversation.Form',
  reduxForm
    form: 'ConversationForm'
    enableReinitialize: true
    keepDirtyOnReinitialize: true

  ({
    options
    handleSubmit
    pristine
    submitting
    submitFailed
    initialValues
    err
  }) ->
    warning = if initialValues?.id then -> 'Changing this may lead to inapropriate import-export process.'

    React.createElement("form", {"onSubmit": (handleSubmit)},
      React.createElement("div", null,
        React.createElement("h2", null, "Details"),
        React.createElement("p", null,
          React.createElement("span", null, "Edit details and remember to save changes. ")
        )
      ),
      React.createElement(Loader, {"loading": (submitting)}),
      React.createElement(Field, { \
        "name": "label",  \
        "placeholder": "Conversation identifier",  \
        "label": "Label",  \
        "component": (TextField),  \
        "validate": (fieldRequired),  \
        "warn": (warning)
      }),
      React.createElement(Field, { \
        "name": "description",  \
        "placeholder": "Conversation description",  \
        "label": "Description",  \
        "component": (TextField),  \
        "validate": (fieldRequired),  \
        "warn": (warning)
      }),
      React.createElement(Field, { \
        "name": "organizationId",  \
        "placeholder": "Organization",  \
        "label": "Organization",  \
        "component": (Select),  \
        "options": (options.organization)
      }),
      React.createElement(Field, { \
        "name": "jobhacksLabels",  \
        "placeholder": "Set job hacks label",  \
        "label": "Job hacks",  \
        "isMulti": true,  \
        "isClearable": false,  \
        "component": (Select),  \
        "options": (options.jobhacksLabels)
      }),
      
      (if submitFailed and err
        React.createElement("div", {"className": "JobHack__error"}, (err))
      ),

      React.createElement("button", { \
        "type": "submit",  \
        "className": "btn btn_secondary btn_solid_bluePurple JobHack__btn",  \
        "disabled": (pristine || submitting)
      }, "Save")
    )

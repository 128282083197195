import React from 'react'
import Header from 'theme/Header'
import Footer from 'theme/Footer'
import {reduxForm, Field, SubmissionError} from 'redux-form'
import {withRouter} from 'react-router-dom'
import {required, email} from 'validate'
import {composeComponent} from 'utils/react-tools'
import {compose, withHandlers, withState} from 'recompose'
import {TextField} from 'theme/Form'
import {signin} from './requests'

import 'theme/btn/btn-styles.sass'
import './Signin.sass'


emailRequired = required 'Enter an email address please'
emailValid = email 'Enter a valid email address please'
passwordRequired = required 'Enter a password please'


SigninForm = reduxForm(
  form: 'signin'
) ({handleSubmit, submitFailed, submitting, invalid, error}) ->
    React.createElement("form", {"onSubmit": (handleSubmit)},
      React.createElement(Field, { \
        "placeholder": "Email address",  \
        "label": "Email",  \
        "name": "email",  \
        "component": (TextField),  \
        "validate": ([emailRequired, emailValid])
      }),
      React.createElement(Field, { \
        "name": "password",  \
        "label": "Password",  \
        "type": "password",  \
        "placeholder": "Password",  \
        "component": (TextField),  \
        "validate": (passwordRequired)
      }),

      (if submitFailed and error
        React.createElement("div", {"className": "Signin__error"},
          (if error is 'unknown' then 'Something went wrong. Please try again later.' else error)
        )
      ),

      React.createElement("button", { \
        "className": "btn btn_primary btn_solid_pink",  \
        "type": "submit",  \
        "disabled": (submitting or invalid and submitFailed)
      },
        (if submitting then 'Signin in...' else 'Sign In')
      )
    )


export default composeComponent 'Signin',
  withRouter

  withState 'error', 'setError', null

  withHandlers
    onSubmit: ({history, setError}) -> (values) ->
      signin values
      .then(
        -> history.push '/'
        (err) ->
          throw new SubmissionError _error: err.data?.message ? 'unknown'
      )

  ({onSubmit, error}) ->
    React.createElement("div", {"className": "Signin"},
      React.createElement(Header, null),
      React.createElement("div", {"className": "Signin__container container"},
        React.createElement("h1", null, "Sign In"),
        React.createElement(SigninForm, {"onSubmit": (onSubmit)})
      ),
      React.createElement(Footer, null)
    )
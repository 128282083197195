import React from 'react'
import {composeComponent} from 'utils/react-tools'
import {withState} from 'recompose'
import Header from 'theme/Header'
import Footer from 'theme/Footer'
import {getUser} from 'api'

import './NotFound.sass'


export default composeComponent 'NotFound',
  ->
    user = getUser()
    React.createElement("div", {"className": "NotFound page"},
      React.createElement(Header, {"user": (user)}),
      React.createElement("div", {"className": "container"},
        React.createElement("h1", null, "Ooops!"),
        React.createElement("p", null, "The page you\'re looking for doesn\'t exist.")
      ),
      React.createElement(Footer, null)
    )
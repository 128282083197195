import React from 'react'
import {composeComponent} from 'utils/react-tools'
import cx from 'classnames'

export EditionButtons = composeComponent 'EditionButtons',
  ({enableEdition, deleteAction, downAction, upAction}) ->
    React.createElement("div", {"className": (cx "cell", "Preview__cell_edition")},
      React.createElement("button", { \
        "className": "btnLink",  \
        "onClick": (() -> upAction())
      },
        React.createElement("span", {"className": "fas fa-arrow-up"})
      ),
      React.createElement("button", { \
        "className": "btnLink",  \
        "onClick": (() -> downAction())
      },
        React.createElement("span", {"className": "fas fa-arrow-down"})
      ),
      React.createElement("button", { \
        "className": "btnLink",  \
        "onClick": (() -> deleteAction())
      },
        React.createElement("span", {"className": "fas fa-times"})
      )
    )

export AddButton = composeComponent 'AddButton',
  ({rowText, enableEdition, addAction}) ->
    React.createElement("div", null,
      (if enableEdition
        React.createElement("div", {"className": (cx "Preview__row", "Preview__itemContent_row")},
          React.createElement("div", {"className": (cx "cell", "Preview__cell_add" )},
            (rowText)
          ),
          React.createElement("div", {"className": (cx "cell", "Preview__cell_addButton")},
            React.createElement("button", { \
              "className": "btn btn_compact btn_solid_bluePurple",  \
              "onClick": (-> addAction.func addAction.data)
            }, """
              Add
""")
          )
        )
      )
    )
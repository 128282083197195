import api from 'api'
import * as _ from 'ramda'

export getActiveLanguage = ->
  api.get '/cms/admin/i18n'
  .then (languages) ->
    languages.find (l) -> l.language is 'English' and l.state is 'Activated'

export getLanguages = ->
  api.get '/cms/admin/i18n'

export getCompetencies = (params) ->
  api.get '/admin/competency/list', params: includeArchive: true

export getCompetency = (id) ->
  api.get '/admin/competency/list', params: {id, includeArchive: true}
  .then _.head

export updateCompetency = (id, data) ->
  new Promise (resolve, reject) ->
    api.post "/admin/competency/#{id}", _.omit ['modifyDate', 'createDate', 'id', 'modifiedBy', 'archiveDate'], data
    .then ->
      getCompetency id
      .then resolve
    .catch reject

export createCompetency = (data) ->
  api.post "/admin/competency", data

export deleteCompetency = (id) ->
  api.delete "/admin/competency/#{id}"

export exportCompetencies = ->
  api.get "/admin/competency/export"

export importCompetencies = (data) ->
  new Promise (resolve, reject) ->
    api.post "/admin/competency/import", data
    .then ->
      getCompetencies()
      .then resolve
    .catch reject
#TODO: factor out and publish as a separate module
import * as _ from 'ramda'

EMAIL_RE = /// ^
  [a-z0-9!#$%&'*+\/=?^_`{|}~.-]+
  @
  ([a-z0-9]([a-z0-9-]*[a-z0-9])?\.)+
  [a-z0-9][a-z0-9]+
  $ ///i

ALPHABETIC = /^[a-zA-Z() ]*$/

required = (message='required') -> (value) -> if _.isNil value then message else undefined
minlen = (min, message='minlen') -> (value) -> message if _.lt _.length(value), min
maxlen = (max, message='maxlen') -> (value) -> message if _.gt _.length(value), max
alpha = (message='alpha') -> (value) -> message if _.test ALPHABETIC, value
# alphanum: (message='alphanum') -> rev.isAlphaNumeric {message}
# num: (message='alphanum') -> rev.isNumeric {message}
# oneof: (values, message='oneof') -> rev.isOneOf(values) {message}
# match: (field, message='match') -> rev.matchesField(field) {message}
# pattern: pattern
email = (message='email') -> (value) -> message if value and not EMAIL_RE.test value
# create: create
integer = (message='integer') -> (value) -> if value? and !Number.isInteger Number value then message else undefined

export {required, email, integer}

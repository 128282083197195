import React from 'react'
import {composeComponent} from 'utils/react-tools'
import {Link} from 'react-router-dom'
import {withRouter} from 'react-router'
import * as _ from 'ramda'
import {withHandlers, withStateHandlers, lifecycle, withProps} from 'recompose'
import cx from 'classnames'
import Loader from 'theme/Loader'
import Form from './Form.coffee'
import Translation from 'theme/TranslationView/Translation'
import ArchiveModal from 'theme/ArchiveModal'
import {getConversation, getLanguages, createConversation, updateConversation, getOrganizations, deleteConversation, getJobHacks} from 'peerConversations/requests'

import '../ConversationElements.sass'

export default composeComponent 'Conversation',
  withRouter
  withProps ({match: {params}}) ->
    conversationId: params.id
  withStateHandlers
    organizations: []
    jobHacks: []
    loading: false
    conversation: {}
    languages: null
    translationsVisible: false
    modalVisible: false
  ,
    setConversation: -> (conversation) -> {conversation}
    setOrganizations: -> (organizations) -> {organizations}
    setJobHacks: -> (jobHacks) -> {jobHacks}
    endLoading: -> -> loading: false
    startLoading: -> -> loading: true
    setSortBy: ({sortBy, sortDir}) -> (column) ->
      if column.name isnt sortBy.name
        sortBy: column
        sortDir: 'asc'
      else switch sortDir
        when 'asc' then sortDir: 'desc'
        when 'desc' then sortDir: 'asc'
        else sortDir: 'asc'
    changeFilter: -> (filters) -> filters: filters
    showMore: ({page}) -> -> page: page + 1
    setLanguages: -> (languages) -> {languages}
    showTranslations: -> -> translationsVisible: true
    closeTranslations: -> -> translationsVisible: false
    showModal: -> (data) -> modalVisible: true
    closeModal: -> -> modalVisible: false


  lifecycle
    componentDidMount: ->
      {startLoading, endLoading, setConversation, conversationId, setLanguages, setOrganizations, setJobHacks} = @props
      startLoading()

      a = if conversationId then getConversation(conversationId) else Promise.resolve({})
      a.then setConversation

      b = getLanguages()
      .then setLanguages

      c = getOrganizations()
      .then setOrganizations

      d = getJobHacks()
      .then setJobHacks

      Promise.all [a, b, c, d]
      .finally endLoading

  withProps ({organizations, jobHacks}) ->
    options: {
      'organization': organizations.map (organization) -> {label: organization.name, value: organization.id}
      'jobhacksLabels': jobHacks.map (jh) -> {label: jh.label, value: jh.label}
    }

  withHandlers
    submit: ({conversationId, history, setConversation}) -> (values) ->
      if conversationId
        updateConversation conversationId, values
        .then setConversation
      else
        createConversation values
        .then (conversation) ->
          setConversation conversation
          history.push "/peer-conversations/conversations/#{conversation.id}"

    archive: ({conversation, startLoading, endLoading, setConversation, closeModal}) -> ->
      startLoading()

      deleteConversation conversation.id
      .then ->
        getConversation conversation.id
        .then setConversation
        .then closeModal
        .finally endLoading
    
    unarchive: ({conversation, startLoading, endLoading, setConversation}) -> ->
      startLoading()

      updateConversation conversation.id, conversation
      .then setConversation
      .finally endLoading

  ({
    loading
    conversation
    organizations
    options
    match
    submit
    conversationId
    params
    languages
    translationsVisible
    showTranslations
    closeTranslations
    showModal
    closeModal
    modalVisible
    unarchive
    archive
  }) ->
    React.createElement("div", {"className": "container"},
      React.createElement(Loader, {"loading": (loading)}),
      React.createElement("div", {"className": "ConversationElements__heading"},
        React.createElement("div", null,
          React.createElement("h1", {"className": "ConversationElements__title"},
            React.createElement(Link, {"to": "/peer-conversations/conversations"},
              React.createElement("span", {"className": "far fa-chevron-left"}),
              (conversation.label ? 'New Conversation')
            )
          )
        ),
        React.createElement("div", {"className": "ConversationElements__headingBtns"},
          (if conversation.archiveDate
            React.createElement("button", {"onClick": (unarchive), "className": "btn btn_secondary btn_outlined_bluePurple"}, "Unarchive")
          else
            React.createElement("button", {"onClick": (showModal), "className": "btn btn_secondary btn_outlined_bluePurple"}, "Archive")
          ),
          React.createElement("button", { \
            "onClick": (if translationsVisible then closeTranslations else showTranslations),  \
            "className": "btn btn_secondary btn_outlined_bluePurple",  \
            "disabled": (!conversationId)
          },
            (if translationsVisible then 'Close content' else 'Edit content')
          )
        )
      ),

      React.createElement("div", {"className": "ConversationElements__content"},
        React.createElement("div", {"className": (cx "form", if translationsVisible then 'form__translationVisible')},
          (unless loading
            React.createElement(Form, { \
              "onSubmit": (submit),  \
              "options": (options),  \
              "initialValues": (if conversationId then conversation else null)
            })
          )
        ),
        (if translationsVisible
          React.createElement(Translation, { \
            "languages": (languages),  \
            "element": (conversation),  \
            "type": "peerconversation.conversation"
          })
        )
      ),

      React.createElement(ArchiveModal, { \
        "closeModal": (closeModal),  \
        "loading": (loading),  \
        "elementType": "conversation",  \
        "archive": (archive),  \
        "modalVisible": (modalVisible)
      })

    )

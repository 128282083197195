import React from 'react'
import T from 'tcomb'
import * as _ from 'ramda'
import {Children, cloneElement} from 'react'
import { CSSTransition as Transition } from 'react-transition-group';
import onClickOutside from 'react-onclickoutside'
import {
  composeComponent
  setPropTypes
  withClassNames
  FirstChild
  ReactComponent
} from 'utils/react-tools'
import {
  defaultProps
  withHandlers
  withProps
  mapProps
  withState
  componentFromProp
  setStatic
} from 'recompose'

import s from './Dropdown.sass'


export Content = composeComponent 'Dropdown.Content',
  defaultProps
    component: 'div'
  setPropTypes
    component: ReactComponent
    hide: T.maybe T.Function
    onClick: T.maybe T.Function
  withHandlers
    onClick: ({onClick, hide}) -> (e) ->
      onClick? e
      hide?()
  withClassNames "Dropdown__content"
  mapProps _.omit ['hide']
  componentFromProp 'component'


export Menu = composeComponent 'Dropdown.Menu',
  defaultProps
    component: 'ul'
  Content


export MenuItem = composeComponent 'Dropdown.MenuItem',
  defaultProps
    wrapperComponent: 'li'
    wrapperProps: {}
    component: 'a'
  setPropTypes
    wrapperComponent: ReactComponent
    wrapperProps: T.Object
    component: T.union [ReactComponent, T.String]
    onRef: T.maybe T.Function

  withClassNames "Dropdown__menuItem"

  withProps (props) ->
    passThru = _.omit [
      'wrapperComponent'
      'wrapperProps'
      'component'
      'children'
      'onRef'
    ], props
    passThru.ref = prop "Dropdown__onRef" if props.onRef
    {passThru}

  ({wrapperComponent, wrapperProps, component, children, passThru}) ->
    WrapperComponent = wrapperComponent
    Component = component

    React.createElement(WrapperComponent, Object.assign({},  wrapperProps),
      React.createElement(Component, Object.assign({},  passThru),
        (children)
      )
    )


TRIGGER_EQ_PROP = '__isDropdownTrigger'

export Trigger = composeComponent 'Dropdown.Trigger',
  setStatic TRIGGER_EQ_PROP, Math.random().toString()
  defaultProps
    component: 'a'
  setPropTypes
    component: ReactComponent
    toggle: T.maybe T.Function
    onClick: T.maybe T.Function
    disabled: T.maybe T.Boolean
  withProps ({component, href}) ->
    if component is 'a' and not href?
      href: 'javascript:void 0'
  withHandlers
    onClick: ({onClick, toggle, disabled}) -> (e) ->
      if not disabled
        onClick? e
        toggle?()
  withClassNames "Dropdown__trigger"
  mapProps _.omit ['toggle']
  componentFromProp 'component'


export Container = composeComponent 'Dropdown.Container',
  defaultProps
    component: 'div'
    hideOnClick: true
  setPropTypes
    active: T.maybe T.Boolean
    component: ReactComponent
    onShow: T.maybe T.Function
    onHide: T.maybe T.Function
    hideOnClick: T.Boolean

  withState 'stActive', 'setActive', false

  withProps ({active, stActive}) ->
    active: active ? stActive

  withHandlers
    hide: ({onHide, setActive}) -> ->
      setActive false
      onHide?()

    show: ({onShow, setActive}) -> ->
      setActive true
      onShow?()

  withHandlers
    toggle: ({active, hide, show}) -> ->
      if active then hide() else show()

    handleClickOutside: ({hide, active}) -> ->
      hide() if active

  withClassNames "Dropdown__container"

  onClickOutside

  withProps ({children, hideOnClick, active, toggle, hide}) ->
    children: Children.map children, (child) ->
      return null unless child
      if child.type[TRIGGER_EQ_PROP] is Trigger[TRIGGER_EQ_PROP]
        cloneElement child, {toggle}
      else
        React.createElement(Transition, { \
          "in": (active),  \
          "timeout": (200),  \
          "classNames": ({
            enter: "Dropdown__enter",
            enterActive: "Dropdown__enterActive",
            exit: "Dropdown__leave",
            exitActive: "Dropdown__leaveActive",
          }),  \
          "unmountOnExit": true
        },
          React.createElement(FirstChild, null,
            (cloneElement child,
              key: 'dropdown'
              hide: hide if hideOnClick
            )
          )
        )

  mapProps _.omit [
    'active'
    'stActive'
    'setActive'
    'onShow'
    'onHide'
    'hideOnClick'
    'hide'
    'show'
    'toggle'
    'handleClickOutside'
    # props from onClickOutside
    'eventTypes'
    'outsideClickIgnoreClass'
    'preventDefault'
    'stopPropagation'
    'wrappedRef'
    'disableOnClickOutside'
    'enableOnClickOutside'
  ]

  componentFromProp 'component'

import React from 'react'
import {composeComponent} from 'utils/react-tools'
import {Link} from 'react-router-dom'
import {withRouter} from 'react-router'
import * as _ from 'ramda'
import RSelect from 'react-select'
import {withHandlers, withStateHandlers, lifecycle, withProps, withState, mapProps} from 'recompose'
import {SubmissionError} from 'redux-form'
import InView from 'react-inview-monitor'
import moment from 'moment'
import cx from 'classnames'
import Header from 'theme/Header'
import Loader from 'theme/Loader'
import DeleteModal from 'theme/DeleteModal'
import Footer from 'theme/Footer'
import Heading from 'theme/Heading'
import {LinksSection } from 'theme/Heading/HeadingElements'
import {getUser} from 'api'
import {getJobHacks, getEnumerators, exportJobHacks, importJobHacks, deleteJobHack, getActiveLanguage} from 'jobHacks/requests'

import './JobHacks.sass'

VISIBLE_ROWS = 20
HEADINGS = [
    name: 'Label'
    sortFunc: _.prop 'label'
  ,
    name: 'Type'
    sortFunc: _.prop 'drtc'
  ,
    name: 'Stage'
    sortFunc: _.prop 'stage'
  ,
    name: 'Content Type'
    sortFunc: _.prop 'contentType'
  ,
    name: 'Development Goal'
    sortFunc: _.compose _.head, _.prop 'developmentGoal'
  ,
    name: 'Last Modified'
    sortFunc: _.prop 'modifyDate'
  ,
    name: 'Archived'
    sortFunc: _.prop 'archiveDate'
]
GROUPS_MAP =
  drtcs: 'Type'
  contentType: 'Content Type'
  developmentGoals: 'Development Goals'

export default composeComponent 'JobHacks',
  withRouter
  withState 'fileInput', 'setFileInput', null
  withState 'importStatus', 'setImportStatus', null
  withState 'language', 'setLanguage', null
  withStateHandlers
    loading: false
    jobHacks: []
    sortBy: HEADINGS[0]
    sortDir: 'asc'
    enumerators: {}
    filters: []
    page: 1
    exporting: false
    modal:
      visible: false
      data: null
  ,
    setJobHacks: -> (jobHacks) -> {jobHacks}
    endLoading: -> -> loading: false
    startLoading: -> -> loading: true
    setSortBy: ({sortBy, sortDir}) -> (column) ->
      if column.name isnt sortBy.name
        sortBy: column
        sortDir: 'asc'
      else switch sortDir
        when 'asc' then sortDir: 'desc'
        when 'desc' then sortDir: 'asc'
        else sortDir: 'asc'
    setEnumerators: -> (enumerators) -> {enumerators}
    changeFilter: -> (filters) -> filters: filters
    showMore: ({page}) -> -> page: page + 1
    showModal: -> (data) -> modal: {visible: true, data}
    closeModal: ({modal}) -> -> modal: _.merge modal, visible: false
    setExporting: -> (exporting) -> {exporting}

  lifecycle
    componentDidMount: ->
      {startLoading, endLoading, setJobHacks, setEnumerators, setLanguage} = @props
      startLoading()

      a = getJobHacks()
      .then setJobHacks

      b = getEnumerators()
      .then setEnumerators

      c = getActiveLanguage()
      .then setLanguage

      Promise.all [a, b, c]
      .finally endLoading

  mapProps (props) ->
    reverse = if props.sortDir is 'desc' then _.reverse else _.identity
    _.evolve(
      jobHacks: _.compose reverse, _.sortBy(props.sortBy?.sortFunc)
    ) props

  withProps ({enumerators}) ->
    options: for group, o of enumerators
      label: GROUPS_MAP[group] ? group
      options: o.map (oo) -> label: oo, value: oo, group: group

  withHandlers
    changeFilter: ({changeFilter, filters, startLoading, endLoading, setJobHacks}) -> (f) ->
      changeFilter f
      startLoading()

      keysToFilter = _.uniq _.pluck 'group', f
      groupedFilters = keysToFilter.reduce (obj, a) ->
        "#{a}": _.pluck 'value', _.filter _.propEq('group', a), f
      , {}

      getJobHacks groupedFilters
      .then setJobHacks, -> changeFilter filters
      .finally endLoading

    exportJobHacks: ({setExporting}) -> ->
      setExporting true
      exportJobHacks()
      .then (jobhacks) ->
        a = document.createElement 'a'
        data = JSON.stringify jobhacks, null, 4
        file = new Blob [data], type: 'text/plain'
        a.href = URL.createObjectURL file
        a.download = "ImperativeJobHacks.txt"
        a.click()
      .finally -> setExporting false

    importJobHacks: ({setJobHacks, fileInput, setErrors, setImportStatus}) -> (e) ->
      file = fileInput.files[0]
      if file.type?.match /text.*/
        reader = new FileReader()

        reader.onload = =>
          try
            result = JSON.parse reader.result ? '[]'
            importJobHacks result
            .then (d) ->
              setJobHacks d
              setImportStatus type: 'success'
            .catch (err) ->
              setImportStatus type: 'error', message: e, error: err.message
          catch err
            if err instanceof SyntaxError
              e = 'File is corrupted.'
            else e = 'Unknown error'
            setImportStatus type: 'error', message: e, error: err.message

        reader.readAsText file

    deleteJobHack: ({setJobHacks, startLoading, endLoading, jobHacks, closeModal}) -> (jobHack) -> (e) ->
      e.stopPropagation()
      startLoading()
      deleteJobHack jobHack.id
      .then ->
        getJobHacks()
         .then setJobHacks
        closeModal()
      .finally endLoading

  ({
    loading
    history
    jobHacks
    count
    match
    modalOpened
    closeModal
    setSortBy
    sortBy
    sortDir
    options
    enumerators
    filters
    changeFilter
    page
    showMore
    exportJobHacks
    exporting
    importing
    importJobHacks
    fileInput
    setFileInput
    deleteJobHack
    modal
    showModal
    language
    importStatus
  }) ->
    user = getUser()
    count = if jobHacks.length then page * VISIBLE_ROWS else jobHacks.length
    maxCount = if count > jobHacks.length then jobHacks.length else count
    links = [
      {text: "Edit job hacks translations", link: "/languages/English/#{language?.id}?key=jobhack"},
      {text: "Add new job hack", link: "/job-hacks/new"}
    ]

    React.createElement("div", {"className": "JobHacks page"},
      React.createElement(Header, {"user": (user)}),
      React.createElement("div", {"className": "container"},
        React.createElement("input", {"type": "file", "ref": (setFileInput), "onChange": (importJobHacks), "className": "JobHacks__import"}),

        React.createElement(Heading, { \
          "name": "Job Hacks",  \
          "subtitle": "List of available job hacks in the app. Click on a row to edit its details.",  \
          "linksSection": (
            React.createElement(LinksSection, { \
              "links": (links)
            })
          ),  \
          "buttonsSection": (
           React.createElement("div", null,
              React.createElement("button", {"onClick": (->fileInput.click()), "className": "btn btn_secondary btn_outlined_bluePurple", "disabled": (importing)}, """
                Import
""", (if importing
                  React.createElement("span", {"className": "fas fa-spinner fa-spin btn__icon_after"})
                )
              ),
              React.createElement("button", {"onClick": (exportJobHacks), "className": "btn btn_secondary btn_outlined_bluePurple", "disabled": (exporting)}, """
                Export
""", (if exporting
                  React.createElement("span", {"className": "fas fa-spinner fa-spin btn__icon_after"})
                )
              )
            )
          )
        }),

        (if importStatus?
          React.createElement("div", {"className": (cx "JobHacks__info", if importStatus.type is 'error' then 'JobHacks__errors' else 'JobHacks__success')},
            (if importStatus.type is 'success'
              React.createElement("div", null,
                React.createElement("span", null, "Import Successful")
              )
            else
              React.createElement("div", null,
                (importStatus.message),
                React.createElement("p", null, (importStatus.error))
              )
            )
          )
        ),

        React.createElement("div", {"className": "JobHacks__filters"},
          React.createElement(RSelect, { \
            "className": "JobHacks__select",  \
            "classNamePrefix": "JobHacks__select",  \
            "options": (options),  \
            "isMulti": true,  \
            "value": (filters),  \
            "onChange": (changeFilter),  \
            "placeholder": "Filter"
          })
        ),

        React.createElement("div", {"className": "JobHacks__table relative"},
          React.createElement(Loader, {"loading": (loading or importing)}),
          React.createElement("table", null,
            React.createElement("thead", null,
              React.createElement("tr", null,
                (HEADINGS.map (h, i) ->
                  React.createElement("th", {"onClick": (-> setSortBy h), "key": (i)},
                    (h.name),
                    (if h.name is sortBy.name
                      React.createElement("i", {"className": "far #{if sortDir is 'asc' then 'fa-chevron-down' else 'fa-chevron-up'}"})
                    )
                  )
                ),
                React.createElement("th", null)
              )
            ),
            React.createElement("tbody", null,
              ([0...maxCount].map (i) ->
                hack = jobHacks[i]
                React.createElement("tr", {"key": (i), "onClick": (-> history.push "/job-hacks/#{hack.id}")},
                  React.createElement("td", null,
                    (hack.label)
                  ),
                  React.createElement("td", null,
                    (hack.drtc)
                  ),
                  React.createElement("td", null,
                    (hack.stage)
                  ),
                  React.createElement("td", null,
                    (hack.contentType)
                  ),
                  React.createElement("td", null,
                    (hack.developmentGoal.map (goal, i) ->
                      React.createElement("span", {"className": "JobHacks__goal #{goal}", "key": (i)},
                        (goal)
                      )
                    )
                  ),
                  React.createElement("td", null,
                    (moment(hack.modifyDate).format 'MMM DD, YYYY')
                  ),
                  React.createElement("td", null,
                    (if hack.archiveDate
                      React.createElement("span", {"className": "far fa-check"})
                    )
                  ),
                  React.createElement("td", null,
                    React.createElement("button", { \
                      "className": "btnLink",  \
                      "onClick": ((e) ->
                        e.stopPropagation()
                        showModal hack
                      )
                    },
                    (if !hack.archiveDate
                      React.createElement("span", {"className": "fas fa-trash"})
                    )
                    ),
                    React.createElement(Link, { \
                      "className": "btn btn_compact btn_solid_bluePurple",  \
                      "to": "/languages/English/#{language?.id}?key=jobhack.#{hack.label}",  \
                      "onClick": ((e) -> e.stopPropagation())
                    }, """
                      Edit translations
""")
                  )
                )
              ),
              (if maxCount < jobHacks.length
                React.createElement("tr", null,
                  React.createElement("td", {"colSpan": "8"},
                    React.createElement(InView, { \
                      "onInView": (showMore),  \
                      "intoViewMargin": "0%",  \
                      "repeatOnInView": true
                    }, "Loading more...")
                  )
                )
              )
            )
          )
        )

      ),
      React.createElement(Footer, null),

      React.createElement(DeleteModal, { \
        "modal": (modal),  \
        "closeModal": (closeModal),  \
        "loading": (loading),  \
        "removedItemName": "job hack",  \
        "deleteFunc": (deleteJobHack)
      })

    )
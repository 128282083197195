import api from 'api'
import * as _ from 'ramda'

export getActiveLanguage = ->
  api.get '/cms/admin/i18n'
  .then (languages) ->
    languages.find (l) -> l.language is 'English' and l.state is 'Activated'

export getLanguages = ->
  api.get '/cms/admin/i18n'

export getJobHacks = (params) ->
  api.get '/admin/jobhack/list', params: _.merge params, includeArchive: true

export getJobHack = (id) ->
  api.get '/admin/jobhack/list', params: {id, includeArchive: true}
  .then _.head

export updateJobHack = (id, data) ->
  new Promise (resolve, reject) ->
    api.post "/admin/jobhack/#{id}", _.omit ['modifyDate', 'createDate', 'id', 'modifiedBy', 'archiveDate'], data
    .then ->
      getJobHack id
      .then resolve
    .catch reject

export createJobHack = (data) ->
  api.post "/admin/jobhack", data

export deleteJobHack = (id) ->
  api.delete "/admin/jobhack/#{id}"

export exportJobHacks = ->
  api.get "/admin/jobhack/export"

export importJobHacks = (data) ->
  new Promise (resolve, reject) ->
    api.post "/admin/jobhack/import", data
    .then ->
      getJobHacks()
      .then resolve
    .catch reject

export getDrtc = ->
  api.get '/dictionaries/JobHackDrtc'
  .catch -> Promise.resolve []

export getStage = ->
  api.get '/dictionaries/JobHackStage'
  .catch -> Promise.resolve []

export getDevelopmentGoal = ->
  api.get '/dictionaries/JobHackDevelopmentGoal'
  .catch -> Promise.resolve []

export getDriver = ->
  api.get '/dictionaries/JobHackDriver'
  .catch -> Promise.resolve []

export getContentType = ->
  api.get '/dictionaries/JobHackContentType'
  .catch -> Promise.resolve []

export getEnumerators = ->
  Promise.all [getDrtc(), getStage(), getDevelopmentGoal(), getDriver(), getContentType()]
  .then ([drtcs, stages, developmentGoals, drivers, contentType]) ->
    {drtcs, stages, developmentGoals, drivers, contentType}

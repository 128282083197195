import React from 'react'
import {composeComponent} from 'utils/react-tools'
import {withHandlers, withProps, withStateHandlers} from 'recompose'
import {Link} from 'react-router-dom'
import Dropdown from 'react-select'
import {Children} from 'react'
import * as _ from 'ramda'
import cx from 'classnames'
import Modal from 'react-modal'
import * as D from 'theme/Dropdown'
import Avatar from 'theme/Avatar'

import 'theme/grid/grid-styles.sass'
import './Header.sass'


config = window.config

LOGO_LINK = '/'

LINKS =
  signin:
    title: 'Sign In'
    url: '/signin'
  start:
    title: 'Get Started'
    url: (www) -> "#{www}/get-started"
  languages:
    title: 'Languages'
    url: '/languages'
  microLessons:
    title: 'Job Hacks'
    url: '/job-hacks'
  peerConversations:
    title: 'Peer Conversations'
    url: '/peer-conversations/programs'
  competencies:
    title: 'Competencies'
    url: '/competencies'
  content:
    title: 'Content'
    url: '/content'
  settings:
    title: 'Settings'
    url: (dashboardAddress) -> "#{dashboardAddress}/settings"
  logout:
    title: 'Logout'
    url: '/logout'

BASE_URL = '/'


export default composeComponent 'Header',
  withStateHandlers
    modalVisible: false
  ,
    closeModal: -> -> modalVisible: false
    toggleModal: ({modalVisible}) -> -> modalVisible: not modalVisible

  withProps ({user}) ->
    loc = window.location.pathname
    www = config.www?.address
    dashboardAddress = config.dashboard?.address

    current = do ->
      name = Object.keys(LINKS).find (name) ->
        {url} = LINKS[name]
        url isnt BASE_URL and loc.startsWith url

      {url, title} = LINKS[name] ? {}
      {name, url, title}

    {current, www, dashboardAddress}

  withHandlers
    navItem: ({current, www}) -> (name) ->
      {url, title} = LINKS[name]
      React.createElement(Link, { \
        "className": (cx "Header__navItem", active: name is current.name),  \
        "to": (url?(www) ? url),  \
        "key": (name)
      }, (title))

  ({className, navItem, user, dashboardAddress, toggleModal, modalVisible, closeModal, current}) ->
    rtl = document.body.dir is 'rtl'

    React.createElement("header", { \
      "className": "Header",  \
      "id": "Header__main",  \
      "role": "banner"
    },
      React.createElement("div", {"className": (cx "Header__content container", dir: if rtl then 'rtl')},
        React.createElement("div", {"className": "Header__logoContainer"},
          React.createElement(Link, {"to": (LOGO_LINK), "className": "Header__logo"})
        ),
        (if user
          React.createElement("nav", {"className": "Header__left"},
            (navItem 'languages'),
            (navItem 'microLessons'),
            (navItem 'peerConversations'),
            (navItem 'competencies')
          )
        ),
        (if user
          React.createElement("nav", {"className": "Header__leftMobile"},
            React.createElement("button", { \
              "className": "Header__navItem btn active",  \
              "onClick": (toggleModal)
            },
              React.createElement("span", null, (current.title ? 'dashboard')),
              React.createElement("i", {"className": "far fa-chevron-down"})
            )
          )
        ),

        React.createElement("nav", {"className": "Header__right"},
          (if user
            React.createElement("div", {"className": "Header__user"},
              React.createElement(Link, {"className": "Header__userLink", "to": (LINKS.languages.url)},
                React.createElement(Avatar, {"for": (user)})
              ),
              React.createElement(D.Container, {"className": "Header__userMenu"},
                React.createElement(D.Trigger, { \
                  "className": "Header__userMenuTrigger fa fa-chevron-down",  \
                  "id": "Header__menuTrigger"
                }),
                React.createElement(D.Menu, null,
                  React.createElement(D.MenuItem, {"component": (Link), "to": (LINKS.languages.url)},
                    (LINKS.languages.title)
                  ),
                  React.createElement(D.MenuItem, {"to": (LINKS.settings.url dashboardAddress)},
                    (LINKS.settings.title)
                  ),
                  React.createElement(D.MenuItem, {"component": (Link), "to": (LINKS.logout.url)},
                    (LINKS.logout.title)
                  )
                )
              )
            )
          else
            navItem 'signin'
          )
        ),

        React.createElement(Modal, { \
          "className": "Header__modal",  \
          "overlayClassName": "Header__modalOverlay",  \
          "isOpen": (modalVisible),  \
          "onRequestClose": (closeModal),  \
          "contentLabel": "Modal"
        },
          React.createElement("nav", {"className": "Header__mobileNav"},
            (if user
              [
                navItem 'languages'
                navItem 'microLessons'
                navItem 'peerConversations'
                navItem 'competencies'
              ]
            )
          )
        )

      )
    )

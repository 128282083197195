import React from 'react'
import {composeComponent} from 'utils/react-tools'
import {Link} from 'react-router-dom'
import {withRouter} from 'react-router'
import * as _ from 'ramda'
import {withHandlers, withProps, withState} from 'recompose'
import cx from 'classnames'
import Collapse from 'react-collapse'
import {deleteProgramSet, addSetConversation, getPeerConversation, updateProgramSet} from 'peerConversations/requests'
import ConversationDetails from './ConversationDetails.coffee'
import Form from './Form.coffee'
import {EditionButtons, AddButton} from './EditionButtons.coffee'

import './Preview.sass'

export default composeComponent 'ProgramDetails.SetDetails',
  withRouter
  withState 'collapsed', 'open', true
  withState 'detailsVisible', 'showDetails', false
  withProps ({setDetails, opened, collapsed, conversations, conversationTree}) ->
    opened: opened or not collapsed
    setId: (_.head setDetails)?.id
    localConversations: conversations.map (conv) -> {label: conv.label}
    conversationsAssigned: conversationTree.map (conv) -> {label: conv.label}

  withProps ({localConversations, conversationsAssigned, conversations}) ->
    convToAddLabels = _.difference localConversations, conversationsAssigned
    convToAdd = convToAddLabels.map (label) -> _.find(_.propEq('label', label.label))(conversations)
    options: convToAdd.map (conv) -> {label: conv.label, value: conv.id}

  withHandlers
    deleteAction: ({programId, setPeerConversations, setDetails}) -> ->
      deleteProgramSet programId, (_.head setDetails).id
        .then () -> getPeerConversation()
          .then (tree) ->
            setPeerConversations tree
    submit: ({setId, conversationTree, setPeerConversations}) -> (values) ->
      addSetConversation Object.assign {}, {setId: setId, definitionId: values.id, seq: conversationTree.length+1}
        .then () -> getPeerConversation()
          .then (tree) ->
            setPeerConversations tree
    upAction: ({programId, setId, previousSet, setPeerConversations}) -> ->
      if _.head previousSet
        updateProgramSet programId, Object.assign {}, { firstId: setId, secondId: (_.head previousSet)?.id}
          .then () -> getPeerConversation()
            .then (tree) ->
              setPeerConversations tree
    downAction: ({programId, setId, nextSet, setPeerConversations}) -> ->
      if _.head nextSet
        updateProgramSet programId, Object.assign({}, {firstId: (_.head nextSet)?.id}, secondId: setId)
          .then () -> getPeerConversation()
            .then (tree) ->
              setPeerConversations tree

  ({
    setDetails
    conversationTree
    conversations
    questions
    enableEdition
    opened
    open
    collapsed
    programId
    deleteAction
    options
    submit
    setPeerConversations
    upAction
    downAction
  }) ->

    set = _.head setDetails

    React.createElement("div", {"className": "Preview__item"},
      React.createElement("div", {"className": (cx "Preview__row", "Preview__itemContent_row", if opened then "Preview__opened")},
        React.createElement("div", {"className": (cx "cell", "Preview__cell_name"), "onClick": (-> open !collapsed)}, """
          Set """, (set?.label), " (", (conversationTree.length), """)
"""),
        React.createElement("div", {"className": (cx "cell", "Preview__cell_description"), "onClick": (-> open !collapsed)},
          (set?.description)
        ),

        (if enableEdition
          React.createElement(EditionButtons, { \
            "enableEdition": (enableEdition),  \
            "deleteAction": (deleteAction),  \
            "upAction": (upAction),  \
            "downAction": (downAction)
          })
        ),
        React.createElement("span", {"className": (cx "Preview__cell_icon", 'btnLink btnLink_secondary'), "onClick": (-> open !collapsed)},
          (React.createElement("i", {"className": "fal #{if opened then 'fa-chevron-up btn__icon_after' else 'fa-chevron-down btn__icon_after'}"}))
        )
      ),
      
      React.createElement("div", {"className": "Preview__itemContent"},
        React.createElement(Collapse, {"isOpened": (opened), "hasNestedCollapse": (true)},
          (
            conversationTree.map (conversation, index) ->
              React.createElement(ConversationDetails, { \
                "key": (index),  \
                "questionTree": (conversation.questions),  \
                "conversationDetails": (conversations.filter (c) -> c.label is conversation.label),  \
                "enableEdition": (enableEdition),  \
                "questions": (questions),  \
                "setId": (set?.id),  \
                "setPeerConversations": (setPeerConversations),  \
                "previousConv": (conversations.filter (conv) -> conv.label is conversationTree[index-1]?.label),  \
                "nextConv": (conversations.filter (conv) -> conv.label is conversationTree[index+1]?.label)
              })
          )
        ),
        React.createElement(Form, { \
          "enableEdition": (enableEdition),  \
          "placeholderText": "Add new conversation",  \
          "onSubmit": (submit),  \
          "options": (options)
        })
      )

    )

import React from 'react'
import {composeComponent} from 'utils/react-tools'
import {withRouter, Link} from 'react-router-dom'
import * as _ from 'ramda'
import {withHandlers, withStateHandlers, lifecycle, withProps} from 'recompose'
import moment from 'moment'
import cx from 'classnames'
import Header from 'theme/Header'
import Loader from 'theme/Loader'
import Footer from 'theme/Footer'
import {getUser} from 'api'
import Modal from 'theme/Modal'
import {getDrafts, getTranslation, createLanguage, publish, activate, archive, getDraft} from './requests'

import './Drafts.sass'

ConfirmModal = ({modalType, confirm, note, close, error}) ->
  React.createElement("div", null,
    React.createElement("p", null, "Are you sure you want to ", React.createElement("span", {"className": (modalType)}, (modalType)), " this draft?"),
    (if note
      React.createElement("p", {"className": "Drafts__modalNote"}, (note))
    ),
    (if error
      React.createElement("div", {"className": "Drafts__modalError"},
        (error)
      )
    ),
    React.createElement("div", {"className": "Drafts__modalBtns"},
      React.createElement("button", { \
        "className": "btn btn_secondary btn_solid_bluePurple",  \
        "onClick": (confirm)
      },
        (modalType)
      ),
      React.createElement("button", { \
        "className": "btn btn_secondary",  \
        "onClick": (close)
      }, "Cancel")
    )
  )

updateDraftError = (draft) ->
  React.createElement("span", null, """
    Cannot perform this action. This may be caused by not valid translations.
    Please go to the """, React.createElement(Link, {"to": "/languages/#{draft.language}/#{draft.id}"}, "translations view"), """ and try to save it.
""")


export default composeComponent 'Drafts',
  withRouter

  withStateHandlers
    loading: false
    updatingDraft: false
    drafts: []
    choosingTemplate: false
    modalOpened: false
    modalType: null
    modalPayload: null
    modalError: null
  ,
    setDrafts: -> (drafts) -> {drafts}
    startLoadingDraft: -> (draft) -> updatingDraft: draft.id
    endLoadingDraft: -> -> updatingDraft: false
    endLoading: -> -> loading: false
    startLoading: -> -> loading: true
    chooseTemplate: -> -> choosingTemplate: true
    endChooseTemplate: -> -> choosingTemplate: false
    openModal: ({modalPayload}) -> (modalType, payload) ->
      modalOpened: true
      modalType: modalType
      modalPayload: payload ? modalPayload
      modalError: null
    setModalError: -> (error) ->
      modalError: error
    closeModal: -> -> modalOpened: false
    updateDraft: ({drafts}) -> (id, draft) ->
      i = _.findIndex _.propEq('id', id), drafts
      newDraft = _.merge drafts[i], draft
      drafts: _.update i, newDraft, drafts
    deactivateDraft: ({drafts}) -> ->
      i = _.findIndex _.propEq('state', 'Activated'), drafts
      newDraft = _.merge drafts[i], state: 'Published'
      drafts: _.update i, newDraft, drafts
    removeDraft: ({drafts}) -> (draft) ->
      i = _.findIndex _.propEq('id', draft.id), drafts
      drafts: _.remove i, 1, drafts

  withHandlers
    loadData: ({startLoading, endLoading, setDrafts, match: {params}, archived}) -> ->
      {languageName} = params
      startLoading()
      getDrafts languageName
      .then (drafts) ->
        drafts.filter (d) -> if archived then d.archiveDate? else not d.archiveDate?
      .then (drafts) ->
        endLoading()
        setDrafts drafts
      ,
        -> endLoading()

  lifecycle
    componentDidMount: ->
      @props.loadData()

    componentDidUpdate: (nextProps) ->
      if @props.archived isnt nextProps.archived
        nextProps.loadData()

  withHandlers
    clickOnRow: ({history, choosingTemplate}) -> (draft) ->
      history.push "/languages/#{draft.language}/#{draft.id}"
    addLanguage: ({drafts, setDrafts, closeModal}) -> (values) ->
      createLanguage values
      .then (draft) ->
        setDrafts [].concat drafts, [draft.header]
        closeModal()
    openModal: ({openModal}) -> (modalType, modalPayload) -> (e) ->
      e.stopPropagation()
      openModal modalType, modalPayload
    publishDraft: ({updateDraft, closeModal, startLoadingDraft, endLoadingDraft, setModalError}) -> (draft) -> (e) ->
      startLoadingDraft draft
      e.stopPropagation()
      publish draft.id
      .then ->
        updateDraft draft.id, state: 'Published'
        endLoadingDraft()
        closeModal()
      , ->
        setModalError updateDraftError draft
        endLoadingDraft()
    activateVersion: ({updateDraft, closeModal, deactivateDraft, startLoadingDraft, endLoadingDraft, setModalError}) -> (draft) -> (e) ->
      startLoadingDraft draft
      e.stopPropagation()
      activate draft.id
      .then ->
        deactivateDraft()
        updateDraft draft.id, state: 'Activated'
        endLoadingDraft()
        closeModal()
      , ->
        setModalError updateDraftError draft
        endLoadingDraft()
    archiveVersion: ({closeModal, startLoadingDraft, endLoadingDraft, removeDraft}) -> (draft) -> (e) ->
      startLoadingDraft draft
      e.stopPropagation()
      archive draft.id
      .then ->
        removeDraft draft
        endLoadingDraft()
        closeModal()
      .catch endLoadingDraft
    exportDraft: ({startLoadingDraft, endLoadingDraft}) -> (d) -> (e) ->
      startLoadingDraft d
      e.stopPropagation()
      getDraft d.id
      .then (draft) ->
        a = document.createElement 'a'
        data = JSON.stringify draft.translation.values, null, 4
        file = new Blob [data], type: 'text/plain'
        a.href = URL.createObjectURL file
        a.download = "ImperativeTranslation_#{draft.header.language}_#{draft.header.version}.txt"
        a.click()
        endLoadingDraft()
      .catch endLoadingDraft

  ({drafts
    loading
    history
    endChooseTemplate
    chooseTemplate
    choosingTemplate
    clickOnRow
    openModal
    closeModal
    modalOpened
    addLanguage
    match
    modalType
    publishDraft
    activateVersion
    archiveVersion
    modalPayload
    modalError
    archived
    exportDraft
    updatingDraft
  }) ->
    {languageName} = match.params
    user = getUser()

    React.createElement("div", {"className": "Drafts page"},
      React.createElement(Header, {"user": (user)}),
      React.createElement("div", {"className": "container"},

        React.createElement("div", {"className": "Drafts__heading"},
          React.createElement("div", null,
            React.createElement("h1", {"className": "Drafts__title"},
              React.createElement(Link, {"to": (if archived then "/languages/#{languageName}" else "/languages")},
                React.createElement("span", {"className": "fas fa-chevron-left"}),
                (languageName)
              )
            ),
            React.createElement("p", null, "Click on a row to ", (if archived then 'show' else 'edit'), " translation")
          ),
          (unless archived
            React.createElement("div", {"className": "Drafts__headingBtns"},
              React.createElement(Link, { \
                "to": "/languages/#{languageName}/import",  \
                "className": "btn btn_secondary btn_outlined_bluePurple"
              }, "Import"),
              React.createElement(Link, { \
                "className": "btn btn_secondary btn_outlined_bluePurple",  \
                "to": "/languages/#{languageName}/archived"
              }, "Show Archived")
            )
          )
        ),

        React.createElement(Loader, {"loading": (loading)}),

        React.createElement("table", null,
          React.createElement("thead", null,
            React.createElement("tr", null,
              React.createElement("th", null, "Version"),
              React.createElement("th", null, "State"),
              React.createElement("th", null, "Created"),
              React.createElement("th", null, "Last Modified"),
              (unless archived
                React.createElement("th", null, "Actions")
              )
            )
          ),
          React.createElement("tbody", null,
            (drafts.map (draft) ->
              React.createElement("tr", {"key": (draft.id), "onClick": ( -> not updatingDraft && clickOnRow draft), "className": (cx draft.state, loading: updatingDraft is draft.id)},
                React.createElement("td", {"data-th": "Version"}, (draft.version)),
                React.createElement("td", {"data-th": "State", "className": (cx "Drafts__state", draft.state)}, (draft.state)),
                React.createElement("td", {"data-th": "Created"}, (moment(draft.createDate).format 'MMMM DD, YYYY')),
                React.createElement("td", {"data-th": "Last Modified"}, (moment(draft.modifyDate).format 'MMMM DD, YYYY hh:mm a')),
                (unless archived
                  React.createElement("td", {"className": "Drafts__actions"},
                    React.createElement(Loader, {"loading": (updatingDraft is draft.id), "size": "2"}),
                    (switch draft.state
                      when 'Draft'
                        React.createElement("button", { \
                          "className": "btn btn_compact btn_solid_bluePurple",  \
                          "onClick": (openModal 'Publish', draft),  \
                          "disabled": (updatingDraft is draft.id)
                        }, "Publish")
                      when 'Published'
                        React.createElement("button", { \
                          "className": "btn btn_compact btn_solid_green",  \
                          "onClick": (openModal 'Activate', draft),  \
                          "disabled": (updatingDraft is draft.id)
                        }, "Activate")
                    ),
                    (if draft.state isnt 'Activated'
                      React.createElement("button", { \
                        "className": "btn btn_compact",  \
                        "onClick": (openModal 'Archive', draft),  \
                        "disabled": (updatingDraft is draft.id)
                      }, "Archive")
                    ),
                    React.createElement("button", { \
                      "className": "btn btn_compact",  \
                      "onClick": (exportDraft draft),  \
                      "disabled": (updatingDraft is draft.id)
                    }, "Export")
                  )
                )
              )
            )
          )
        )
      ),

      React.createElement(Footer, null),

      React.createElement(Modal, { \
        "isOpen": (modalOpened),  \
        "close": (closeModal),  \
        "className": "Drafts__modal"
      },
        React.createElement(Loader, {"loading": (updatingDraft isnt false), "size": "2"}),
        (switch modalType
          when 'Publish', 'PublishError'
            React.createElement(ConfirmModal, { \
              "close": (closeModal),  \
              "modalType": (modalType),  \
              "confirm": (publishDraft modalPayload),  \
              "note": "You will be unable to edit this version.",  \
              "error": (modalError)
            })
          when 'Archive'
            React.createElement(ConfirmModal, { \
              "close": (closeModal),  \
              "modalType": (modalType),  \
              "confirm": (archiveVersion modalPayload),  \
              "error": (modalError)
            })
          when 'Activate', 'ActivateError'
            React.createElement(ConfirmModal, { \
              "close": (closeModal),  \
              "modalType": (modalType),  \
              "confirm": (activateVersion modalPayload),  \
              "note": "This version will be live.",  \
              "error": (modalError)
            })
        )
      )
    )
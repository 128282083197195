import React from 'react'
import {composeComponent} from 'utils/react-tools'
import {withHandlers, withStateHandlers, lifecycle} from 'recompose'
import {SubmissionError} from 'redux-form'
import Form from './Form.coffee'
import Loader from 'theme/Loader'
import {getTransalations, editTransalations} from './requests.coffee'
import validationErrors from 'theme/TranslationView/validationErrors.coffee'

import './TranslationView.sass'


export default composeComponent 'TranslationView',
  withStateHandlers
    loading: false
    language: ""
    translations: []
    errors: null
  ,
    endLoading: -> -> loading: false
    startLoading: -> -> loading: true
    changeLanguage: -> (language) -> language: language
    setTranslations: -> (translations) -> {translations}
    setErrors: -> (errors) -> {errors}

  withHandlers
    onChangeLanguage: ({changeLanguage, startLoading, endLoading, setTranslations, element, type}) -> (l) ->
      startLoading()
      changeLanguage l
      getTransalations l?.value, params: {prefix: "#{type}.#{element?.label}", includeEmpty: true}
        .then (translation) -> 
          setTranslations translation?.translation?.values
      .finally endLoading
  
  withHandlers
    submit: ({startLoading, endLoading, setTranslations, element, type, language, setErrors}) -> (values) ->
      newValues = values: 
        values.values.map (v) -> Object.assign {}, v, {value: if v.value is "" then v.value = undefined else v.value}

      startLoading()
      editTransalations language.value, newValues, params: {prefix: "#{type}.#{element?.label}", includeEmpty: true}
        .then(
          (translation) -> 
            setTranslations translation?.translation?.values
          (error) ->
            if error.message is 'ValidationFailed'
              fieldErrors = values.values.map (v) ->
                foundError = error.errors.find (e) -> e.key is v.key
                value: if foundError then validationErrors[foundError.errorCode] ? 'Unknown'
                key: v.key

              generalErrors = error.errors
              .filter (e) -> not e.key?
              .map (e) -> validationErrors[e.errorCode]

              errors =
                accum: generalErrors
                errors: fieldErrors.filter (f) -> f.value

              setErrors errors
              throw new SubmissionError values: fieldErrors, _error: errors
            else
              e = _error: 'Something went wrong. Please try again later'
              setErrors e
              throw new SubmissionError e
            return error
        )
        .finally endLoading

  lifecycle
    componentDidMount: ->
      {options, onChangeLanguage} =@props
      myL = options.find (l) -> l.label?.toLowerCase() == "english"
      onChangeLanguage myL

  ({options, element, onChangeLanguage, language, translations, submit, loading, errors}) ->

    values = translations.map (t) -> label: t.key, value: t.value

    React.createElement("div", {"className": "container"},
      React.createElement(Loader, {"loading": (loading)}),
      React.createElement(Form, { \
        "onSubmit": (submit),  \
        "initialValues": (values: translations),  \
        "options": (options),  \
        "onChangeLanguage": (onChangeLanguage),  \
        "language": (language),  \
        "err": (errors)
      })
    )
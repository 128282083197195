import React from 'react'
import cx from 'classnames'
import * as _ from 'ramda'
import RSelect, {components} from 'react-select'
import {composeComponent} from 'utils/react-tools'
import {withStateHandlers, withHandlers, withProps} from 'recompose'


import './Form.sass'

export Checkbox = composeComponent 'Form.Checkbox',

  ({input, label, description, disabled, className, setValue, checked})->
    React.createElement("div", {"className": (cx("Form__field", className, {disabled}))},
      React.createElement("div", {"className": "Form__label"},
        (if label
          React.createElement("label", {"htmlFor": (input.name)},
            (label)
          )
        )
      ),
      React.createElement("div", {"className": "Form__checkboxWrapper"},
        React.createElement("label", null,
          React.createElement("input", Object.assign({ \
            "type": 'checkbox',  \
            "checked": (checked),  \
            "onClick": (setValue),  \
            "disabled": (disabled)
            }, input
          )),
          (description)
        )
      )
    )

export TextField = composeComponent 'Form.TextField',
  ({input, label, required, placeholder, className, textarea, disabled, meta: {touched, error, warning}, type, maxlength, withoutError, setRef}) ->
    React.createElement("div", {"className": (cx("Form__field", className, {disabled}))},
      React.createElement("div", {"className": "Form__label"},
        (if label
          React.createElement("label", {"className": (cx({required})), "htmlFor": (input.name)},
            (label)
          )
        ),
        React.createElement("div", {"className": "Form__labelInfos"},
          (if touched and error and not withoutError
            React.createElement("span", {"className": (cx "Form__fieldError", withLabel: label?)}, (error))
          ),
          (if touched and warning
            React.createElement("span", {"className": (cx "Form__fieldWarning", withLabel: label?)}, (warning))
          )
        )
      ),
      React.createElement("div", {"className": "Form__input"},
        (if textarea
          React.createElement("textarea", Object.assign({ \
            "placeholder": (placeholder),  \
            "className": (cx({
              'has-value': input.value and input.value isnt '',
              err: touched and error
              warn: touched and warning
            })),  \
            "disabled": (disabled),  \
            "maxLength": (maxlength),  \
            "ref": (setRef)
            }, input
          ))
        else
          React.createElement("input", Object.assign({ \
            "type": (type || "text"),  \
            "placeholder": (placeholder),  \
            "className": (cx({
              'has-value': input.value and input.value isnt '',
              err: touched and error
              warn: touched and warning
            })),  \
            "maxLength": (maxlength),  \
            "disabled": (disabled),  \
            "ref": (setRef)
            }, input
          ))
        ),
        (if touched and warning
          React.createElement("span", {"className": "Form__fieldIcon fas fa-exclamation-triangle"})
        )
      )
    )

export Select = composeComponent 'Form.Select',
  withProps ({isMulti, options, input: {value}}) ->
    selectedOption:
      if isMulti
        options?.filter (o) -> o.value in value
      else options?.find (o) -> o.value is value

  withHandlers
    handleChange: ({setOption, input, isMulti, setValue, setLastAction}) -> (option, value) ->
      if setLastAction then setLastAction value
      value = if isMulti then _.pluck 'value', option else if option? then option.value else null
      input.onChange value
      if setValue then setValue value

  ({input
    label
    required
    placeholder
    options
    meta
    search
    creatable
    className
    disabled
    loading
    setOption
    selectedOption
    onNewOptionClick
    handleChange
    isMulti
    optionClassName
    isClearable
  }) =>
    {touched, error} = meta
    Option = (props) ->
      React.createElement("div", {"className": (cx "Form__selectOption", optionClassName? props.data)},
        React.createElement(components.Option, Object.assign({},  props ))
      )

    React.createElement("div", {"className": (cx("Form__field", className, {disabled}))},
      (if label
        React.createElement("label", {"className": (cx({required})), "htmlFor": (input.name)},
          (label)
        )
      ),
      React.createElement("div", {"className": "Form__input"},
        (touched && error &&
          React.createElement("span", {"className": "Form__fieldError"}, (error))
        ),
        React.createElement(RSelect, { \
          "className": (cx("Form__select", {err: touched && error})),  \
          "classNamePrefix": "Form__select",  \
          "options": (options),  \
          "onChange": (handleChange),  \
          "value": (selectedOption),  \
          "placeholder": (placeholder),  \
          "disabled": (disabled),  \
          "autoBlur": true,  \
          "isMulti": (isMulti),  \
          "components": ({Option}),  \
          "isClearable": (isClearable)
        })
      )
    )
